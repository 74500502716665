import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChampionImage from './components/ImageSection/ChampionImage';
import ImageNavBar from './components/ImageSection/ChampionNavBar';
import { ChampionStats } from '../firebase/championDAO';
import { getChampion, getBuildDataByDocumentId } from '../firebase/championDAO';
import BuildSubSection from './components/build/BuildSubSection';
import ChampionCircleBar from './components/ImageSection/ChampionCircleBar';
import MatchupCard from './components/CountersTab/MatchupCard';
import MobileMatchupCard from './components/CountersTab/MobileMatchupCard';
import ItemsTab from './components/ItemTab/itemsTab';

const ChampionPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [championData, setChampionData] = useState<ChampionStats | null>(null);

  // For the tabs
  const [activeTab, setActiveTab] = useState<string>('Overview');

  const handleTeamPositionChange = (teamPositionClicked: string) => {
    if (
      championData?.teamPosition !== teamPositionClicked &&
      championData?.documentId
    ) {
      const documentIdParts = championData?.documentId.split('_');
      documentIdParts[1] = teamPositionClicked.toLowerCase();
      const fetchData = async () => {
        const data: any = await getBuildDataByDocumentId(
          documentIdParts.join('_')
        );
        setChampionData(data);
      };
      fetchData();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      if (id) {
        const data: any = await getChampion(id);
        setChampionData(data);
      }
    };
    fetchData();
  }, [id]);

  return !championData ? (
    <div className="flex justify-center items-center h-screen ">
      <div className="h-[35%]">
        <div className="animate-spin rounded-full h-16 w-16 border-b-4 border-blue-600"></div>
      </div>
    </div>
  ) : (
    // Actual Content
    <div className="flex justify-center w-full overflow-x-hidden">
      <div className="w-full lg:w-[100%] lg:max-w-[84.1rem]">
        <div className="relative bg-white w-full">
          <div className="w-full">
            <ChampionImage
              handleTeamPositionChange={handleTeamPositionChange}
              allTeamPositionsPickRates={
                championData?.allTeamPositionsPickRates
              }
              teamPosition={championData?.teamPosition}
              championSpecificLanePickRate={
                championData?.championSpecificLanePickRate
              }
              teamPositionWinRate={championData?.teamPositionWinRate}
              banRate={championData?.banRate}
              championId={id!}
              championsmallimageSrc={`/tiles/${id}_0.jpg`}
            />
          </div>
          <div className="md:absolute md:bottom-0 md:left-0 w-full bg-green md:bg-transparent">
            <ImageNavBar
              buttonLabels={['Overview', 'Counters']}
              activeLabel={activeTab}
              onTabChange={setActiveTab}
            />
          </div>
        </div>

        {/* build section, change name? overviewSection */}
        <div className="bg-primary mt-2 fade-in">
          {activeTab === 'Overview' && (
            <BuildSubSection
              recommendedNormalItems={championData.recommendedNormalItems}
              recommendedRunes={championData.recommendedRunes}
              recommendedStartingItems={championData.recommendedStartingItems}
              recommendedBoots={championData.recommendedBoots}
              recommendedSpells={championData.recommendedSummonerSpells}
              recommendedSkillPath={championData.recommendedSkillLevelUp}
              goodAgainst={championData.goodAgainst}
              badAgainst={championData.badAgainst}
              champid={id!}
            />
          )}
          {activeTab === 'Counters' && (
            <div className="mx-auto w-[97%] md:w-full">
              <div className="hidden md:block p-4 rounded-md bg-secondary">
                <MatchupCard
                  image1={`/championCounter/${id!}_0.jpg`}
                  champid={id!}
                  goodAgainst={championData.goodAgainst}
                  badAgainst={championData.badAgainst}
                />
              </div>
              <div className="lg:hidden rounded-md bg-secondary">
                <MobileMatchupCard
                  image1={`/championCounter/${id!}_0.jpg`}
                  champid={id!}
                  goodAgainst={championData.goodAgainst}
                  badAgainst={championData.badAgainst}
                />
              </div>
            </div>
          )}
          {activeTab === 'Items' && (
            <div className="mx-auto w-[97%] md:w-full">
              <ItemsTab
                recommendedNormalItems={championData.recommendedNormalItems}
                champid={id!}
              />
            </div>
          )}
          {/* Add similar conditions for other tabs */}
        </div>
      </div>
    </div>
  );
};

export default ChampionPage;
