import React, { useState, useRef, useEffect } from "react";
import { MatchData, Participant } from "./MatchHistory";
import MatchHistoryItemDropdown from "./MatchHistoryItemDropdown";

import CustomTooltip from "../../../lol-champion-page/components/build/CustomToolTip";
import { getRightPerkClass } from "../../../Util";

// Function to load item data
const loadItemData = async () => {
  try {
    const module = await import(`../../../staticData/en_US/items/items`);
    return module.default;
  } catch (error) {
    console.error("Failed to load item data:", error);
    return {};
  }
};

const formatNumber = (num: number) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  return num.toString();
};

interface Rune {
  id: number;
  key: string;
  icon: string;
  name: string;
  shortDesc: string;
  longDesc: string;
}

interface Slot {
  runes: Rune[];
}

interface Perk {
  id: number;
  icon: string;
  name: string;
  slots: Slot[];
}

interface MatchHistoryItemProps {
  matchIndex: number;
  matches?: MatchData[];
  summonerName?: string | undefined;
}

function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds + "s"}`;
}

const MatchHistoryItem: React.FC<MatchHistoryItemProps> = ({
  matchIndex,
  matches,
  summonerName,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState("0px");
  const [itemData, setItemData] = useState<Record<string, any>>({});
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Load item data on component mount
  useEffect(() => {
    loadItemData().then((data) => {
      setItemData(data);
    });
  }, []);
console.log(matches![matchIndex].matchId)
  const handleToggleDropdown = () => {
    if (isDropdownOpen) {
      if (dropdownRef.current) {
        setMaxHeight(`${dropdownRef.current.scrollHeight}px`);
        setTimeout(() => setMaxHeight("0px"), 10);
      }
    } else {
      if (dropdownRef.current) {
        setMaxHeight(`${dropdownRef.current.scrollHeight}px`);
      }
    }
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (dropdownRef.current) {
        if (isDropdownOpen) {
          setMaxHeight(`${dropdownRef.current.scrollHeight}px`);
        }
      }
    });

    if (dropdownRef.current) {
      observer.observe(dropdownRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [isDropdownOpen]);

  const getRightPerk = (perkClass: Perk, perkId: number) => {
    for (let slot of perkClass.slots) {
      for (let rune of slot.runes) {
        if (rune.id === perkId) {
          return rune.icon;
        }
      }
    }
    return null;
  };

  const getSecondaryClass = (perkClass: Perk, perkId: number) => {
    if (perkClass.id === perkId) return perkClass.icon;
  };

  const getSummonerStats = (
    matchIndex: number,
    matches: MatchData[] | undefined,
    summonerName: string | undefined,
  ): Participant | null => {
    if (!matches || !matches[matchIndex]) return null;

    const match = matches[matchIndex];
    for (let participant of match.participants) {
      if (
        participant.riotIdGameName?.toLocaleLowerCase().trim() +
          participant.riotIdTagline?.toLocaleLowerCase().trim() ===
        summonerName?.toLocaleLowerCase().trim()
      ) {
        return participant;
      }
    }
    return null;
  };

  const getHighestKillTag = (participant: Participant) => {
    if (participant.pentaKills > 0) return "PENTAKILL";
    if (participant.quadraKills > 0) return "QUADRAKILL";
    if (participant.tripleKills > 0) return "TRIPLEKILL";
    if (participant.doubleKills > 0) return "DOUBLEKILL";
    return "";
  };

  const getTimeago = (timestamp: number): string => {
    const timeDifference = Date.now() - timestamp;
    const seconds = timeDifference / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const weeks = days / 7;
    const months = days / 30;

    if (months >= 1)
      return `${Math.floor(months)} month${
        Math.floor(months) > 1 ? "s" : ""
      } ago`;
    if (weeks >= 1)
      return `${Math.floor(weeks)} week${Math.floor(weeks) > 1 ? "s" : ""} ago`;
    if (days >= 1)
      return `${Math.floor(days)} day${Math.floor(days) > 1 ? "s" : ""} ago`;
    if (hours >= 1)
      return `${Math.floor(hours)} hour${Math.floor(hours) > 1 ? "s" : ""} ago`;
    if (minutes >= 1)
      return `${Math.floor(minutes)} minute${
        Math.floor(minutes) > 1 ? "s" : ""
      } ago`;
    return `${Math.floor(seconds)} second${
      Math.floor(seconds) > 1 ? "s" : ""
    } ago`;
  };

  const summonerStats = getSummonerStats(matchIndex, matches, summonerName);
  const isVictory = summonerStats?.win === true;

  const handleButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleToggleDropdown();
  };

  const handleDropdownClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  if (!summonerStats) return null;

  const primaryPerkClass = getRightPerkClass(
    summonerStats.perks.styles[0].selections[0].perk,
  );
  const secondaryPerkClass = getRightPerkClass(
    summonerStats.perks.styles[1].style,
  );

  const primaryPerk = primaryPerkClass
    ? getRightPerk(
        primaryPerkClass,
        summonerStats.perks.styles[0].selections[0].perk,
      )
    : null;

  const secondaryPerk = secondaryPerkClass
    ? getSecondaryClass(secondaryPerkClass, summonerStats.perks.styles[1].style)
    : null;

  return (
    <div onClick={handleToggleDropdown}>
      <div
        className={`relative flex w-full items-start justify-between rounded border border-secondary p-2 hover:shadow-lg xl:mx-auto xl:max-w-[75%]`}
        style={{
          background: isVictory
            ? "linear-gradient(to right, rgba(0, 255, 0, 0.3) 5%, rgba(15, 23, 42, 0.5) 20%, rgba(15, 23, 42, 0.8) 40%, #020617 60%)"
            : "linear-gradient(to right, rgba(255, 0, 0, 0.3) 5%, rgba(15, 23, 42, 0.5) 20%, rgba(15, 23, 42, 0.8) 40%, #020617 60%)",
        }}
      >
        <div className="relative flex flex-row self-end">

          {summonerStats && (
            <img
              src={`/champimages/${summonerStats.championName}.png`}
              alt="Champion"
              className="h-12 w-12 rounded"
            />
          )}
          <div className="border-gold absolute bottom-0 left-[-0.4rem] flex h-5 w-5 items-center justify-center rounded-full border-2 bg-black text-xxs text-white">
            {summonerStats?.champLevel}
          </div>
          <div className="ml-2">
            <div className="flex flex-col md:flex-row md:items-start">
              <div className="mb-2 md:mb-0 md:mr-4">
                <div className="flex items-center">
                  <p
                    className={`font-beaufort mr-2 text-xs ${
                      isVictory ? "text-green" : "text-red-500"
                    }`}
                  >
                    {isVictory ? "VICTORY" : "DEFEAT"}
                  </p>
                </div>
                <p className="text-xs">
                  {matches && matches[matchIndex].queueId}
                </p>
                <p className="hidden text-xs text-stone-50 md:block flex space-x-[0.1rem]">
                  <span className="text-green font-bold text-sm">{summonerStats?.kills}</span>
                  <span>/</span>
                  <span className="text-red-600 font-bold text-sm">{summonerStats?.deaths}</span>
                  <span>/</span>
                  <span className="text-yellow-500 font-bold text-sm">{summonerStats?.assists}</span>
                </p>

              </div>

              <div className="flex md:grid md:grid-cols-2 md:items-center md:gap-1">
                <img
                  src={`/spell/${summonerStats?.summoner1Id}.png`}
                  alt="Spell 1"
                  className="mb-1 h-4 w-4 md:mb-0 md:h-6 md:w-6"
                />
                <img
                  src={`/spell/${summonerStats?.summoner2Id}.png`}
                  alt="Spell 2"
                  className="mb-1 h-4 w-4 md:mb-0 md:h-6 md:w-6"
                />
                {primaryPerk && (
                  <img
                    src={`/${primaryPerk}`}
                    alt="Primary Perk"
                    className="mb-1 h-4 w-4 md:mb-0 md:h-6 md:w-6"
                  />
                )}
                {secondaryPerk && (
                  <img
                    src={`/${secondaryPerk}`}
                    alt="Secondary Perk"
                    className="mb-1 h-4 w-4 md:mb-0 md:h-5 md:w-5"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* invid stats only for md up */}
        <div className="relative hidden w-1/3 flex-col items-center justify-center overflow-hidden lg:flex">
          <div className="stats stats-vertical mx-auto w-full max-w-xs bg-transparent shadow lg:stats-horizontal">
            <div className="stat m-0 flex-none p-1">
              <div className="stat-title text-[0.8rem] leading-tight text-neutral-400">
                Damage done/taken
              </div>
              <div className="leading-tigh stat-value text-[0.9rem] text-neutral-400">
                {formatNumber(summonerStats?.totalDamageDealtToChampions)} <span className="text-green">/</span> {formatNumber(summonerStats?.totalDamageTaken)} 
              </div>
            </div>
            <div className="stat m-0 flex-none p-1">
              <div className="stat-title text-[0.8rem] leading-tight text-neutral-400">
                Gold earned
              </div>
              <div className="stat-value text-[0.9rem] leading-tight text-neutral-400">
                {formatNumber(summonerStats?.goldEarned)}
              </div>
            </div>
            <div className="stat m-0 flex-none p-1">
              <div className="stat-title text-[0.8rem] leading-tight text-neutral-400">
                CS
              </div>
              <div className="stat-value text-[0.9rem] leading-tight text-neutral-400">
                {summonerStats?.totalMinionsKilled +
                  summonerStats?.neutralMinionsKilled}
              </div>
            </div>
          </div>
        </div>

        {/* tags */}
        <div className="flex flex-col items-center justify-center gap-1 self-center">
          <span className="py-0.25 rounded-sm bg-purple-600 px-0.5 text-xxs font-bold text-white">
            KDA: {summonerStats?.kda.toFixed(2)}
          </span>
          <span className="py-0.25 rounded-sm bg-blue-950 px-0.5 text-xxs font-bold text-white">
            {getHighestKillTag(summonerStats)}
          </span>
        </div>
        <div className="flex gap-3 font-beaufort text-gray-350 absolute bottom-[3.4rem] right-1 m-1 text-xs opacity-70 md:bottom-12 md:right-0 md:text-xs lg:right-0 lg:m-2 lg:text-[0.9rem] xl:bottom-10">
        <div className="font-bold md:bg-primary rounded text-xxs md:text-xs p-1 text-neutral-300">{(matches && formatTime(matches[matchIndex].gameDuration))}</div>
        <div className="font-bold md:bg-primary rounded text-xxs md:text-xs p-1 text-neutral-300">{getTimeago(matches![matchIndex].gameEndTimestamp)}</div> 
        </div>
        <div className="flex items-center self-end lg:flex-row lg:space-x-2">
          <div className="mr-2 grid grid-cols-3 gap-1 md:mt-2 md:flex md:flex-row">
            {[
              summonerStats?.item0,
              summonerStats?.item1,
              summonerStats?.item2,
              summonerStats?.item3,
              summonerStats?.item4,
              summonerStats?.item5,
            ]
              .sort((a, b) => (a ? 1 : -1))
              .map((item, index) => {
                const itemInfo = itemData[`item_${item}`] || {}; // Access item data using item ID
                const itemImage = `/item/${item}.png`; // Define the item image path

                // Render the tooltip only if item info and image are available
                if (!item || !itemInfo.name)
                  return (
                    <div
                      key={matchIndex + index}
                      className="h-4 w-4 rounded md:h-8 md:w-8"
                    ></div>
                  );

                return (
                  <CustomTooltip
                    key={matchIndex + index}
                    position="top"
                    header={itemInfo.name || ""}
                    cost={`${itemInfo.gold?.total || ""} (${
                      itemInfo.gold?.base || ""
                    })`}
                    content={itemInfo.description || ""}
                  >
                    <img
                      src={itemImage}
                      alt={`Item ${index + 1}`}
                      className="h-6 w-6 rounded md:h-8 md:w-8"
                    />
                  </CustomTooltip>
                );
              })}
          </div>
          <div className="relative h-12 lg:ml-2 lg:h-auto lg:self-stretch">
            <div className="absolute bottom-0 left-0 h-4/6 w-0.5 border-l border-gray-600"></div>
          </div>
          <button
            onClick={handleButtonClick}
            className="ml-1 rounded-full lg:ml-2 lg:mt-0"
          >
            <svg
              className={`size-2 transition-transform ${
                isDropdownOpen ? "rotate-180" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m6 6 6 6 6-6" />
            </svg>
          </button>
        </div>
      </div>
      <div
        ref={dropdownRef}
        className={`overflow-hidden transition-all duration-300 ease-in-out`}
        style={{ maxHeight }}
        onClick={handleDropdownClick}
      >
        <div className="mt-2">
          {matches && (
            <MatchHistoryItemDropdown
              participants={matches[matchIndex].participants}
              itemData={itemData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchHistoryItem;
