import React from "react";

export const SummonerDetailsSkeleton: React.FC = () => (
  <div
    role="status"
    className="max-w-md md:max-w-4xl mx-auto p-4 md:p-6 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700"
  >
    <div className="flex items-center space-x-4">
      <div className="w-16 h-16 skeleton rounded-md flex items-center justify-center"></div>

      <div className="flex-1 space-y-4">
        <div className="h-4 skeleton rounded w-1/2 md:w-1/3"></div>
        <div className="h-4 skeleton rounded w-1/4 md:w-1/6"></div>
      </div>
    </div>
    <div className="flex flex-wrap justify-start items-start w-full pt-2 space-x-4 md:space-x-2">
      <div className="w-28 h-32 skeleton rounded"></div>
      <div className="w-28 h-32 skeleton rounded"></div>
      <div className="w-28 h-32 skeleton rounded"></div>
    </div>
    <div className="flex flex-wrap md:flex-nowrap md:space-x-4 space-y-4 md:space-y-0 pt-4">
      <div className="h-24 skeleton rounded w-full md:w-1/2">
        <div className="flex flex-wrap justify-center gap-10">
          {Array.from({ length: 4 }).map((_, idx) => (
            <div key={idx} className="flex flex-col items-center">
              <div className="w-12 h-12 skeleton rounded-full mt-4"></div>
              <div className="w-12 h-2 skeleton rounded-full"></div>
              <div className="w-10 h-2 skeleton rounded-full mt-1"></div>
            </div>
          ))}
        </div>
      </div>
      <div className="h-28 md:h-24 skeleton rounded w-full md:w-1/2"></div>
    </div>
    <span className="sr-only">Loading...</span>
    <div className="h-28 md:h-10 skeleton rounded w-full"></div>
  </div>
);

export const MatchHistorySkeleton: React.FC = () => (
  
  <div
    role="status"
    className="max-w-md md:max-w-4xl mx-auto p-4 md:p-6 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700"
  >
    {Array.from({ length: 5 }).map((_, idx) => (
      <div key={idx} className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 skeleton rounded-full w-24 md:w-32 mb-2.5"></div>
          <div className="w-32 md:w-48 h-2 skeleton rounded-full"></div>
        </div>
        <div className="h-2.5 skeleton rounded-full w-12 md:w-24"></div>
      </div>
    ))}
    <span className="sr-only">Loading...</span>
  </div>
);
