import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface Participant {
  teamId: number;
  kills: number;
  goldEarned: number;
  totalDamageDealtToChampions: number;
  wardsPlaced: number;
  totalDamageTaken: number;
  totalMinionsKilled: number;
  neutralMinionsKilled?: number;
  win: boolean;
  // ... include other properties as needed
}

interface Props {
  participants: Participant[];
}

const MatchHistoryItemDropdownPerformance: React.FC<Props> = ({ participants }) => {
  // Helper function to format numbers
  const formatNumber = (num: number) => {
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    }
    return num.toString();
  };

  // Split participants into two teams based on teamId
  const team100: Participant[] = participants.filter((p) => p.teamId === 100);
  const team200: Participant[] = participants.filter((p) => p.teamId === 200);

  // Function to calculate total stats for a team
  const getTeamStats = (team: Participant[]) => {
    const totalKills = team.reduce((sum, p) => sum + p.kills, 0);
    const totalGoldEarned = team.reduce((sum, p) => sum + p.goldEarned, 0);
    const totalDamageDealtToChampions = team.reduce(
      (sum, p) => sum + p.totalDamageDealtToChampions,
      0
    );
    const totalWardsPlaced = team.reduce((sum, p) => sum + p.wardsPlaced, 0);
    const totalDamageTaken = team.reduce((sum, p) => sum + p.totalDamageTaken, 0);
    const totalCS = team.reduce(
      (sum, p) => sum + p.totalMinionsKilled + (p.neutralMinionsKilled || 0),
      0
    );
    return {
      totalKills,
      totalGoldEarned,
      totalDamageDealtToChampions,
      totalWardsPlaced,
      totalDamageTaken,
      totalCS,
    };
  };

  // Calculate stats for each team
  const teamBlue = getTeamStats(team100);
  const teamRed = getTeamStats(team200);

  // Function to calculate percentage of team's performance compared to both teams
  const getTeamPercentage = (teamMetric: number, opponentMetric: number) => {
    const total = teamMetric + opponentMetric;
    if (total === 0) return 0;
    return Math.round((teamMetric / total) * 100);
  };

  // Prepare metrics data
  const metrics = [
    {
      label: 'Champion Kills',
      teamBlueValue: teamBlue.totalKills,
      teamRedValue: teamRed.totalKills,
      percentage: getTeamPercentage(teamBlue.totalKills, teamRed.totalKills),
    },
    {
      label: 'Gold Earned',
      teamBlueValue: teamBlue.totalGoldEarned,
      teamRedValue: teamRed.totalGoldEarned,
      percentage: getTeamPercentage(teamBlue.totalGoldEarned, teamRed.totalGoldEarned),
    },
    {
      label: 'Champion Damage',
      teamBlueValue: teamBlue.totalDamageDealtToChampions,
      teamRedValue: teamRed.totalDamageDealtToChampions,
      percentage: getTeamPercentage(
        teamBlue.totalDamageDealtToChampions,
        teamRed.totalDamageDealtToChampions
      ),
    },
    {
      label: 'Wards Placed',
      teamBlueValue: teamBlue.totalWardsPlaced,
      teamRedValue: teamRed.totalWardsPlaced,
      percentage: getTeamPercentage(teamBlue.totalWardsPlaced, teamRed.totalWardsPlaced),
    },
    {
      label: 'Damage Taken',
      teamBlueValue: teamBlue.totalDamageTaken,
      teamRedValue: teamRed.totalDamageTaken,
      percentage: getTeamPercentage(teamBlue.totalDamageTaken, teamRed.totalDamageTaken),
    },
    {
      label: 'CS',
      teamBlueValue: teamBlue.totalCS,
      teamRedValue: teamRed.totalCS,
      percentage: getTeamPercentage(teamBlue.totalCS, teamRed.totalCS),
    },
  ];

  return (
    <div className="text-white mt-1 md:mt-2 lg:mt-3">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {metrics.map((metric, index) => {
          const options: ApexOptions = {
            series: [metric.percentage],
            chart: {
              height: 200,
              type: 'radialBar',
              offsetY: -7,
              sparkline: {
                enabled: true,
              },
            },
            plotOptions: {
              radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                  background: '#60a5fa', // Blue color for teamRed
                  strokeWidth: '97%',
                  margin: 5,
                  dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    color: '#999',
                    opacity: 1,
                    blur: 2,
                  },
                },
                dataLabels: {
                  name: {
                    show: false,
                  },
                  value: {
                    offsetY: -2,
                    fontSize: '0px',
                    color: '#ffffff',
                  },
                },
              },
            },
            colors: ['#f87171'], // Red color for teamBlue
          };

          return (
            <div key={index} className="chart-container">
              <div className='border-b-1 border-violet-400 border-l-1 border-r-1 '>
              <div className="flex justify-center">
                <p className='text-stone-50'>{metric.label}</p>
              </div>
              <Chart
                options={options}
                series={options.series as number[]}
                type="radialBar"
                height={200}
              />
              <div className="flex flex-row justify-center gap-[6.6rem]">
                <p className="text-red-400 ">{formatNumber(metric.teamBlueValue)}</p>
                <p className="text-blue-400 text-sm">{formatNumber(metric.teamRedValue)}</p>
              </div>
              </div>

            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MatchHistoryItemDropdownPerformance;
