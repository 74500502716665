import React, { useEffect, useState } from "react";
import FeaturedGamesItems from "./LandingPageLiveDataComponents/FeaturedGamesItems";
import SearchBar from "../lol-landing-page/components/ChampionSearchBar";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/championDAO";

export interface FeaturedParticipant {
  championId: number;
  riotId: string;
}

export interface FeaturedGame {
  gameLength: number;
  participants: FeaturedParticipant[];
}

const LandingPageLiveData: React.FC = () => {
  const [featuredGames, setFeaturedGames] = useState<FeaturedGame[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFeaturedGames = async () => {
      try {
        const getFeaturedGamesData = httpsCallable(
          functions,
          "getFeaturedGamesDataCallable",
        );

        const response = await getFeaturedGamesData({
          regionalServer: "euw1",
        });

        const data = response.data as FeaturedGame[];
        setFeaturedGames(data);
        console.log("data", data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching featured games data:", error);
        setLoading(false);
      }
    };

    fetchFeaturedGames();
  }, []);

  return (
    <div className="relative flex min-h-screen items-center justify-center">
      {/* Video Background */}
      <video
        src="/mp4/5.mp4"
        className="absolute left-0 top-0 h-full w-full object-cover"
        muted
        autoPlay
        loop
        playsInline
      />

      {/* Overlay for dimming */}
      <div className="absolute left-0 top-0 h-full w-full bg-black bg-opacity-95"></div>

      {/* Content */}
      <div className="hero-content relative z-10 text-center text-neutral-content">
        <div className="grid grid-cols-2 gap-4">
          {/* Rendering FeaturedGamesItems with fetched data */}
          {featuredGames.map((game, index) => (
            <FeaturedGamesItems key={index} game={game} />
          ))}

          {/* Search Bar */}
          <div className="col-span-2 flex w-full justify-center py-4">
            <SearchBar padding="py-2 mx-20" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageLiveData;
