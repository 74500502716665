import React from "react";
import { LiveDataProps } from "../live-data-static/Types";
import { round } from "lodash";
import { champions } from "../../staticData/champion_mapping";
import { generateTags } from "../../staticData/summoner_tags_calculation";
import r_8000_primary from "../../staticData/cs_CZ/runes/r_8000_primary";
import r_8100_primary from "../../staticData/cs_CZ/runes/r_8100_primary";
import r_8200_primary from "../../staticData/cs_CZ/runes/r_8200_primary";
import r_8300_primary from "../../staticData/cs_CZ/runes/r_8300_primary";
import r_8400_primary from "../../staticData/cs_CZ/runes/r_8400_primary";
import { Perk } from "../live-data-static/Types";

interface SummonerCardProps extends LiveDataProps {
  index: number; // Add index prop to the interface
  role: string;
}

const getRightPerkClass = (perkClass: number) => {
  if (perkClass >= 8000 && perkClass < 8100) return r_8000_primary;
  if (perkClass >= 8100 && perkClass < 8200) return r_8100_primary;
  if (perkClass >= 8200 && perkClass < 8300) return r_8200_primary;
  if (perkClass >= 8300 && perkClass < 8400) return r_8300_primary;
  if (perkClass >= 8400 && perkClass < 8500) return r_8400_primary;
  return null;
};

const getRightPerk = (perkClass: Perk, perkId: number) => {
  for (let slot of perkClass.slots) {
    for (let rune of slot.runes) {
      if (rune.id === perkId) {
        return rune.icon;
      }
    }
  }
  return null;
};

const SummonerCard: React.FC<SummonerCardProps> = ({
  participant,
  index,
  role,
}) => {
  let primaryPerk: string | null = "";
  let secondaryPerk: string | null = "";
  const tempPerkOne = getRightPerkClass(participant.perks.perkSubStyle);
  const tempPerkTwo = getRightPerkClass(participant.perks.perkStyle);

  if (tempPerkOne && tempPerkTwo) {
    primaryPerk = getRightPerk(tempPerkOne, participant.perks.perkIds[4]);
    secondaryPerk = getRightPerk(tempPerkTwo, participant.perks.perkIds[0]);
  }

  return (
    <div
      className={`relative bg-gradient-to-r from-blue-950 to-indigo-950 text-white rounded-2xl shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] p-3 w-48 mx-auto transform transition-transform duration-300 md:w-64 md:p-4 half-left-border shadow-md ${
        index < 5 ? "shadow-blue-500" : "shadow-red-500"
      }`}
    >
      <style>
        {`
          .half-left-border::before {
            content: '';
            position: absolute;
            top: 8px; /* Adjust to fit the rounded corner */
            left: 0;
            height: calc(50%); /* Adjust to fit the rounded corner */
            width: 1px; /* Slightly thinner border width */
            background: linear-gradient(to bottom, rgba(203, 213, 225, 0) 0%, #cbd5e1 50%, rgba(203, 213, 225, 0) 100%); /* Gradient for easing out on top and bottom */
          }
        `}
      </style>

      {/* Existing content */}

      {/* Grid of small images at the top left corner */}
      <div className="absolute top-2 left-2 grid grid-cols-2 gap-1">
        <img
          src={`/spell/${participant.spell1Id}.png`}
          alt="Image1"
          className="w-4 h-4 md:w-6 md:h-6"
        />
        <img
          src={`/spell/${participant.spell2Id}.png`}
          alt="Image2"
          className="w-4 h-4 md:w-6 md:h-6"
        />
      </div>

      <div className="absolute top-2 right-2 grid grid-cols-2 gap-1">
        <img
          src={`/${secondaryPerk}`}
          alt="Image1"
          className="w-4 h-4 md:w-6 md:h-6"
        />
        <img
          src={`/${primaryPerk}`}
          alt="Image2"
          className="w-4 h-4 md:w-6 md:h-6"
        />
      </div>

      <div className="absolute top-16 md:top-20 right-2">
        <div className="flex flex-col items-center">
          <p className="text-gray-400 text-xxs md:text-xs">{role}</p>
          <img
            src={`/teamposition/${role?.toUpperCase()}.webp`}
            alt="Image1"
            className="w-8 h-8 md:w-10 md:h-10"
          />
        </div>
      </div>

      {/* <div className="absolute top-16 md:top-20 left-2">
        <div className="flex flex-col items-center">
          <p className="text-blue-300 text-xxs md:text-xs text-opacity-60">
            Winrate
          </p>
          <p className="text-blue-300 text-xxs md:text-sm font-bold">62%</p>
          <p className="text-blue-300 text-xxs md:text-xs text-opacity-60">
            112 Played
          </p>
        </div>
      </div> */}

      {/* Main content with centered items */}
      <div className="flex flex-col items-center">
        <div className="relative w-24 h-24 md:w-32 md:h-32 shadow-lg shadow-cyan-500/50 rounded-full">
          <div className="relative w-full h-full">
            <img
              src={`/tiles/${champions[participant.championId]}_0.jpg`}
              alt="Character"
              className="rounded-full border-4 border-gray-800 w-full h-full object-cover scale-y-95 scale-x-95 md:object-center-plus-25"
            />
            <img
              src="/imgframes/test.png"
              alt="Frame"
              className="absolute top-0 left-0 w-full h-full pointer-events-none"
              style={{ transform: "scale(1.1)" }} // Adjust the scale to fit the frame properly around the image
            />
          </div>
        </div>
        {/* summoner name */}
        <div className="flex flex-col items-center w-full">
          <div className="w-full h-px bg-blue-400 mt-3 opacity-50"></div>{" "}
          {/* Top border */}
          <div className="w-full flex justify-center">
            <h2 className="text-sm font-bold md:text-base truncate px-2 text-center">
              {participant.riotId}
            </h2>
          </div>
          <div className="w-full h-px bg-blue-400 opacity-50"></div>{" "}
          {/* Bottom border */}
        </div>
      </div>

      <div className="flex justify-between items-center w-full mt-1">
        {" "}
        {/* Reduced mt-2 to mt-1 */}
        <div className="flex justify-start items-center bg-primary rounded-lg px-1 py-0.5 shadow-inner md:px-2 md:py-1">
          <img
            src={`/tiers/${participant?.rank.length === 0 ? "PROFILE_UNRANKED.png" : participant?.rank[0].tier}.png`} // Replace with the correct image source
            alt="Ashe"
            className="w-6 h-6 md:w-8 md:h-8 rounded-full mr-2"
          />
          <div>
            <p className="text-blue-400 text-xxs md:text-xs"></p>
            <p className="font-bold text-xxs md:text-sm opacity-70 mr-2">
              {participant.rank.length === 0 ? "0" : participant.rank[0].leaguePoints} LP
            </p>
          </div>
        </div>
        {/* Spacer to keep sections apart */}
        <div className="flex-1"></div>
        {/* Right Challenger Section */}
        <div className="flex justify-start items-center bg-primary rounded-lg px-1 py-0.5 shadow-inner md:px-2 md:py-1">
          <div className="flex flex-col items-center">
            <p className="text-blue-400 text-xxs md:text-xs">Champion WR</p>
            <p className="text-blue-300 text-xxs md:text-sm font-bold">?</p>
          </div>
        </div>
      </div>

      <div className="">
        {" "}
        {/* Reduced mt-2 to mt-1 and md:mt-4 to md:mt-2 */}
        <div className="flex justify-between text-gray-400 text-xs md:text-sm">
          <span>WINRATE</span>
          <span>
            {participant.rank.length === 0? "?":participant.rank[0].wins + participant.rank[0].losses} played
          </span>
        </div>
        {/* Updated Progress Bar */}
        <div className="w-full bg-primary rounded-full dark:bg-primary mt-0.5">
          {" "}
          {/* Reduced mt-1 to mt-0.5 */}
          <div
            className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
            style={{
              width:
              participant.rank.length === 0? "?":round(
                  (participant.rank[0].wins /
                    (participant.rank[0].wins + participant.rank[0].losses)) *
                    100,
                  1
                ).toString() + "%",
            }} // Update this value based on the winrate or progress
          >
            <p className="text-green text-xxs font-bold md:text-xs ">
              {participant.rank.length === 0? "?":round(
                (participant.rank[0].wins /
                  (participant.rank[0].wins + participant.rank[0].losses)) *
                  100,
                1
              )}
              %
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-1 md:mt-2">
        {" "}
        {/* Reduced mt-2 to mt-1 and md:mt-4 to md:mt-2 */}
        <div className="text-center">
          <p className="text-gray-400 text-xs">K/D/A</p>
          <div className="font-bold text-sm md:text-base flex justify-center">
            <span className="text-green">?</span>
            <span className="mx-1">/</span>
            <span className="text-red-500">?</span>
            <span className="mx-1">/</span>
            <span className="text-yellow-500">?</span>
          </div>
        </div>
        <div className="text-center">
          <p className="text-gray-400 text-xs">MATCHES</p>
          <p className="font-bold text-sm md:text-base">?</p>
        </div>
      </div>

      <div className="flex justify-between items-center mt-4">
        {/* Left Tags (Green - Success) */}
        <div className="flex flex-col space-y-1">
          {participant.rank.length === 0? "":generateTags(
            participant.rank[0].wins + participant.rank[0].losses,
            participant.rank[0].wins,
            participant.rank[0].losses,
            (participant.rank[0].wins /
              (participant.rank[0].wins + participant.rank[0].losses)) *
              100,
              participant.rank.length === 0 ? 0 : participant.rank[0].leaguePoints
          )
            .filter((tag) => tag.color === "green")
            .map((tag, index) => (
              <span
                key={index}
                className="badge badge-success text-xs text-stone-50 gap-2"
              >
                {tag.tag}
              </span>
            ))}
        </div>
        {/* Spacer */}
        <div className="flex-1"></div>
        {/* Right Tags (Red - Error) */}
        <div className="flex flex-col space-y-1">
          {participant.rank.length === 0? "":generateTags(
            participant.rank[0].wins + participant.rank[0].losses,
            participant.rank[0].wins,
            participant.rank[0].losses,
            (participant.rank[0].wins /
              (participant.rank[0].wins + participant.rank[0].losses)) *
              100,
              participant.rank.length === 0 ? 0 : participant.rank[0].leaguePoints
          )
            .filter((tag) => tag.color === "red")
            .map((tag, index) => (
              <span
                key={index}
                className="badge badge-error text-xs text-stone-50  "
              >
                {tag.tag}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SummonerCard;
