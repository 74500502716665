import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-gray-600 mb-4">Last updated: September 14, 2024</p>

      <p className="mb-4">
        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Interpretation and Definitions</h2>

      <h3 className="text-xl font-semibold mt-4 mb-2">Interpretation</h3>
      <p className="mb-4">
        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">Definitions</h3>
      <p className="mb-4">For the purposes of this Privacy Policy:</p>

      <ul className="list-disc list-inside mb-4 space-y-2">
        <li><strong>Account</strong>: A unique account created for You to access our Service or parts of our Service.</li>
        <li><strong>Affiliate</strong>: An entity that controls, is controlled by, or is under common control with a party.</li>
        <li><strong>Company</strong>: Refers to EZ.GG, Sweden.</li>
        <li><strong>Cookies</strong>: Small files placed on Your device by a website.</li>
        <li><strong>Country</strong>: Refers to Sweden.</li>
        <li><strong>Device</strong>: Any device that can access the Service (e.g., computer, cellphone).</li>
        <li><strong>Personal Data</strong>: Any information that relates to an identifiable individual.</li>
        <li><strong>Service</strong>: Refers to the Website.</li>
        <li><strong>Website</strong>: Refers to EZ.GG, accessible at <a href="https://www.ez.gg" className="text-blue-500 underline" target="_blank">www.ez.gg</a>.</li>
        <li><strong>You</strong>: The individual accessing or using the Service.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Collecting and Using Your Personal Data</h2>

      <h3 className="text-xl font-semibold mt-4 mb-2">Types of Data Collected</h3>

      <h4 className="text-lg font-semibold mt-4 mb-2">Personal Data</h4>
      <p className="mb-4">
        While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
      </p>
      <ul className="list-disc list-inside mb-4 space-y-2">
        <li>Usage Data</li>
      </ul>

      <h4 className="text-lg font-semibold mt-4 mb-2">Usage Data</h4>
      <p className="mb-4">
        Usage Data is collected automatically when using the Service.
      </p>
      <p className="mb-4">
        Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
      </p>
      <p className="mb-4">
        When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
      </p>
      <p className="mb-4">
        We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
      </p>

      <h4 className="text-lg font-semibold mt-4 mb-2">Tracking Technologies and Cookies</h4>
      <p className="mb-4">
        We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.
      </p>

      <p className="mb-4">
        The technologies We use may include:
      </p>

      <ul className="list-disc list-inside mb-4 space-y-2">
        <li><strong>Cookies or Browser Cookies</strong>: A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.</li>
        <li><strong>Web Beacons</strong>: Small electronic files known as web beacons used to track the popularity of a section of the Service or verify system and server integrity.</li>
      </ul>

      <p className="mb-4">
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">Use of Your Personal Data</h3>
      <p className="mb-4">The Company may use Personal Data for the following purposes:</p>

      <ul className="list-disc list-inside mb-4 space-y-2">
        <li><strong>To provide and maintain our Service</strong>, including to monitor usage of our Service.</li>
        <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service.</li>
        <li><strong>For the performance of a contract:</strong> to develop, comply with, and undertake purchases made through the Service.</li>
        <li><strong>To contact You:</strong> By email, phone, or other electronic communications regarding updates or information.</li>
        <li><strong>To provide You</strong> with news, offers, and general information.</li>
        <li><strong>To manage Your requests:</strong> Attend and manage any requests You submit to Us.</li>
        <li><strong>For business transfers:</strong> Evaluate or conduct mergers, acquisitions, or sales.</li>
        <li><strong>For other purposes:</strong> Data analysis, service improvements, and promotional campaigns.</li>
      </ul>

      <h3 className="text-xl font-semibold mt-4 mb-2">Retention of Your Personal Data</h3>
      <p className="mb-4">
        We will retain Your Personal Data only for as long as necessary for the purposes set out in this Privacy Policy.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">Security of Your Personal Data</h3>
      <p className="mb-4">
        The security of Your Personal Data is important to Us. While We strive to use commercially acceptable means to protect Your Personal Data, no method of transmission over the Internet or method of electronic storage is 100% secure.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Children's Privacy</h2>
      <p className="mb-4">
        Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under 13 years of age.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Links to Other Websites</h2>
      <p className="mb-4">
        Our Service may contain links to other websites. We strongly advise You to review the Privacy Policy of every site You visit as we have no control over third-party sites.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Changes to this Privacy Policy</h2>
      <p className="mb-4">
        We may update Our Privacy Policy from time to time. You are advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about this Privacy Policy, You can contact us by email at contact@ez.gg.
      </p>
    </div>
  );
};

export default PrivacyPolicy;