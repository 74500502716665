import React, { useEffect, useState } from "react";
import RankDetails from "./components/RankDetails";
import { champions } from "../../../../src/staticData/champion_mapping";
import ChampionMasteries from "./components/ChampionMasteries";
import PerformanceChart from "./components/PerformanceChart";
import { useNavigate } from "react-router-dom";
import { MatchData, Participant } from "../matchhistory/MatchHistory";

interface SummonerDetailsProps {
  summonerName?: string;
  profileIcon: number;
  rank: string;
  tagLine?: string;
  gameName?: string;
  championMasteries: ChampionMastery[];
  rankInfo: Array<{
    leagueId: string;
    queueType: string;
    tier: string;
    rank: string;
    summonerId: string;
    leaguePoints: number;
    wins: number;
    losses: number;
    veteran: boolean;
    inactive: boolean;
    freshBlood: boolean;
    hotStreak: boolean;
  }>;
  matches: MatchData[];
  isSummonerLive?: boolean;
  puuid: string;
  summonerLevel: number;
  region?: string;
}

export interface BannedChampion {
  championId: number;
  teamId: number;
  pickTurn: number;
}

interface ChampionMastery {
  championId: string;
  championLevel: number;
  championPoints: number;
}

export interface LiveGameData {
  gameMode: string;
  gameType: string;
  gameQueueConfigId: number;
  participants: any;
  platformId: string;
  bannedChampions: BannedChampion[];
  gameStartTime: number;
  gameLength: number;
}

const SummonerDetails: React.FC<SummonerDetailsProps> = ({
  tagLine,
  summonerName,
  profileIcon,
  rank,
  rankInfo,
  championMasteries,
  matches,
  isSummonerLive,
  puuid,
  summonerLevel,
  gameName,
  region,
}) => {
  const [championMasteries_, setChampionMasteries] = useState<
    ChampionMastery[]
  >([]);
  const [topChampionImage, setTopChampionImage] = useState<string>("");
  const [winrate, setWinrate] = useState<number>(0);
  const [chartData, setChartData] = useState<{ x: string; y: number }[]>([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (championMasteries) {
      setChampionMasteries(championMasteries);

      // Sort champion masteries to find the top champion
      const sortedMasteries = [...championMasteries].sort(
        (a, b) => b.championPoints - a.championPoints,
      );
      if (sortedMasteries.length > 0) {
        const topChampionId = sortedMasteries[0].championId;
        setTopChampionImage(
          `/championBackground/${champions[topChampionId]}_0.jpg`,
        );
      }
    }

    // Extract lane data from matches
    const laneCounts: Record<string, number> = {
      BOTTOM: 0,
      JUNGLE: 0,
      MIDDLE: 0,
      TOP: 0,
      UTILITY: 0,
    };

    let winCount = 0;
    matches.forEach((match) => {
      match.participants.forEach((participant: Participant) => {
        if (
          (
            participant.riotIdGameName?.toLowerCase() +
            participant.riotIdTagline?.toLowerCase()
          ).trim() === summonerName?.toLowerCase().trim()
        ) {
          const lane = participant.teamPosition;
          if (laneCounts[lane] !== undefined) {
            laneCounts[lane]++;
          }
          if (participant.win) {
            winCount++;
          }
        }
      });
    });

    const totalMatches = matches.length;
    setWinrate(totalMatches > 0 ? (winCount / totalMatches) * 100 : 0);

    setChartData(
      Object.keys(laneCounts).map((lane) => ({
        x: lane,
        y: laneCounts[lane],
      })),
    );
  }, [championMasteries, puuid, matches]);

  const handleLiveDataClick = () => {
    if (isSummonerLive) {
      navigate(`/live-data/${region}/${summonerName}/${tagLine}`, {
        state: puuid,
      });
    }
  };

  // Define images for each category (aligned with the chart categories)

  return (
    <div className="relative w-auto transition duration-700 xl:mx-auto xl:max-w-[75%]">
      {/* Background Section */}
      {topChampionImage && (
        <div
          className={`absolute left-0 top-0 h-64 w-full bg-cover bg-center opacity-60 md:h-80 lg:h-96`}
          style={{
            backgroundImage: `url(${topChampionImage})`,
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-primary"></div>
        </div>
      )}

      {/* Overlay Gradient to Blend the Background */}
      <div className="relative flex w-full flex-col items-start gap-2 p-2 md:gap-4 md:p-5 lg:p-3">
        <div className="relative z-10 flex w-full items-start">
          <img
            src={`/profileicon/${profileIcon}.png`}
            alt="Summoner Profile"
            className="mr-4 h-14 w-14 rounded-xl border-2 border-green md:h-20 md:w-20 lg:h-24 lg:w-24"
          />
          <div className="absolute left-[0.85rem] top-[2.9rem] rounded-md border-2 border-green bg-primary pl-1 pr-1 text-xs text-stone-50 md:left-6 md:top-[4.1rem] md:text-sm lg:left-8 lg:top-[5rem] lg:text-base">
            {summonerLevel}
          </div>

          <div className="ml-3 flex flex-col">
            <div className="rounded-lg bg-primary p-1 text-xl font-bold md:text-2xl lg:text-3xl">
              {gameName}
            </div>
            <div className="text-small self-start rounded-lg bg-primary p-1 text-stone-50 md:text-lg lg:text-xl mt-1">
              #{tagLine}
            </div>
          </div>
        </div>

        <button
          onClick={handleLiveDataClick}
          disabled={!isSummonerLive}
          className={`absolute right-2 top-2 z-10 rounded px-3 py-2 text-xs shadow-lg md:text-sm ${
            isSummonerLive
              ? "bg-red-500 text-stone-50 hover:bg-red-600"
              : "cursor-not-allowed bg-gray-200 text-gray-600"
          }`}
        >
          Live Game
          {isSummonerLive && <span className="ml-1">⚡</span>}
        </button>

        {/* Rank Details */}
        <div className="flex w-full items-start justify-between">
          <RankDetails rankInfo={rankInfo} />
        </div>

        {/* Champion Masteries and Performance Chart */}
        <div className="flex w-full flex-col gap-2 md:flex-row">
          <div className="w-full md:w-1/2">
            <ChampionMasteries championMasteries={championMasteries_} />
          </div>
          <div className="w-full md:w-1/2">
            <PerformanceChart chartData={chartData} winRate={winrate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummonerDetails;
