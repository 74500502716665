import r_8000_primary from "../src/staticData/cs_CZ/runes/r_8000_primary";
import r_8100_primary from "../src/staticData/cs_CZ/runes/r_8100_primary";
import r_8200_primary from "../src/staticData/cs_CZ/runes/r_8200_primary";
import r_8300_primary from "../src/staticData/cs_CZ/runes/r_8300_primary";
import r_8400_primary from "../src/staticData/cs_CZ/runes/r_8400_primary";


export const getRightPerkClass = (perkClass: number) => {
    if (perkClass >= 8000 && perkClass < 8100) return r_8000_primary;
    if (perkClass >= 8100 && perkClass < 8200) return r_8100_primary;
    if (perkClass >= 8200 && perkClass < 8300) return r_8200_primary;
    if (perkClass >= 8300 && perkClass < 8400) return r_8300_primary;
    if (perkClass >= 8400 && perkClass < 8500) return r_8400_primary;
    return null;
  };