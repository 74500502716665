import React from "react";
import { Participant } from "./MatchHistory";

interface IndividualPerformanceProps {
  participants: Participant[];
}

const MatchHistoryItemDropdownIndividualPerformance: React.FC<IndividualPerformanceProps> = ({
  participants,
}) => {
  return (
    <div className="text-white overflow-auto">
      <table className="min-w-full bg-secondary text-xs border-collapse table-fixed">
        <thead>
          <tr className="border-b border-primary">
            <th className="py-1 px-2 border-r border-primary text-xxs text-left w-1/4">Summoner</th>
            <th className="py-1 px-2 border-r border-primary text-xxs text-left">Champion Kills</th>
            <th className="py-1 px-2 border-r border-primary text-xxs text-left">Gold Earned</th>
            <th className="py-1 px-2 border-r border-primary text-xxs text-left">Champion Damage</th>
            <th className="py-1 px-2 border-r border-primary text-xxs text-left">Wards Placed</th>
            <th className="py-1 px-2 border-r border-primary text-xxs text-left">Damage Taken</th>
            <th className="py-1 px-2 text-xxs text-left">CS</th>
          </tr>
        </thead>
        <tbody>
          {participants.map((participant, index) => (
            <tr key={index} className="border-b border-primary">
              <td className="py-1 px-2 border-r border-primary flex items-center break-words">
                <img
                  src={`/champimages/${participant.championName}.png`}
                  alt={participant.riotIdGameName}
                  className="w-6 h-6 mr-2"
                />
                {participant.riotIdGameName}
              </td>
              <td className="py-1 px-2 border-r border-primary">{`${participant.kills}/${participant.deaths}/${participant.assists}`}</td>
              <td className="py-1 px-2 border-r border-primary">{participant.goldEarned}</td>
              <td className="py-1 px-2 border-r border-primary">{participant.totalDamageDealtToChampions}</td>
              <td className="py-1 px-2 border-r border-primary">{participant.wardsPlaced}</td>
              <td className="py-1 px-2 border-r border-primary">{participant.totalDamageTaken}</td>
              <td className="py-1 px-2">{participant.totalMinionsKilled}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MatchHistoryItemDropdownIndividualPerformance;
