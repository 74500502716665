import React, { useState, useEffect } from "react";
import SummonerCard from "./components/SummonerCard";
import { functions, getChampion } from "../firebase/championDAO";
import { ChampionStats } from "../firebase/championDAO";
import BuildSubSection from "../lol-champion-page/components/build/BuildSubSection";
import { BannedChampion } from "../lol-summoner-page/components/summonerdetails/SummonerDetails";
import { useLocation, useParams } from "react-router-dom";
import { queueDescriptions } from "./live-data-static/mapQueueId";
import SummonerCardMobile from "./components/SummonerCardMobile";
import { champions } from "../staticData/champion_mapping";
import { predictRoles } from "./components/rolePrediction";
import ElapsedTimeCounter from "./components/ElapsedTimeCounter";
import Error from "../components/error";
import { httpsCallable } from "firebase/functions";

interface LiveDataProps {
  liveData?: LiveGameData;
}

export interface LiveGameData {
  gameMode: string;
  gameType: string;
  gameQueueConfigId: number;
  participants: any;
  platformId: string;
  bannedChampions: BannedChampion[];
  gameStartTime: number;
  gameLength: number;
}

function getQueueDescription(queueId: number) {
  const queue = queueDescriptions.find((q) => q.queueId === queueId);
  return queue
    ? queue.description
      ? queue.description
      : "No description available"
    : "Unknown queueId";
}

const LiveDataPage: React.FC<LiveDataProps> = () => {
  const { region } = useParams<{ region: string }>();
  const location = useLocation();
  const puuid: string | undefined = location.state as string | undefined;
  const [liveData, setLiveData] = useState<LiveGameData | null>(null);
  const [championData, setChampionData] = useState<ChampionStats | null>(null);
  const [queueDescription, setQueueDescription] = useState("");
  const [searchedChampion, setSearchedChampion] = useState("");
  const [teamWithPredictedRoles, setTeamWithPredictedRoles] = useState<{
    [key: string]: string;
  }>({});
  const [error, setError] = useState<{ message: string; errorCode: string } | null>(null);
  const [isLoading, setIsLoading] = useState(true); // New state for controlling loading transition

  useEffect(() => {
    const fetchLiveData = async () => {
      if (!puuid) return;

      try {
        // Use httpsCallable to call the Firebase function
        const getSummonerLiveData = httpsCallable(functions, "getSummonerLiveData");

        const response = await getSummonerLiveData({
          regionalServer: region,
          puuid: puuid,
        });

        const data = response.data as LiveGameData;
        getSearchedChampion(data, puuid);
        setLiveData(data);
        setIsLoading(false); // Set loading to false when data is loaded
        const queueDesc = getQueueDescription(data.gameQueueConfigId);
        setQueueDescription(queueDesc);
      } catch (error) {
        console.log("Error fetching live game data:", error);
        setError({
          message: "An unexpected error occurred.",
          errorCode: "500",
        });
      }
    };

    fetchLiveData();
  }, [puuid]);

  useEffect(() => {
    if (!liveData || !liveData.participants) return;

    let teamOne: string[] = [];
    for (let i = 0; i < 5; i++) {
      teamOne.push(liveData.participants[i].championId.toString());
    }

    let teamTwo: string[] = [];
    for (let i = 5; i < 10; i++) {
      teamTwo.push(liveData.participants[i].championId.toString());
    }

    setTeamWithPredictedRoles({
      ...predictRoles(teamOne),
      ...predictRoles(teamTwo),
    });
  }, [liveData]);

  const getSearchedChampion = (data: LiveGameData, puuid: string) => {
    data.participants.forEach((participant: any) => {
      if (participant.puuid === puuid) {
        setSearchedChampion(champions[participant.championId]);
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: any = await getChampion(searchedChampion);
        setChampionData(data);
      } catch (error) {
        console.error("Error fetching champion data:", error);
      }
    };

    if (searchedChampion) {
      fetchData();
    }
  }, [searchedChampion]);

  if (error) {
    return <Error message={error.message} errorCode={error.errorCode} />;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-7xl">
        {/* Smooth transition for content */}
        <div className={`transition-opacity duration-500 ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
          {liveData?.participants ? (
            <>
              {/* Existing content rendering */}
              <div className="md:my-4">
                <div className="py-2 flex flex-col md:flex-row md:justify-between md:items-center px-4">
                  <h1 className="text-sm md:text-xl font-semibold text-left">
                    {queueDescription}
                  </h1>
                  <div className="hidden md:flex items-center space-x-4">
                    <div className="avatar-group flex -space-x-4 rtl:space-x-reverse">
                      {Array.from({ length: 5 }).map((_, index) => (
                        <div
                          className="avatar border-5 border-primary !border-primary rounded-full"
                          key={`ally-${index}`}
                        >
                          <div className="w-10 rounded-full overflow-hidden">
                            <img
                              src={`/champimages/${champions[liveData.participants[index].championId]}.png`}
                              alt={`Champion ${liveData.participants[index].championId}`}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="text-lg font-bold mx-2">VS</div>
                    <div className="avatar-group flex -space-x-4 rtl:space-x-reverse">
                      {Array.from({ length: 5 }).map((_, index) => (
                        <div
                          className="avatar border-5 !border-primary rounded-full"
                          key={`enemy-${index + 5}`}
                        >
                          <div className="w-10 rounded-full overflow-hidden">
                            <img
                              src={`/champimages/${champions[liveData.participants[index + 5].championId]}.png`}
                              alt={`Champion ${liveData.participants[index + 5].championId}`}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    {liveData?.gameStartTime && (
                      <ElapsedTimeCounter gameStartTime={liveData.gameStartTime} />
                    )}
                  </div>
                </div>
              </div>

              <div className="block md:hidden px-4 text-center mb-2">
                <div className="flex items-center justify-center space-x-2">
                  <div className="avatar-group flex -space-x-4 rtl:space-x-reverse">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <div
                        className="avatar border-5 border-primary !border-primary rounded-full"
                        key={`ally-mobile-${index}`}
                      >
                        <div className="w-8 rounded-full overflow-hidden">
                          <img
                            src={`/champimages/${champions[liveData.participants[index].championId]}.png`}
                            alt={`Champion ${liveData.participants[index].championId}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="text-lg font-bold mx-2">VS</div>

                  <div className="avatar-group flex -space-x-4 rtl:space-x-reverse">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <div
                        className="avatar border-5 !border-primary rounded-full"
                        key={`enemy-mobile-${index + 5}`}
                      >
                        <div className="w-8 rounded-full overflow-hidden">
                          <img
                            src={`/champimages/${champions[liveData.participants[index + 5].championId]}.png`}
                            alt={`Champion ${liveData.participants[index + 5].championId}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-5 gap-1 md:gap-4 justify-items-center">
                {liveData.participants.map((participant: any, index: number) => (
                  <div key={index} className="w-full">
                    <div className="block md:hidden">
                      <SummonerCardMobile
                        participant={participant}
                        index={index}
                        role={"Mid"}
                      />
                    </div>
                    <div className="hidden md:block">
                      <SummonerCard
                        participant={participant}
                        index={index}
                        role={teamWithPredictedRoles[participant.championId.toString()]}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="my-4">
                <div className="py-2 flex justify-between items-center px-4">
                  <h1 className="text-xl font-semibold text-left">
                    Recommended build for {searchedChampion}
                  </h1>
                </div>
              </div>
              <div className="mt-8 w-full">
                {championData ? (
                  <BuildSubSection
                    recommendedNormalItems={championData.recommendedNormalItems}
                    recommendedRunes={championData.recommendedRunes}
                    recommendedStartingItems={championData.recommendedStartingItems}
                    recommendedBoots={championData.recommendedBoots}
                    recommendedSpells={championData.recommendedSummonerSpells}
                    recommendedSkillPath={championData.recommendedSkillLevelUp}
                    goodAgainst={championData.goodAgainst}
                    badAgainst={championData.badAgainst}
                    champid={searchedChampion}
                  />
                ) : (
                  <div>Loading build information...</div>
                )}
              </div>
            </>
          ) : null}
        </div>

        {/* Skeleton with smooth transition out */}
        <div className={`transition-opacity duration-500 ${isLoading ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <div className="grid md:grid-cols-5 gap-x-4 gap-y-4 md:h-auto sm:h-64">
            {Array.from({ length: 10 }).map((_, index) => (
              <div
                key={index}
                className="skeleton sm:w-36 md:h-96 md:w-60 h-64 w-60 flex items-center justify-center"
              >
                <div className="flex flex-col items-center gap-6 w-52">
                  <div className="flex items-center gap-4">
                    <div className="skeleton h-16 w-16 shrink-0 rounded-full"></div>
                    <div className="flex flex-col gap-2">
                      <div className="skeleton h-4 w-20"></div>
                      <div className="skeleton h-4 w-28"></div>
                    </div>
                  </div>
                  <div className="skeleton h-32 w-full"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveDataPage;
