import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface PerformanceChartProps {
  chartData: { x: string; y: number }[]; // Data for the bar chart
  winRate: number; // Win rate percentage
}

const chartImages = [
  '/teamposition/BOTTOM.webp',
  '/teamposition/JUNGLE.webp',
  '/teamposition/MIDDLE.webp',
  '/teamposition/TOP.webp',
  '/teamposition/UTILITY.webp',
];

const PerformanceChart: React.FC<PerformanceChartProps> = ({
  chartData,
  winRate,
}) => {
  // Bar Chart Options
  const barChartOptions: ApexOptions = {
    chart: {
      type: 'bar' as 'bar',
      height: 150,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '70%',
        barHeight: '60%',
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickPlacement: 'between',
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      textAnchor: 'middle',
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        colors: ['#000000'],
      },
      background: {
        foreColor: '#0f172a',
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: '#cbd5e1',
        opacity: 0.45,
      },
    },
    colors: ['#FFD700', '#FFD700', '#FFD700', '#FFD700', '#FFD700'],
    tooltip: {
      theme: 'dark', // Set tooltip theme to dark
    },
  };

  const barChartSeries = [
    {
      data: chartData,
    },
  ];

  // Radial Bar Chart Options
  const radialBarOptions: ApexOptions = {
    series: [winRate], // Use the actual win rate
    chart: {
      height: 150,
      type: 'radialBar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: '70%',
          background: '#0f172a',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: '#0f172a',
          strokeWidth: '100%',
          margin: 0,
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: 0,
            show: true,
            color: '#FEE75C',
            fontSize: '13px',
          },
          value: {
            offsetY: 5,
            formatter: function (val) {
              return `${val.toFixed(0)}%`; // Display the value as integer percentage
            },
            color: '#fafaf9',
            fontSize: '15px',
            show: true,
          },
        },
      },
    },
    fill: {
      colors: ['#FFD700'],
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['Winrate'],
    legend: {
      show: false, // Disable the legend
    },
    tooltip: {
      theme: 'dark', // Set tooltip theme to dark
    },
  };

  return (
    <div className="w-full bg-primaryShade rounded-md shadow-strong-inner shadow-primary">
      <div className="flex justify-start items-center p-2">
        <div className="w-0.5 h-4 md:h-6 bg-green mr-1 md:mr-2"></div>
        <span className="text-sm md:text-lg font-bold">Analytics</span>
        <span className="ml-2 text-xs md:text-base text-gray-500">
          (last 20 games)
        </span>
      </div>

      <div className="relative flex justify-around items-center">
        <div className="relative flex items-center mb-1">
          {/* Bar Chart */}
          <div className="h-36 w-40">
            <ReactApexChart
              options={barChartOptions}
              series={barChartSeries}
              type="bar"
              height={150}
            />
            <div className="absolute bottom-0 left-3 w-full flex gap-1">
              {chartImages.map((imageSrc, index) => (
                <img
                  key={index}
                  src={imageSrc}
                  alt={`Image ${index + 1}`}
                  className="w-6 h-6"
                />
              ))}
            </div>
          </div>
        </div>
        {/* Radial Bar Chart */}
        <div className="flex flex-col items-center h-36 w-40">
          <ReactApexChart
            options={radialBarOptions}
            series={[winRate]} // Use the actual win rate
            type="radialBar"
            height={150}
          />
        </div>
      </div>
    </div>
  );
};

export default PerformanceChart;
