import React, { useState, useRef, useEffect } from "react";
import SearchBar from "./components/ChampionSearchBar";

const ChampionPage: React.FC = () => {
  // State to manage the transition between videos
  const [isSecondVideoPlaying, setIsSecondVideoPlaying] = useState(false);

  // Refs for the video elements
  const secondVideoRef = useRef<HTMLVideoElement>(null);

  // Handler for when the first video ends
  const handleFirstVideoEnd = () => {
    setIsSecondVideoPlaying(true);
  };

  // Effect to play the second video when it becomes active
  useEffect(() => {
    if (isSecondVideoPlaying && secondVideoRef.current) {
      secondVideoRef.current.play();
    }
  }, [isSecondVideoPlaying]);

  return (
    <div className="area w-full">
      <div className="relative flex flex-col items-center">
        <div className="w-full">
          <div
            className="relative -mb-4 mt-3.5 scale-y-105 opacity-30"
            style={{ height: "110vh", overflow: "hidden" }}
          >
            {/* Video for desktop, image for mobile */}
            <div className="relative hidden md:block">
              {/* First Video */}
              <video
                src="/mp4/5.mp4"
                className={`absolute left-0 top-0 h-screen w-full rounded-lg object-cover blur-sm filter transition-opacity duration-1000 ${
                  isSecondVideoPlaying ? "opacity-0" : "opacity-100"
                }`}
                muted
                autoPlay
                controls={false}
                onEnded={handleFirstVideoEnd}
              />

              {/* Second Video */}
              <video
                src="/mp4/6.mp4"
                ref={secondVideoRef}
                className={`absolute left-0 top-0 h-screen w-full rounded-lg object-cover blur-sm filter transition-opacity duration-1000 ${
                  isSecondVideoPlaying ? "opacity-100" : "opacity-0"
                }`}
                muted
                loop
                controls={false}
                preload="auto"
              />
            </div>

            {/* Image for mobile devices */}
            <img
              src="championCounter/Ekko_28.jpg"
              alt="Ekko Background"
              className="block w-full scale-y-110 rounded-lg object-cover blur-sm filter md:hidden"
              style={{ minWidth: "100%", minHeight: "100%" }}
            />
          </div>

          {/* Rest of your content */}
          <div className="absolute left-1/2 top-1/4 z-10 -translate-x-1/2 transform text-center font-proxima">
            <div className="font-proxima-nova mb-10 flex items-center text-5xl font-extrabold md:text-6xl">
              <img src="logo/ez_logo.png" alt="EZ Logo" className="h-20" />
              <span className="pt-3 font-sans font-bold text-stone-50 md:text-6xl">
                .GG
              </span>
            </div>
          </div>
          <div className="absolute inset-0 z-10 flex w-full items-center justify-center">
            <div className="w-4/5 min-w-max py-2 sm:w-[50%]">
              <SearchBar padding="py-2 mx-20" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChampionPage;
