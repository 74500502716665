export default {
    "id": 8300,
    "icon": "perk-images/Styles/7203_Whimsy.png",
    "name": "Povzbuzen\u00ed",
    "slots": [
        {
            "runes": [
                {
                    "id": 8351,
                    "key": "GlacialAugment",
                    "icon": "perk-images/Styles/Inspiration/GlacialAugment/GlacialAugment.png",
                    "name": "Ledovcov\u00e9 vylep\u0161en\u00ed",
                    "shortDesc": "Kdy\u017e znehybn\u00ed\u0161 nep\u0159\u00e1telsk\u00e9ho \u0161ampiona, vyst\u0159el\u00ed z n\u011bj 3 ledovcov\u00e9 paprsky, kter\u00e9 zpomal\u00ed okoln\u00ed nep\u0159\u00e1tele a sn\u00ed\u017e\u00ed jejich po\u0161kozen\u00ed zp\u016fsobovan\u00e9 tv\u00fdm spojenc\u016fm.",
                    "longDesc": "Kdy\u017e znehybn\u00ed\u0161 nep\u0159\u00e1telsk\u00e9ho \u0161ampiona, vyst\u0159el\u00ed z n\u011bj sm\u011brem k tob\u011b a dal\u0161\u00edm okoln\u00edm \u0161ampion\u016fm 3 ledovcov\u00e9 paprsky a vytvo\u0159\u00ed na 3&nbsp;sek. (+ 100&nbsp;% trv\u00e1n\u00ed znehyb\u0148uj\u00edc\u00edho efektu) zmrzl\u00e9 z\u00f3ny, kter\u00e9 nep\u0159\u00e1tele zpomal\u00ed o 20&nbsp;% (+9&nbsp;% za ka\u017ed\u00fdch 10&nbsp;% s\u00edly l\u00e9\u010den\u00ed a \u0161t\u00edt\u016f) (+6&nbsp;% za ka\u017ed\u00fdch 100 bod\u016f s\u00edly schopnost\u00ed) (+7&nbsp;% za ka\u017ed\u00fdch 100 bod\u016f bonusov\u00e9ho \u00fato\u010dn\u00e9ho po\u0161kozen\u00ed) a sn\u00ed\u017e\u00ed o 15&nbsp;% po\u0161kozen\u00ed, kter\u00e9 zp\u016fsobuj\u00ed tv\u00fdm spojenc\u016fm (krom\u011b tebe). <br><br>P\u0159eb\u00edjec\u00ed doba: 25&nbsp;sek."
                },
                {
                    "id": 8360,
                    "key": "UnsealedSpellbook",
                    "icon": "perk-images/Styles/Inspiration/UnsealedSpellbook/UnsealedSpellbook.png",
                    "name": "Odpe\u010det\u011bn\u00e1 kniha kouzel",
                    "shortDesc": "Vym\u011b\u0148 si mimo boj vyvol\u00e1va\u010dova kouzla za n\u011bjak\u00e1 jin\u00e1. V\u00fdm\u011bnou za unik\u00e1tn\u00ed vyvol\u00e1va\u010dovo kouzlo se zv\u00fd\u0161\u00ed \u010detnost, se kterou bude\u0161 moci prov\u00e1d\u011bt budouc\u00ed v\u00fdm\u011bny.",
                    "longDesc": "Vym\u011b\u0148 si jedno ze sv\u00fdch vyvol\u00e1va\u010dov\u00fdch kouzel za jin\u00e9, jednor\u00e1zov\u011b pou\u017eiteln\u00e9 vyvol\u00e1va\u010dovo kouzlo. Ka\u017ed\u00e9 unik\u00e1tn\u00ed vyvol\u00e1va\u010dovo kouzlo, kter\u00e9 si vym\u011bn\u00ed\u0161, ti trvale zkr\u00e1t\u00ed p\u0159eb\u00edjec\u00ed dobu v\u00fdm\u011bny o 25&nbsp;sek. (\u00favodn\u00ed p\u0159eb\u00edjec\u00ed doba v\u00fdm\u011bny je 5&nbsp;min.). <br><br>Prvn\u00ed v\u00fdm\u011bna se ti zp\u0159\u00edstupn\u00ed v 6. minut\u011b. <br><rules><br>Vyvol\u00e1va\u010dova kouzla lze vym\u011bnit pouze mimo boj. <br>Pot\u00e9, co n\u011bjak\u00e9 vym\u011bn\u011bn\u00e9 vyvol\u00e1va\u010dovo kouzlo pou\u017eije\u0161, si jej bude\u0161 moci znovu zvolit a\u017e po proveden\u00ed dal\u0161\u00edch 3 v\u00fdm\u011bn.<br>Po proveden\u00ed dvou v\u00fdm\u011bn vyvol\u00e1va\u010dov\u00fdch kouzel se kouzlu Ude\u0159it zv\u00fd\u0161\u00ed zp\u016fsobovan\u00e9 po\u0161kozen\u00ed. </rules>"
                },
                {
                    "id": 8369,
                    "key": "FirstStrike",
                    "icon": "perk-images/Styles/Inspiration/FirstStrike/FirstStrike.png",
                    "name": "Prvn\u00ed \u00fader",
                    "shortDesc": "Po zah\u00e1jen\u00ed boje proti \u0161ampion\u016fm zp\u016fsobuje\u0161 po dobu 3&nbsp;sek. 7% dodate\u010dn\u00e9 po\u0161kozen\u00ed a z\u00edsk\u00e1v\u00e1\u0161 zla\u0165\u00e1ky, jejich\u017e po\u010det se odv\u00edj\u00ed od zp\u016fsoben\u00e9ho po\u0161kozen\u00ed.",
                    "longDesc": "\u00datoky nebo schopnosti veden\u00e9 proti nep\u0159\u00e1telsk\u00e9mu \u0161ampionovi b\u011bhem 0.25&nbsp;sek. od vstupu do boje proti \u0161ampion\u016fm ti p\u0159id\u00e1vaj\u00ed 10 zla\u0165\u00e1k\u016f a poskytuj\u00ed ti na 3&nbsp;sek. efekt <b>Prvn\u00ed \u00fader</b>, d\u00edky kter\u00e9mu zp\u016fsobuje\u0161 \u0161ampion\u016fm <truedamage>7%</truedamage> dodate\u010dn\u00e9 <truedamage>po\u0161kozen\u00ed</truedamage> a <gold>50&nbsp;% (35&nbsp;% v p\u0159\u00edpad\u011b \u0161ampion\u016f bojuj\u00edc\u00edch na d\u00e1lku)</gold> tohoto bonusov\u00e9ho po\u0161kozen\u00ed se ti p\u0159em\u011bn\u00ed na <gold>zla\u0165\u00e1ky</gold>.<br><br>P\u0159eb\u00edjec\u00ed doba: <scaleLevel>25\u201315</scaleLevel>&nbsp;sek."
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8306,
                    "key": "HextechFlashtraption",
                    "icon": "perk-images/Styles/Inspiration/HextechFlashtraption/HextechFlashtraption.png",
                    "name": "Magitechnick\u00fd skokostroj",
                    "shortDesc": "Kdy\u017e se kouzlu Skok po\u010d\u00edt\u00e1 p\u0159eb\u00edjec\u00ed doba, nahrazuje ho <i>Magiskok</i>.<br><br><i>Magiskok</i>: Po soust\u0159ed\u011bn\u00ed se p\u0159enese\u0161 na novou pozici.",
                    "longDesc": "Kdy\u017e se kouzlu Skok po\u010d\u00edt\u00e1 p\u0159eb\u00edjec\u00ed doba, nahrazuje ho <i>Magiskok</i>.<br><br><i>Magiskok</i>: Po soust\u0159ed\u011bn\u00ed dlouh\u00e9m 2 sek. se p\u0159enese\u0161 na novou pozici.<br><br>P\u0159eb\u00edjec\u00ed doba: 20 sek. Jakmile za\u010dne\u0161 bojovat s n\u011bjak\u00fdm \u0161ampionem, za\u010dne se kouzlu po\u010d\u00edtat p\u0159eb\u00edjec\u00ed doba dlouh\u00e1 10 sek."
                },
                {
                    "id": 8304,
                    "key": "MagicalFootwear",
                    "icon": "perk-images/Styles/Inspiration/MagicalFootwear/MagicalFootwear.png",
                    "name": "Magick\u00e9 boty",
                    "shortDesc": "Ve 12. minut\u011b z\u00edsk\u00e1\u0161 zdarma boty, do t\u00e9 doby si v\u0161ak \u017e\u00e1dn\u00e9 boty koupit nem\u016f\u017ee\u0161. Ka\u017ed\u00e1 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Takedown'>likvidace</lol-uikit-tooltipped-keyword> usp\u00ed\u0161\u00ed z\u00edsk\u00e1n\u00ed bot o 45 sek.",
                    "longDesc": "Ve 12. minut\u011b z\u00edsk\u00e1\u0161 zdarma M\u00edrn\u011b magick\u00e9 boty, do t\u00e9 doby si v\u0161ak \u017e\u00e1dn\u00e9 boty koupit nem\u016f\u017ee\u0161. Za ka\u017edou provedenou likvidaci se doba pro z\u00edsk\u00e1n\u00ed bot zkracuje o 45&nbsp;sek.<br><br>M\u00edrn\u011b magick\u00e9 boty ti poskytuj\u00ed dodate\u010dn\u00fdch <speed>10 k rychlosti pohybu</speed>."
                },
                {
                    "id": 8321,
                    "key": "CashBack",
                    "icon": "perk-images/Styles/Inspiration/CashBack/CashBack2.png",
                    "name": "Vratn\u00e9 pen\u00edze",
                    "shortDesc": "P\u0159i zakoupen\u00ed legend\u00e1rn\u00edch p\u0159edm\u011bt\u016f z\u00edsk\u00e1\u0161 zp\u011bt \u010d\u00e1st pou\u017eit\u00fdch zla\u0165\u00e1k\u016f.",
                    "longDesc": "P\u0159i zakoupen\u00ed legend\u00e1rn\u00edch p\u0159edm\u011bt\u016f z\u00edsk\u00e1\u0161 zp\u011bt 6&nbsp;% zla\u0165\u00e1k\u016f."
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8313,
                    "key": "PerfectTiming",
                    "icon": "perk-images/Styles/Inspiration/PerfectTiming/AlchemistCabinet.png",
                    "name": "Trojit\u00fd lektvar",
                    "shortDesc": "Po dosa\u017een\u00ed 3. \u00farovn\u011b z\u00edsk\u00e1\u0161 Elix\u00edr chamtivosti.<br>Po dosa\u017een\u00ed 6. \u00farovn\u011b z\u00edsk\u00e1\u0161 Elix\u00edr s\u00edly.<br>Po dosa\u017een\u00ed 9. \u00farovn\u011b z\u00edsk\u00e1\u0161 Elix\u00edr dovednosti. ",
                    "longDesc": "Po dosa\u017een\u00ed 3. \u00farovn\u011b z\u00edsk\u00e1\u0161 Elix\u00edr chamtivosti.<br>Po dosa\u017een\u00ed 6. \u00farovn\u011b z\u00edsk\u00e1\u0161 Elix\u00edr s\u00edly.<br>Po dosa\u017een\u00ed 9. \u00farovn\u011b z\u00edsk\u00e1\u0161 Elix\u00edr dovednosti. "
                },
                {
                    "id": 8352,
                    "key": "TimeWarpTonic",
                    "icon": "perk-images/Styles/Inspiration/TimeWarpTonic/TimeWarpTonic.png",
                    "name": "N\u00e1poj zak\u0159iven\u00ed \u010dasu",
                    "shortDesc": "Lektvary ud\u011bl\u00ed \u010d\u00e1st obnovovan\u00fdch zdroj\u016f okam\u017eit\u011b.",
                    "longDesc": "P\u0159i pou\u017eit\u00ed lektvaru z\u00edsk\u00e1\u0161 40&nbsp;% obnovovan\u00e9ho zdrav\u00ed okam\u017eit\u011b.<br><br>"
                },
                {
                    "id": 8345,
                    "key": "BiscuitDelivery",
                    "icon": "perk-images/Styles/Inspiration/BiscuitDelivery/BiscuitDelivery.png",
                    "name": "Z\u00e1silka su\u0161enek",
                    "shortDesc": "Ka\u017ed\u00fdch 2&nbsp;min. z\u00edsk\u00e1\u0161 zdarma su\u0161enku, a to a\u017e do 6&nbsp;min. Spot\u0159ebov\u00e1n\u00edm nebo prodejem su\u0161enky se ti trvale zv\u00fd\u0161\u00ed maxim\u00e1ln\u00ed hodnota many a z\u00e1rove\u0148 se ti obnov\u00ed zdrav\u00ed a mana.",
                    "longDesc": "Z\u00e1silka su\u0161enek: Ka\u017ed\u00fdch 2&nbsp;min. z\u00edsk\u00e1\u0161 p\u0159edm\u011bt TotalBiscuitova su\u0161enka v\u011b\u010dn\u00e9 v\u016fle, a to a\u017e do 6&nbsp;min.<br><br>Su\u0161enky ti obnov\u00ed 8&nbsp;% chyb\u011bj\u00edc\u00edho zdrav\u00ed a many. Spot\u0159ebov\u00e1n\u00edm nebo prodejem su\u0161enky se ti trvale zv\u00fd\u0161\u00ed maxim\u00e1ln\u00ed hodnota many o 40 bod\u016f. <br><br><i>Bez many:</i> \u0160ampion\u016fm bez many se m\u00edsto toho obnov\u00ed 12&nbsp;% chyb\u011bj\u00edc\u00edho zdrav\u00ed. "
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8347,
                    "key": "CosmicInsight",
                    "icon": "perk-images/Styles/Inspiration/CosmicInsight/CosmicInsight.png",
                    "name": "Kosmick\u00e9 porozum\u011bn\u00ed",
                    "shortDesc": "+<attention>18</attention> ke zrychlen\u00ed vyvol\u00e1va\u010dov\u00fdch kouzel<br>+<attention>10</attention> ke zrychlen\u00ed p\u0159edm\u011bt\u016f",
                    "longDesc": "+<attention>18</attention> ke zrychlen\u00ed vyvol\u00e1va\u010dov\u00fdch kouzel<br>+<attention>10</attention> ke zrychlen\u00ed p\u0159edm\u011bt\u016f"
                },
                {
                    "id": 8410,
                    "key": "ApproachVelocity",
                    "icon": "perk-images/Styles/Resolve/ApproachVelocity/ApproachVelocity.png",
                    "name": "N\u00e1b\u011bhov\u00e1 rychlost",
                    "shortDesc": "Kdy\u017e m\u00ed\u0159\u00ed\u0161 k nedalek\u00fdm nep\u0159\u00e1telsk\u00fdm \u0161ampion\u016fm, jim\u017e byla <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_ImpairMov'>omezena pohyblivost</lol-uikit-tooltipped-keyword>, z\u00edsk\u00e1\u0161 bonus k <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_MS'>rychlosti pohybu</lol-uikit-tooltipped-keyword>, kter\u00fd je v\u011bt\u0161\u00ed, pokud byla nep\u0159\u00edteli omezena pohyblivost tv\u00fdm p\u0159i\u010din\u011bn\u00edm.",
                    "longDesc": "Kdy\u017e m\u00ed\u0159\u00ed\u0161 k nedalek\u00fdm nep\u0159\u00e1telsk\u00fdm \u0161ampion\u016fm, jim\u017e byla omezena pohyblivost, z\u00edsk\u00e1\u0161 <speed>7.5&nbsp;% k rychlosti pohybu</speed>. Tento bonus se zv\u00fd\u0161\u00ed na <speed>15&nbsp;%</speed>, kdy\u017e n\u011bjak\u00e9mu nep\u0159\u00e1telsk\u00e9mu \u0161ampionovi omez\u00ed\u0161 pohyblivost ty. <br><br>Dosah pro aktivaci omezuj\u00edc\u00edm efektem od spojenc\u016f: 1000"
                },
                {
                    "id": 8316,
                    "key": "JackOfAllTrades",
                    "icon": "perk-images/Styles/Inspiration/JackOfAllTrades/JackofAllTrades2.png",
                    "name": "V\u0161eum\u011bl",
                    "shortDesc": "Za ka\u017ed\u00fd r\u016fzn\u00fd atribut z\u00edskan\u00fd z p\u0159edm\u011bt\u016f dostane\u0161 jeden stupe\u0148 V\u0161eum\u011bla. Ka\u017ed\u00fd stupe\u0148 ti poskytuje <speed>1 ke zrychlen\u00ed schopnost\u00ed</speed>.<br><br>Na 5. a 10. stupni dostane\u0161 bonus k <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'>adaptivn\u00ed s\u00edle</lol-uikit-tooltipped-keyword>.",
                    "longDesc": "Za ka\u017ed\u00fd r\u016fzn\u00fd atribut z\u00edskan\u00fd z p\u0159edm\u011bt\u016f dostane\u0161 jeden stupe\u0148 V\u0161eum\u011bla. Ka\u017ed\u00fd stupe\u0148 ti poskytuje <speed>1 ke zrychlen\u00ed schopnost\u00ed</speed>.<br><br>Na 5. a 10. stupni dostane\u0161 bonus ve v\u00fd\u0161i 10 a n\u00e1sledn\u011b 25 bod\u016f k <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'>adaptivn\u00ed s\u00edle</lol-uikit-tooltipped-keyword>."
                }
            ]
        }
    ]
}