import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select, {
  ActionMeta,
  OptionProps,
  components,
  SingleValueProps,
  SingleValue,
  MultiValue,
} from 'react-select';
import { FaSearch } from 'react-icons/fa';
import champions from '../../staticData/AllChampions/champions_en_US';
import colors from 'tailwindcss/colors';
import {
  getSummonerProfiles,
  SummonerProfile,
} from '../../firebase/championDAO';
import { forEach } from 'lodash';

// Define an interface for your option
interface OptionType {
  value: string;
  label: string;
  image: string;
  descr: string;
}

export interface RegionOptionType {
  value?: string;
  label?: string;
  image?: string;
}

interface SummonerProfileOptionType extends SummonerProfile {
  label: string;
  value: string;
  image: string;
  descr: string;
}

// Generic Option component
const GenericOption: React.FC<
  OptionProps<OptionType | SummonerProfileOptionType, false>
> = (props) => {
  const data = props.data as SummonerProfileOptionType;
  return (
    <components.Option {...props}>
      <div className="flex items-start w-full">
        <img
          src={data.image}
          alt={data.riotIdGameName || data.label}
          className="w-10 h-10 mr-1"
        />
        <div className="flex flex-col items-start">
          <div className="text-xs md:text-lg">
            {data.riotIdGameName || data.label}
          </div>
          <div className="text-xxs md:text-xs text-gray-400 italic">
            {data.riotIdTagline || data.descr}
          </div>
        </div>
      </div>
    </components.Option>
  );
};

// RegionOption component
const RegionOption: React.FC<OptionProps<RegionOptionType, false>> = (
  props
) => {
  return (
    <components.Option {...props}>
      <div className="flex justify-between opacity-20">
        <div>{props.data.label}</div>
      </div>
    </components.Option>
  );
};

// Define region options and default region
export const regionOptions: RegionOptionType[] = [
  { value: 'BR1', label: 'BR', image: '/regionimages/Brazil.png' },
  { value: 'EUN1', label: 'EUNE', image: '/regionimages/Europe.png' },
  { value: 'EUW1', label: 'EUW', image: '/regionimages/Europe.png' },
  {
    value: 'LA1',
    label: 'LAN',
    image: '/regionimages/Latin_America_North.png',
  },
  {
    value: 'LA2',
    label: 'LAS',
    image: '/regionimages/Latin_America_South.png',
  },
  { value: 'NA1', label: 'NA', image: '/regionimages/North_America.png' },
  { value: 'OC1', label: 'OC', image: '/regionimages/Oceania.png' },
  { value: 'RU1', label: 'RU', image: '/regionimages/Russia.png' },
  { value: 'TR1', label: 'TR', image: '/regionimages/Turkey.png' },
  { value: 'JP1', label: 'JP', image: '/regionimages/Japan.png' },
  { value: 'KR', label: 'KR', image: '/regionimages/Republic_of_Korea.png' },
  { value: 'PH2', label: 'PH', image: '/regionimages/Philippines.png' },
  { value: 'SG2', label: 'SG', image: '/regionimages/Singapore.png' },
  { value: 'TW2', label: 'TW', image: '/regionimages/Taiwan.png' },
  { value: 'TH2', label: 'TH', image: '/regionimages/Thailand.png' },
  { value: 'VN2', label: 'VN', image: '/regionimages/Vietnam.png' },
  { value: 'ME1', label: 'ME', image: '/regionimages/unknown.png' },
];

const defaultRegion: RegionOptionType = {
  value: 'EUW1',
  label: 'EUW',
  image: '/regionimages/Europe.png',
};

// Define the options based on your champions
const options: OptionType[] = champions.map((champion) => ({
  value: champion.champId,
  label: champion.name,
  image: champion.image,
  descr: champion.quote,
}));

// Define a custom DropdownIndicator component that doesn't render anything
const DropdownIndicator = () => null;

// Define styles for the Select component
const customStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
  }),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    textAlign: 'left',
    fontSize: '0.8rem',
    '@media (min-width: 768px)': {
      fontSize: '1.05rem',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#0E1117',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? colors.gray[900] : 'transparent',
    borderBottom: `1px solid ${colors.slate[900]}`,
    color: 'white',
    borderTop: `1px solid ${colors.slate[900]}`,
    ':hover': {
      backgroundColor: '#1C2530',
      cursor: 'pointer',
    },
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'white',
    fontSize: '16px',
    ':hover': {
      cursor: 'text',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
};

const RegionSingleValue: React.FC<
  SingleValueProps<RegionOptionType, false>
> = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <div className="min-w-[5rem] md:w-full">
      <div className="text-xxs md:text-xs text-gray-500">Region</div>
      <div className="text-xxs md:text-sm">{children}</div>
    </div>
  </components.SingleValue>
);

const regionSelectStyles = {
  ...customStyles,
  control: (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    ':hover': {
      cursor: 'pointer',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#0E1117',
  }),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    color: 'white',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
};

interface ChampionSearchBarProps {
  padding: string;
}

const ChampionSearchBar: React.FC<ChampionSearchBarProps> = ({ padding }) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<RegionOptionType | null>(
    defaultRegion
  );
  const [profiles, setProfile] = useState<SummonerProfileOptionType[] | null>(
    null
  );
  const [summonerName, setSummonerName] = useState('');

  const handleDropdownClick = (
    selectedOption: OptionType | null,
    meta: ActionMeta<OptionType>
  ) => {
    setSelectedOption(selectedOption);
    if (meta.action === 'select-option') {
      if ('profileIcon' in selectedOption!) {
        // Navigate to profile
        const profile = selectedOption as SummonerProfileOptionType;
        let selected1: RegionOptionType = {
          value: profile.regionalServer,
          label: profile.regionalServer,
          image: profile.regionalServer,
        };

        setSelectedRegion(selected1);
        navigate(
          `/summoner/${profile.regionalServer}/${profile.value}/${profile.label}`
        );
        setProfile(null);
        setSummonerName('');
      } else {
        // Navigate to champion
        navigate(`/champion/${selectedOption!.value}`);
        setSelectedOption(null);
        setSelectedRegion(selectedRegion);
        setProfile(null);
        setSummonerName('');
      }
      // Reset state after navigation
      resetSearchState();
    }
  };

  const resetSearchState = () => {
    setSelectedOption(null);

    setProfile(null);
    setSummonerName('');
  };

  const handleRegionChange = (
    selectedRegion: SingleValue<RegionOptionType> | MultiValue<RegionOptionType>
  ) => {
    const singleSelectedRegion = Array.isArray(selectedRegion)
      ? selectedRegion[0]
      : selectedRegion;
    setSelectedRegion(singleSelectedRegion);
  };

  const handleSearchClick = async () => {
    const [name, tag] = summonerName.split('#');
    const usedTagline = selectedRegion?.label === 'NA' ? 'NA1' : (tag ? tag : selectedRegion?.label);

    const url = `/summoner/${selectedRegion?.value}/${name.trim()}/${usedTagline}`;
    navigate(url);
    setSelectedOption(null);
  };

  const handleInputChange = async (
    inputValue: string,
    { action }: { action: string }
  ) => {
    if (action === 'input-change') {
      setSummonerName(inputValue);

      if (!inputValue) return;

      try {
        const fetchedProfiles = await getSummonerProfiles(inputValue);
        console.log('fetchedProfiles: ', fetchedProfiles);
        if (fetchedProfiles) {
          const profileOptions = fetchedProfiles.map((profile) => ({
            ...profile,
            label: profile.riotIdTagline,
            regionalServer: profile.regionalServer,
            value: profile.riotIdGameName,
            image: `/profileicon/${profile.profileIcon}.png`,
            descr: profile.riotIdTagline,
          }));
          setProfile(profileOptions);
        }
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  const combinedOptions = profiles ? [...profiles, ...options] : options;

  return (
    <div className="flex items-center justify-center w-full">
      <div
        className={`flex justify-between bg-[#0E1117] border-1 border-[#1C2530] rounded-md w-full ${padding} px-4 items-center shadow-lg`}
      >
        <div className="flex items-center justify-between w-full">
          <div className="w-full flex">
            <Select
              isOptionDisabled={() => false}
              isClearable={false}
              isSearchable={false}
              options={regionOptions}
              onChange={handleRegionChange}
              value={selectedRegion}
              styles={regionSelectStyles}
              placeholder="Search for champion or summoner"
              menuPlacement="auto"
              components={{
                Option: RegionOption,
                SingleValue: RegionSingleValue,
                IndicatorSeparator: () => null,
              }}
            />
            <div className="text-xs md:text-base w-full">
              <Select
                isClearable
                isSearchable
                openMenuOnClick={true}
                options={combinedOptions}
                onChange={handleDropdownClick}
                onInputChange={handleInputChange}
                placeholder="Search for champion or summoner"
                value={selectedOption}
                inputValue={summonerName}
                components={{
                  Option: GenericOption,
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                styles={customStyles}
                onKeyDown={handleKeyDown}
              />
            </div>
            <button
              onClick={handleSearchClick}
              className="pl-2 self-center mr-5"
            >
              <FaSearch />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChampionSearchBar;
