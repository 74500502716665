export default {
    "id": 8100,
    "icon": "perk-images/Styles/7200_Domination.png",
    "name": "Dominance",
    "slots": [
        {
            "runes": [
                {
                    "id": 8112,
                    "key": "Electrocute",
                    "icon": "perk-images/Styles/Domination/Electrocute/Electrocute.png",
                    "name": "Z\u00e1sah proudem",
                    "shortDesc": "Pokud v rozmez\u00ed 3 sek. zas\u00e1hne\u0161 n\u011bjak\u00e9ho \u0161ampiona 3 <b>samostatn\u00fdmi</b> \u00fatoky nebo schopnostmi, zp\u016fsob\u00ed\u0161 mu bonusov\u00e9 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_AdaptiveDmg'>adaptivn\u00ed po\u0161kozen\u00ed</lol-uikit-tooltipped-keyword>.",
                    "longDesc": "Pokud v rozmez\u00ed 3&nbsp;sek. zas\u00e1hne\u0161 n\u011bjak\u00e9ho \u0161ampiona 3 <b>samostatn\u00fdmi</b> \u00fatoky nebo schopnostmi, zp\u016fsob\u00ed\u0161 mu bonusov\u00e9 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_AdaptiveDmg'><font color='#48C4B7'>adaptivn\u00ed po\u0161kozen\u00ed</font></lol-uikit-tooltipped-keyword>.<br><br>Po\u0161kozen\u00ed: 50\u2013190 bod\u016f (+0.1 bonusov\u00e9ho \u00fato\u010dn\u00e9ho po\u0161kozen\u00ed, +0.05 s\u00edly schopnost\u00ed).<br><br>P\u0159eb\u00edjec\u00ed doba: 20&nbsp;sek.<br><br><hr><i>\u201e\u0158\u00edkali jsme jim P\u00e1ni hrom\u016f, proto\u017ee hovo\u0159it o jejich blesc\u00edch, p\u0159ivol\u00e1vali bychom na sebe pohromu.\u201c</i>"
                },
                {
                    "id": 8128,
                    "key": "DarkHarvest",
                    "icon": "perk-images/Styles/Domination/DarkHarvest/DarkHarvest.png",
                    "name": "Temn\u00e9 \u017en\u011b",
                    "shortDesc": "Kdy\u017e zp\u016fsob\u00ed\u0161 po\u0161kozen\u00ed \u0161ampionovi s n\u00edzk\u00fdm zdrav\u00edm, zp\u016fsob\u00ed\u0161 mu <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_AdaptiveDmg'>adaptivn\u00ed po\u0161kozen\u00ed</lol-uikit-tooltipped-keyword> a sebere\u0161 jeho du\u0161i.",
                    "longDesc": "Kdy\u017e zp\u016fsob\u00ed\u0161 po\u0161kozen\u00ed \u0161ampionovi s m\u00e9n\u011b ne\u017e 50&nbsp;% zdrav\u00ed, zp\u016fsob\u00ed\u0161 mu <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_AdaptiveDmg'>adaptivn\u00ed po\u0161kozen\u00ed</lol-uikit-tooltipped-keyword> a sebere\u0161 jeho du\u0161i, \u010d\u00edm\u017e se efektu Temn\u00e9 \u017en\u011b trvale zv\u00fd\u0161\u00ed po\u0161kozen\u00ed o 9 bod\u016f.<br><br>Po\u0161kozen\u00ed efektem Temn\u00e9 \u017en\u011b: 20 (+9 bod\u016f po\u0161kozen\u00ed za ka\u017edou du\u0161i) (+0.1 bonusov\u00e9ho \u00fato\u010dn\u00e9ho po\u0161kozen\u00ed) (+0.05 s\u00edly schopnost\u00ed)<br>P\u0159eb\u00edjec\u00ed doba: 35&nbsp;sek. (likvidace ji resetuje na 1.0&nbsp;sek.)"
                },
                {
                    "id": 9923,
                    "key": "HailOfBlades",
                    "icon": "perk-images/Styles/Domination/HailOfBlades/HailOfBlades.png",
                    "name": "Smr\u0161\u0165 me\u010d\u016f",
                    "shortDesc": "P\u0159i prvn\u00edch 3 \u00fatoc\u00edch v\u016f\u010di nep\u0159\u00e1telsk\u00fdm \u0161ampion\u016fm z\u00edsk\u00e1\u0161 v\u00fdrazn\u00fd bonus k rychlosti \u00fatok\u016f.",
                    "longDesc": "P\u0159i n\u00e1sleduj\u00edc\u00edch 3 \u00fatoc\u00edch na nep\u0159\u00e1telsk\u00e9ho \u0161ampiona m\u016f\u017ee\u0161 z\u00edskat 110&nbsp;% k rychlosti \u00fatok\u016f (80&nbsp;% v p\u0159\u00edpad\u011b \u0161ampion\u016f bojuj\u00edc\u00edch na d\u00e1lku).<br><br>Pokud mezi jednotliv\u00fdmi \u00fatoky uplyne v\u00edce ne\u017e 3&nbsp;sek., tento efekt skon\u010d\u00ed.<br><br>P\u0159eb\u00edjec\u00ed doba: 12&nbsp;sek.<br><br><rules>Resetov\u00e1n\u00ed \u00fatok\u016f zvy\u0161uje limit \u00fatok\u016f o 1.<br>Tento efekt ti umo\u017e\u0148uje do\u010dasn\u011b p\u0159ekonat horn\u00ed limit rychlosti \u00fatok\u016f.</rules>"
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8126,
                    "key": "CheapShot",
                    "icon": "perk-images/Styles/Domination/CheapShot/CheapShot.png",
                    "name": "R\u00e1na pod p\u00e1s",
                    "shortDesc": "Nep\u0159\u00e1telsk\u00fdm \u0161ampion\u016fm s <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_ImpairAct'>omezenou pohyblivost\u00ed nebo akcemi</lol-uikit-tooltipped-keyword> zp\u016fsob\u00ed\u0161 bonusov\u00e9 p\u0159\u00edm\u00e9 po\u0161kozen\u00ed. ",
                    "longDesc": "Kdy\u017e po\u0161kod\u00ed\u0161 nep\u0159\u00e1tele s <b>omezenou pohyblivost\u00ed nebo akcemi</b>, zp\u016fsob\u00ed\u0161 jim 10-45 bod\u016f bonusov\u00e9ho p\u0159\u00edm\u00e9ho po\u0161kozen\u00ed (v z\u00e1vislosti na \u00farovni).<br><br>P\u0159eb\u00edjec\u00ed doba: 4 sek.<br><rules>Bonus se aktivuje p\u0159i po\u0161kozen\u00ed zp\u016fsoben\u00e9m po omezen\u00ed pohyblivosti.</rules>"
                },
                {
                    "id": 8139,
                    "key": "TasteOfBlood",
                    "icon": "perk-images/Styles/Domination/TasteOfBlood/GreenTerror_TasteOfBlood.png",
                    "name": "Chu\u0165 krve",
                    "shortDesc": "Kdy\u017e zp\u016fsob\u00ed\u0161 po\u0161kozen\u00ed nep\u0159\u00e1telsk\u00e9mu \u0161ampionovi, vyl\u00e9\u010d\u00ed\u0161 se.",
                    "longDesc": "Kdy\u017e zp\u016fsob\u00ed\u0161 po\u0161kozen\u00ed nep\u0159\u00e1telsk\u00e9mu \u0161ampionovi, vyl\u00e9\u010d\u00ed\u0161 se.<br><br>L\u00e9\u010den\u00ed: 16-40 zdrav\u00ed (+0.1 bonusov\u00e9ho \u00fato\u010dn\u00e9ho po\u0161kozen\u00ed, +0.05 s\u00edly schopnost\u00ed) (podle \u00farovn\u011b).<br><br>P\u0159eb\u00edjec\u00ed doba: 20 sek."
                },
                {
                    "id": 8143,
                    "key": "SuddenImpact",
                    "icon": "perk-images/Styles/Domination/SuddenImpact/SuddenImpact.png",
                    "name": "N\u00e1hl\u00fd dopad",
                    "shortDesc": "Pot\u00e9, co pou\u017eije\u0161 p\u0159eb\u011bhnut\u00ed, skok, p\u0159esun \u010di teleportaci nebo p\u0159ijde\u0161 o neviditelnost, bude tvoje po\u0161kozen\u00ed z\u00e1kladn\u00edmi \u00fatoky a schopnostmi zp\u016fsobovat nep\u0159\u00e1telsk\u00fdm \u0161ampion\u016fm bonusov\u00e9 p\u0159\u00edm\u00e9 po\u0161kozen\u00ed.",
                    "longDesc": "Pot\u00e9, co pou\u017eije\u0161 p\u0159eb\u011bhnut\u00ed, skok, p\u0159esun \u010di teleportaci nebo p\u0159ijde\u0161 o neviditelnost, bude po\u0161kozen\u00ed z\u00e1kladn\u00edmi \u00fatoky a schopnostmi zp\u016fsobovat nep\u0159\u00e1telsk\u00fdm \u0161ampion\u016fm po dobu 4&nbsp;sek. bonusov\u00e9 <trueDamage>p\u0159\u00edm\u00e9 po\u0161kozen\u00ed ve v\u00fd\u0161i 20\u201380 bod\u016f</trueDamage>, hodnota se odv\u00edj\u00ed od \u00farovn\u011b.<br><br>P\u0159eb\u00edjec\u00ed doba: 10&nbsp;sek."
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8136,
                    "key": "ZombieWard",
                    "icon": "perk-images/Styles/Domination/ZombieWard/ZombieWard.png",
                    "name": "Zombie totem",
                    "shortDesc": "Po <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Takedown'>likvidaci</lol-uikit-tooltipped-keyword> nep\u0159\u00e1telsk\u00e9ho totemu vyra\u0161\u00ed z jeho trosek spojeneck\u00fd Zombie totem. Ka\u017ed\u00fd Zombie totem ti p\u0159id\u00e1 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'>adaptivn\u00ed</lol-uikit-tooltipped-keyword> bonus k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed nebo s\u00edle schopnost\u00ed, kter\u00fd se je\u0161t\u011b zv\u00fd\u0161\u00ed po dosa\u017een\u00ed maxima stup\u0148\u016f.",
                    "longDesc": "Po <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Takedown'>likvidaci</lol-uikit-tooltipped-keyword> nep\u0159\u00e1telsk\u00e9ho totemu vyra\u0161\u00ed z jeho trosek spojeneck\u00fd Zombie totem.<br><br>Za ka\u017ed\u00fd vytvo\u0159en\u00fd Zombie totem dostane\u0161 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'><font color='#48C4B7'>adaptivn\u00ed</font></lol-uikit-tooltipped-keyword> bonus ve v\u00fd\u0161i +1.2 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed nebo +2 k s\u00edle schopnost\u00ed, tento efekt se m\u016f\u017ee nas\u010d\u00edtat a\u017e 10x. <br><br>Kdy\u017e vyvol\u00e1\u0161 Zombie totem celkem 10x, z\u00edsk\u00e1\u0161 nav\u00edc +10 k adaptivn\u00ed s\u00edle.<br><br>Zombie totemy jsou viditeln\u00e9, maj\u00ed \u017eivotnost 120&nbsp;sek. a nezapo\u010d\u00edt\u00e1vaj\u00ed se do limitu totem\u016f."
                },
                {
                    "id": 8120,
                    "key": "GhostPoro",
                    "icon": "perk-images/Styles/Domination/GhostPoro/GhostPoro.png",
                    "name": "P\u0159\u00edzra\u010dn\u00fd poro",
                    "shortDesc": "Kdy\u017e skon\u010d\u00ed \u017eivotnost n\u011bjak\u00e9ho tv\u00e9ho totemu, objev\u00ed se m\u00edsto n\u011bj P\u0159\u00edzra\u010dn\u00fd poro. P\u0159\u00edzra\u010dn\u00fd poro poskytuje v\u00fdhled na okol\u00ed, dokud jej nep\u0159\u00e1tel\u00e9 neobjev\u00ed. Ka\u017ed\u00fd P\u0159\u00edzra\u010dn\u00fd poro a j\u00edm odhalen\u00fd nep\u0159\u00e1telsk\u00fd \u0161ampion ti p\u0159idaj\u00ed <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'>adaptivn\u00ed</lol-uikit-tooltipped-keyword> bonus k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed nebo s\u00edle schopnost\u00ed, kter\u00fd se je\u0161t\u011b zv\u00fd\u0161\u00ed po dosa\u017een\u00ed maxima stup\u0148\u016f.",
                    "longDesc": "Kdy\u017e skon\u010d\u00ed \u017eivotnost n\u011bjak\u00e9ho tv\u00e9ho totemu, objev\u00ed se m\u00edsto n\u011bj P\u0159\u00edzra\u010dn\u00fd poro, kter\u00fd na 90&nbsp;sek. poskytuje v\u00fdhled na sv\u00e9 okol\u00ed. Kdy\u017e se v jeho bl\u00edzkosti objev\u00ed n\u011bjak\u00fd nep\u0159\u00e1telsk\u00fd \u0161ampion, P\u0159\u00edzra\u010dn\u00fd poro se lekne a ute\u010de.<br><br>Ka\u017ed\u00fd p\u0159ivolan\u00fd P\u0159\u00edzra\u010dn\u00fd poro a j\u00edm odhalen\u00fd nep\u0159\u00e1telsk\u00fd \u0161ampion ti p\u0159idaj\u00ed <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'><font color='#48C4B7'>adaptivn\u00ed</font></lol-uikit-tooltipped-keyword> bonus ve v\u00fd\u0161i +1.2 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed nebo +2 k s\u00edle schopnost\u00ed, tento efekt se m\u016f\u017ee nas\u010d\u00edtat a\u017e 10x. <br><br>Po nas\u010d\u00edt\u00e1n\u00ed 10 stup\u0148\u016f nav\u00edc z\u00edsk\u00e1\u0161 +10 k adaptivn\u00ed s\u00edle."
                },
                {
                    "id": 8138,
                    "key": "EyeballCollection",
                    "icon": "perk-images/Styles/Domination/EyeballCollection/EyeballCollection.png",
                    "name": "Sb\u00edrka bulv",
                    "shortDesc": "<lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Takedown'>Likvidacemi</lol-uikit-tooltipped-keyword> \u0161ampion\u016f z\u00edsk\u00e1v\u00e1\u0161 bulvy. Za ka\u017edou z\u00edskanou bulvu obdr\u017e\u00ed\u0161 trval\u00fd <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'>adaptivn\u00ed</lol-uikit-tooltipped-keyword> bonus k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed nebo s\u00edle schopnost\u00ed, kter\u00fd se je\u0161t\u011b zv\u00fd\u0161\u00ed po dokon\u010den\u00ed cel\u00e9 sb\u00edrky.",
                    "longDesc": "Likvidacemi \u0161ampion\u016f z\u00edsk\u00e1v\u00e1\u0161 bulvy. Ka\u017ed\u00e1 bulva ti p\u0159id\u00e1v\u00e1 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'><font color='#48C4B7'>adaptivn\u00ed</font></lol-uikit-tooltipped-keyword> bonus ve v\u00fd\u0161i +1.2 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed nebo +2 k s\u00edle schopnost\u00ed. <br><br>Kdy\u017e po nasb\u00edr\u00e1n\u00ed 10 bulev dokon\u010d\u00ed\u0161 svou sb\u00edrku, z\u00edsk\u00e1\u0161 nav\u00edc <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'><font color='#48C4B7'>adaptivn\u00ed</font></lol-uikit-tooltipped-keyword> bonus ve v\u00fd\u0161i +6 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed nebo +10 k s\u00edle schopnost\u00ed.<br><br>Za likvidaci \u0161ampiona obdr\u017e\u00ed\u0161 1 bulvu."
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8135,
                    "key": "TreasureHunter",
                    "icon": "perk-images/Styles/Domination/TreasureHunter/TreasureHunter.png",
                    "name": "Lovec poklad\u016f",
                    "shortDesc": "<b>Jedine\u010dn\u00e9</b> <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Takedown'>likvidace</lol-uikit-tooltipped-keyword> poskytuj\u00ed p\u0159i prvn\u00edm z\u00edsk\u00e1n\u00ed dodate\u010dn\u00e9 zla\u0165\u00e1ky. ",
                    "longDesc": "P\u0159i p\u0159\u00ed\u0161t\u00edm sebr\u00e1n\u00ed stupn\u011b <i>Lovce odm\u011bn</i> z\u00edsk\u00e1\u0161 nav\u00edc <gold>50 zla\u0165\u00e1k\u016f</gold>. Za ka\u017ed\u00fd stupe\u0148 <i>Lovce odm\u011bn</i> se mno\u017estv\u00ed z\u00edskan\u00fdch zla\u0165\u00e1k\u016f zvy\u0161uje o <gold>20</gold>, a to a\u017e do v\u00fd\u0161e <gold>130 zla\u0165\u00e1k\u016f</gold>.<br><br>Stupn\u011b <i>Lovce odm\u011bn</i> z\u00edsk\u00e1\u0161, kdykoliv poprv\u00e9 zlikviduje\u0161 n\u011bjak\u00e9ho nep\u0159\u00e1telsk\u00e9ho \u0161ampiona."
                },
                {
                    "id": 8105,
                    "key": "RelentlessHunter",
                    "icon": "perk-images/Styles/Domination/RelentlessHunter/RelentlessHunter.png",
                    "name": "Ne\u00fanavn\u00fd lovec",
                    "shortDesc": "<b>Unik\u00e1tn\u00ed</b> <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Takedown'>likvidace</lol-uikit-tooltipped-keyword> p\u0159id\u00e1vaj\u00ed trval\u00fd bonus <b>k <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_MS'>rychlosti pohybu</lol-uikit-tooltipped-keyword> mimo boj</b>. ",
                    "longDesc": "Z\u00edsk\u00e1\u0161 <speed>8 k rychlosti pohybu</speed> mimo boj za ka\u017ed\u00fd stupe\u0148 <i>Lovce odm\u011bn</i>.<br><br>Stupn\u011b <i>Lovce odm\u011bn</i> z\u00edsk\u00e1\u0161, kdykoliv poprv\u00e9 zlikviduje\u0161 n\u011bjak\u00e9ho nep\u0159\u00e1telsk\u00e9ho \u0161ampiona."
                },
                {
                    "id": 8106,
                    "key": "UltimateHunter",
                    "icon": "perk-images/Styles/Domination/UltimateHunter/UltimateHunter.png",
                    "name": "Ultim\u00e1tn\u00ed lovec",
                    "shortDesc": "<lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Takedown'>Likvidace</lol-uikit-tooltipped-keyword> <b>jedine\u010dn\u00fdch</b> nep\u0159\u00e1tel ti poskytuje trval\u00fd bonus ke zkr\u00e1cen\u00ed p\u0159eb\u00edjec\u00ed doby ultim\u00e1tky. ",
                    "longDesc": "Tvoje ultim\u00e1tka z\u00edsk\u00e1 <attention>6</attention> ke zrychlen\u00ed schopnost\u00ed a dal\u0161\u00edch <attention>5</attention> ke zrychlen\u00ed schopnost\u00ed za ka\u017ed\u00fd stupe\u0148 <i>Lovce odm\u011bn</i>.<br><br>Stupn\u011b <i>Lovce odm\u011bn</i> z\u00edsk\u00e1\u0161, kdykoliv poprv\u00e9 zlikviduje\u0161 n\u011bjak\u00e9ho nep\u0159\u00e1telsk\u00e9ho \u0161ampiona."
                }
            ]
        }
    ]
}