export default {
    "id": 8200,
    "icon": "perk-images/Styles/7202_Sorcery.png",
    "name": "\u010carod\u011bjnictv\u00ed",
    "slots": [
        {
            "runes": [
                {
                    "id": 8214,
                    "key": "SummonAery",
                    "icon": "perk-images/Styles/Sorcery/SummonAery/SummonAery.png",
                    "name": "Vyvolat Aery",
                    "shortDesc": "Tv\u00e9 \u00fatoky a schopnosti vys\u00edlaj\u00ed na c\u00edl Aery, kter\u00e1 zp\u016fsob\u00ed po\u0161kozen\u00ed nep\u0159\u00e1tel\u016fm a spojenc\u016fm poskytne \u0161t\u00edt.",
                    "longDesc": "Kdy\u017e sv\u00fdm z\u00e1kladn\u00edm \u00fatokem nebo kouzlem zp\u016fsob\u00ed\u0161 po\u0161kozen\u00ed nep\u0159\u00e1telsk\u00e9mu \u0161ampionovi, vy\u0161le\u0161 k n\u011bmu Aery, kter\u00e1 mu v z\u00e1vislosti na \u00farovni zp\u016fsob\u00ed 10\u201350 bod\u016f po\u0161kozen\u00ed (+<scaleAP>0.05 s\u00edly schopnost\u00ed</scaleAP>) (+<scaleAD>0.1 bonusov\u00e9ho \u00fato\u010dn\u00e9ho po\u0161kozen\u00ed</scaleAD>).<br><br>Kdy\u017e sv\u00fdmi schopnostmi pos\u00edl\u00ed\u0161 nebo ochr\u00e1n\u00ed\u0161 spojence, vy\u0161le\u0161 k n\u011bmu Aery, kter\u00e1 mu v z\u00e1vislosti na \u00farovni poskytne \u0161t\u00edt o s\u00edle 30\u2013100 bod\u016f (+<scaleAP>0.05 s\u00edly schopnost\u00ed</scaleAP>) (+<scaleAD>0.1 bonusov\u00e9ho \u00fato\u010dn\u00e9ho po\u0161kozen\u00ed</scaleAD>).<br><br>Aery nelze vyslat znovu, dokud se k tob\u011b nevr\u00e1t\u00ed."
                },
                {
                    "id": 8229,
                    "key": "ArcaneComet",
                    "icon": "perk-images/Styles/Sorcery/ArcaneComet/ArcaneComet.png",
                    "name": "Magick\u00e1 kometa",
                    "shortDesc": "Kdy\u017e n\u011bjak\u00e9mu \u0161ampionovi zp\u016fsob\u00ed\u0161 po\u0161kozen\u00ed svou schopnost\u00ed, hod\u00ed\u0161 na jeho pozici kometu, kter\u00e1 mu zp\u016fsob\u00ed dal\u0161\u00ed po\u0161kozen\u00ed.",
                    "longDesc": "Kdy\u017e n\u011bjak\u00e9mu \u0161ampionovi zp\u016fsob\u00ed\u0161 po\u0161kozen\u00ed svou schopnost\u00ed, vrhne\u0161 na jeho pozici kometu. Jestli\u017ee Magick\u00e9 komet\u011b pr\u00e1v\u011b b\u011b\u017e\u00ed p\u0159eb\u00edjec\u00ed doba, jej\u00ed zb\u00fdvaj\u00edc\u00ed p\u0159eb\u00edjec\u00ed doba se zkr\u00e1t\u00ed.<br><br><lol-uikit-tooltipped-keyword key='LinkTooltip_Description_AdaptiveDmg'><font color='#48C4B7'>Adaptivn\u00ed po\u0161kozen\u00ed</font></lol-uikit-tooltipped-keyword>: 30-130 bod\u016f v z\u00e1vislosti na \u00farovni (<scaleAP>+0.05 s\u00edly schopnost\u00ed</scaleAP> a <scaleAD>0.1 bonusov\u00e9ho \u00fato\u010dn\u00e9ho po\u0161kozen\u00ed</scaleAD>)<br>P\u0159eb\u00edjec\u00ed doba: 20-8 sek.<br><rules><br>Zkr\u00e1cen\u00ed p\u0159eb\u00edjec\u00ed doby:<br>Jeden c\u00edl: 20 %.<br>Plo\u0161n\u00fd efekt: 10 %.<br>Postupn\u00e9 po\u0161kozen\u00ed: 5 %.<br></rules>"
                },
                {
                    "id": 8230,
                    "key": "PhaseRush",
                    "icon": "perk-images/Styles/Sorcery/PhaseRush/PhaseRush.png",
                    "name": "F\u00e1zov\u00fd shon",
                    "shortDesc": "Pokud n\u011bjak\u00e9ho \u0161ampiona zas\u00e1hne\u0161 3 <b>samostatn\u00fdmi</b> \u00fatoky nebo schopnostmi, z\u00edsk\u00e1\u0161 kr\u00e1tk\u00fd bonus k <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_MS'>rychlosti pohybu</lol-uikit-tooltipped-keyword>. ",
                    "longDesc": "Pokud b\u011bhem 4&nbsp;sek. zas\u00e1hne\u0161 n\u011bjak\u00e9ho \u0161ampiona 3 \u00fatoky nebo <b>samostatn\u00fdmi</b> schopnostmi, z\u00edsk\u00e1\u0161 v z\u00e1vislosti na \u00farovni <speed>15\u201340&nbsp;% k rychlosti pohybu</speed> a 75&nbsp;% k odolnosti v\u016f\u010di zpomalen\u00ed. <hr>U kontaktn\u00edch \u0161ampion\u016f se tento bonus zvy\u0161uje na <speed>25\u201350&nbsp;% k rychlosti pohybu</speed>.<hr>Trv\u00e1n\u00ed: 3&nbsp;sek.<br>P\u0159eb\u00edjec\u00ed doba: 30\u201310&nbsp;sek."
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8224,
                    "key": "NullifyingOrb",
                    "icon": "perk-images/Styles/Sorcery/NullifyingOrb/Pokeshield.png",
                    "name": "Neutralizuj\u00edc\u00ed oko",
                    "shortDesc": "Kdy\u017e ti klesne zdrav\u00ed p\u0159\u00edli\u0161 n\u00edzko vinou utrp\u011bn\u00e9ho magick\u00e9ho po\u0161kozen\u00ed, z\u00edsk\u00e1\u0161 \u0161t\u00edt proti magick\u00e9mu po\u0161kozen\u00ed.",
                    "longDesc": "Pokud utrp\u00ed\u0161 magick\u00e9 po\u0161kozen\u00ed, p\u0159i kter\u00e9m by ti kleslo zdrav\u00ed pod 30&nbsp;%, z\u00edsk\u00e1\u0161 nejd\u0159\u00edv \u0161t\u00edt, kter\u00fd b\u011bhem 4&nbsp;sek. absorbuje v z\u00e1vislosti na \u00farovni 35\u2013110 bod\u016f magick\u00e9ho po\u0161kozen\u00ed (+14&nbsp;% bonusov\u00e9ho \u00fato\u010dn\u00e9ho po\u0161kozen\u00ed) + (9&nbsp;% s\u00edly schopnost\u00ed).<br><br>P\u0159eb\u00edjec\u00ed doba: 60&nbsp;sek."
                },
                {
                    "id": 8226,
                    "key": "ManaflowBand",
                    "icon": "perk-images/Styles/Sorcery/ManaflowBand/ManaflowBand.png",
                    "name": "Prsten manov\u00e9ho proudu",
                    "shortDesc": "Kdy\u017e zas\u00e1hne\u0161 nep\u0159\u00e1telsk\u00e9ho \u0161ampiona n\u011bjakou schopnost\u00ed, maxim\u00e1ln\u00ed hodnota many se ti trvale zv\u00fd\u0161\u00ed o +25, a to a\u017e do celkov\u00e9ho bonusu 250 bod\u016f.<br><br>Kdy\u017e bonusov\u00e1 mana dos\u00e1hne hodnoty 250 bod\u016f, v\u017edy po uplynut\u00ed 5 sek. se ti obnov\u00ed 1 % chyb\u011bj\u00edc\u00ed many.",
                    "longDesc": "Kdy\u017e zas\u00e1hne\u0161 nep\u0159\u00e1telsk\u00e9ho \u0161ampiona n\u011bjakou schopnost\u00ed, maxim\u00e1ln\u00ed hodnota many se ti trvale zv\u00fd\u0161\u00ed o +25, a to a\u017e do celkov\u00e9ho bonusu 250 bod\u016f.<br><br>Kdy\u017e bonusov\u00e1 mana dos\u00e1hne hodnoty 250 bod\u016f, v\u017edy po uplynut\u00ed 5 sek. se ti obnov\u00ed 1 % chyb\u011bj\u00edc\u00ed many.<br><br>P\u0159eb\u00edjec\u00ed doba: 15 sek."
                },
                {
                    "id": 8275,
                    "key": "NimbusCloak",
                    "icon": "perk-images/Styles/Sorcery/NimbusCloak/6361.png",
                    "name": "Obla\u010dn\u00fd pl\u00e1\u0161\u0165",
                    "shortDesc": "Po sesl\u00e1n\u00ed vyvol\u00e1va\u010dova kouzla z\u00edsk\u00e1\u0161 kr\u00e1tk\u00fd bonus k <speed>rychlosti pohybu</speed>, kter\u00fd ti umo\u017e\u0148uje proch\u00e1zet ostatn\u00edmi jednotkami.",
                    "longDesc": "Po sesl\u00e1n\u00ed vyvol\u00e1va\u010dova kouzla z\u00edsk\u00e1\u0161 bonus k <speed>rychlosti pohybu</speed>, kter\u00fd trv\u00e1 2&nbsp;sek. a umo\u017e\u0148uje ti proch\u00e1zet ostatn\u00edmi jednotkami.<br><br>Zv\u00fd\u0161en\u00ed: <speed>5\u201325&nbsp;% k rychlosti pohybu</speed> v z\u00e1vislosti na p\u0159eb\u00edjec\u00ed dob\u011b vyvol\u00e1va\u010dova kouzla. (Del\u0161\u00ed p\u0159eb\u00edjec\u00ed doba vyvol\u00e1va\u010dova kouzla poskytuje vy\u0161\u0161\u00ed bonus k <speed>rychlosti pohybu</speed>.) "
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8210,
                    "key": "Transcendence",
                    "icon": "perk-images/Styles/Sorcery/Transcendence/Transcendence.png",
                    "name": "Prostoupen\u00ed",
                    "shortDesc": "Po dosa\u017een\u00ed n\u00e1sleduj\u00edc\u00edch \u00farovn\u00ed z\u00edsk\u00e1\u0161 ur\u010dit\u00e9 bonusy:<br>5. \u00farove\u0148: +5 ke <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_CDR'>zrychlen\u00ed schopnost\u00ed</lol-uikit-tooltipped-keyword> <br>8. \u00farove\u0148: +5 ke <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_CDR'>zrychlen\u00ed schopnost\u00ed</lol-uikit-tooltipped-keyword> <br>11. \u00farove\u0148: P\u0159i likvidaci \u0161ampiona se ti zkr\u00e1t\u00ed zb\u00fdvaj\u00edc\u00ed p\u0159eb\u00edjec\u00ed doba z\u00e1kladn\u00edch schopnost\u00ed o 20&nbsp;%.",
                    "longDesc": "Po dosa\u017een\u00ed n\u00e1sleduj\u00edc\u00edch \u00farovn\u00ed z\u00edsk\u00e1\u0161 ur\u010dit\u00e9 bonusy:<br>5. \u00farove\u0148: +5 ke <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_CDR'>zrychlen\u00ed schopnost\u00ed</lol-uikit-tooltipped-keyword> <br>8. \u00farove\u0148: +5 ke <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_CDR'>zrychlen\u00ed schopnost\u00ed</lol-uikit-tooltipped-keyword> <br>11. \u00farove\u0148: P\u0159i likvidaci \u0161ampiona se ti zkr\u00e1t\u00ed zb\u00fdvaj\u00edc\u00ed p\u0159eb\u00edjec\u00ed doba z\u00e1kladn\u00edch schopnost\u00ed o 20&nbsp;%.<br>"
                },
                {
                    "id": 8234,
                    "key": "Celerity",
                    "icon": "perk-images/Styles/Sorcery/Celerity/CelerityTemp.png",
                    "name": "Rychlost",
                    "shortDesc": "V\u0161echny bonusy k <speed>rychlosti pohybu</speed> na tebe p\u016fsob\u00ed o 7&nbsp;% siln\u011bji a z\u00edsk\u00e1v\u00e1\u0161 <speed>1&nbsp;% k rychlosti pohybu</speed>.",
                    "longDesc": "V\u0161echny bonusy k pohybu na tebe p\u016fsob\u00ed o 7&nbsp;% siln\u011bji a z\u00edsk\u00e1v\u00e1\u0161 <speed>1&nbsp;% k rychlosti pohybu</speed>."
                },
                {
                    "id": 8233,
                    "key": "AbsoluteFocus",
                    "icon": "perk-images/Styles/Sorcery/AbsoluteFocus/AbsoluteFocus.png",
                    "name": "Absolutn\u00ed soust\u0159ed\u011bn\u00ed",
                    "shortDesc": "Kdy\u017e m\u00e1\u0161 v\u00edc ne\u017e 70 % zdrav\u00ed, z\u00edsk\u00e1\u0161 bonusov\u00e9 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_AdaptiveDmg'>adaptivn\u00ed po\u0161kozen\u00ed</lol-uikit-tooltipped-keyword>.",
                    "longDesc": "Kdy\u017e m\u00e1\u0161 v\u00edc ne\u017e 70 % zdrav\u00ed, z\u00edsk\u00e1\u0161 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'><font color='#48C4B7'>adaptivn\u00ed</font></lol-uikit-tooltipped-keyword> bonus ve v\u00fd\u0161i a\u017e +18 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed nebo +30 k s\u00edle schopnost\u00ed (podle \u00farovn\u011b). <br><br>Na 1. \u00farovni z\u00edsk\u00e1\u0161 +1.8 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed nebo +3 k s\u00edle schopnost\u00ed. "
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8237,
                    "key": "Scorch",
                    "icon": "perk-images/Styles/Sorcery/Scorch/Scorch.png",
                    "name": "Se\u017eehnut\u00ed",
                    "shortDesc": "Tv\u00e1 prvn\u00ed \u00fato\u010dn\u00e1 schopnost pop\u00e1l\u00ed ka\u017ed\u00fdch 10&nbsp;sek. zasa\u017een\u00e9 \u0161ampiony.",
                    "longDesc": "Tvoje n\u00e1sleduj\u00edc\u00ed \u00fato\u010dn\u00e1 schopnost zap\u00e1l\u00ed \u0161ampiony, kte\u0159\u00ed b\u011bhem 1&nbsp;sek. utrp\u00ed 20\u201340 bod\u016f bonusov\u00e9ho magick\u00e9ho po\u0161kozen\u00ed (v z\u00e1vislosti na \u00farovni).<br><br>P\u0159eb\u00edjec\u00ed doba: 10&nbsp;sek."
                },
                {
                    "id": 8232,
                    "key": "Waterwalking",
                    "icon": "perk-images/Styles/Sorcery/Waterwalking/Waterwalking.png",
                    "name": "Ch\u016fze po vod\u011b",
                    "shortDesc": "Kdy\u017e jsi v \u0159ece, z\u00edsk\u00e1\u0161 bonus k <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_MS'>rychlosti pohybu</lol-uikit-tooltipped-keyword> a <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'>adaptivn\u011b</lol-uikit-tooltipped-keyword> tak\u00e9 k s\u00edle schopnost\u00ed nebo \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed.",
                    "longDesc": "Kdy\u017e jsi v \u0159ece, z\u00edsk\u00e1\u0161 <speed>10 k rychlosti pohybu</speed> a <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'><font color='#48C4B7'>adaptivn\u00ed</font></lol-uikit-tooltipped-keyword> bonus ve v\u00fd\u0161i a\u017e +18 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed nebo +30 k s\u00edle schopnost\u00ed (v z\u00e1vislosti na \u00farovni).<br><br><hr><br><i>K\u00e9\u017e m\u00e1\u0161 rychlost divok\u00e9 \u0159eky a hbitost vypla\u0161en\u00e9ho \u0159\u00ed\u010dn\u00edho kraba.</i><br>"
                },
                {
                    "id": 8236,
                    "key": "GatheringStorm",
                    "icon": "perk-images/Styles/Sorcery/GatheringStorm/GatheringStorm.png",
                    "name": "\u017denouc\u00ed se bou\u0159e",
                    "shortDesc": "V pr\u016fb\u011bhu z\u00e1pasu z\u00edsk\u00e1v\u00e1\u0161 zvy\u0161uj\u00edc\u00ed se <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'>adaptivn\u00ed</lol-uikit-tooltipped-keyword> bonus k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed nebo s\u00edle schopnost\u00ed.",
                    "longDesc": "V\u017edy po uplynut\u00ed 10 min. z\u00edsk\u00e1\u0161 bonus k s\u00edle schopnost\u00ed \u010di \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed (<lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'><font color='#48C4B7'>adaptivn\u011b</font></lol-uikit-tooltipped-keyword>).<br><br><i>10 minut</i>: + 8 k s\u00edle schopnost\u00ed nebo 5 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed.<br><i>20 minut</i>: + 24 k s\u00edle schopnost\u00ed nebo 14 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed <br><i>30 minut</i>: dal\u0161\u00edch + 48 k s\u00edle schopnost\u00ed nebo 29 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed<br><i>40 minut</i>: + 80 k s\u00edle schopnost\u00ed nebo 48 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed<br><i>50 minut</i>: + 120 k s\u00edle schopnost\u00ed nebo 72 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed<br><i>60 min</i>: + 168 k s\u00edle schopnost\u00ed nebo 101 k \u00fato\u010dn\u00e9mu po\u0161kozen\u00ed<br>atd."
                }
            ]
        }
    ]
}