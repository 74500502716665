export default {
    "id": 8000,
    "icon": "perk-images/Styles/7201_Precision.png",
    "name": "P\u0159esnost",
    "slots": [
        {
            "runes": [
                {
                    "id": 8005,
                    "key": "PressTheAttack",
                    "icon": "perk-images/Styles/Precision/PressTheAttack/PressTheAttack.png",
                    "name": "Nepolevuj\u00edc\u00ed \u00fatok",
                    "shortDesc": "Zas\u00e1hne\u0161-li nep\u0159\u00e1telsk\u00e9ho \u0161ampiona 3x po sob\u011b, zp\u016fsob\u00ed\u0161 mu bonusov\u00e9 po\u0161kozen\u00ed a tvoje po\u0161kozen\u00ed se pos\u00edl\u00ed do doby, ne\u017e opust\u00ed\u0161 boj proti \u0161ampion\u016fm.",
                    "longDesc": "Zas\u00e1hne\u0161-li n\u011bjak\u00e9ho nep\u0159\u00e1telsk\u00e9ho \u0161ampiona 3 z\u00e1kladn\u00edmi \u00fatoky po sob\u011b, zp\u016fsob\u00ed\u0161 mu 40\u2013160 bod\u016f bonusov\u00e9ho <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_AdaptiveDmg'><font color='#48C4B7'>adaptivn\u00edho po\u0161kozen\u00ed</font></lol-uikit-tooltipped-keyword> (v z\u00e1vislosti na \u00farovni) a ve\u0161ker\u00e9 dal\u0161\u00ed po\u0161kozen\u00ed, kter\u00e9 zp\u016fsob\u00ed\u0161 do konce tohoto boje proti \u0161ampion\u016fm, se zv\u00fd\u0161\u00ed o 8&nbsp;%."
                },
                {
                    "id": 8021,
                    "key": "FleetFootwork",
                    "icon": "perk-images/Styles/Precision/FleetFootwork/FleetFootwork.png",
                    "name": "Hbit\u00e9 nohy",
                    "shortDesc": "\u00dato\u010den\u00edm a pohybov\u00e1n\u00edm se nas\u010d\u00edt\u00e1vaj\u00ed stupn\u011b energie. Po dosa\u017een\u00ed 100 stup\u0148\u016f t\u011b tv\u016fj n\u00e1sleduj\u00edc\u00ed \u00fatok vyl\u00e9\u010d\u00ed a zv\u00fd\u0161\u00ed ti <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_MS'>rychlost pohybu</lol-uikit-tooltipped-keyword>.",
                    "longDesc": "\u00dato\u010den\u00edm a pohybov\u00e1n\u00edm se nas\u010d\u00edt\u00e1vaj\u00ed stupn\u011b energie. Po dosa\u017een\u00ed 100 stup\u0148\u016f bude tv\u016fj n\u00e1sleduj\u00edc\u00ed \u00fatok nabit\u00fd energi\u00ed.<br><br>\u00datoky nabit\u00e9 energi\u00ed ti vyl\u00e9\u010d\u00ed 10\u2013130 zdrav\u00ed (+0.1 bonusov\u00e9ho \u00fato\u010dn\u00e9ho po\u0161kozen\u00ed, +0.05 s\u00edly schopnost\u00ed) a poskytnou ti na 1&nbsp;sek. <speed>20&nbsp;% k rychlosti pohybu</speed>.<br><br>\u00da\u010dinnost l\u00e9\u010den\u00ed je u \u0161ampion\u016f bojuj\u00edc\u00edch na d\u00e1lku 60&nbsp;% a \u00fa\u010dinnost bonusu k rychlosti pohybu 75&nbsp;%. P\u0159i pou\u017eit\u00ed proti poskok\u016fm m\u00e1 ve\u0161ker\u00e9 l\u00e9\u010den\u00ed 15% \u00fa\u010dinnost."
                },
                {
                    "id": 8010,
                    "key": "Conqueror",
                    "icon": "perk-images/Styles/Precision/Conqueror/Conqueror.png",
                    "name": "Dobyvatel",
                    "shortDesc": "\u00dato\u010den\u00edm na nep\u0159\u00e1telsk\u00e9 \u0161ampiony z\u00edsk\u00e1v\u00e1\u0161 stupn\u011b adaptivn\u00ed s\u00edly. Po nas\u010d\u00edt\u00e1n\u00ed 12 stup\u0148\u016f se l\u00e9\u010d\u00ed\u0161 ve v\u00fd\u0161i ur\u010dit\u00e9ho procenta po\u0161kozen\u00ed zp\u016fsobovan\u00e9ho \u0161ampion\u016fm.",
                    "longDesc": "Z\u00e1kladn\u00ed \u00fatoky nebo kouzla, je\u017e zp\u016fsob\u00ed po\u0161kozen\u00ed nep\u0159\u00e1telsk\u00e9mu \u0161ampionovi, ti poskytnou na 5&nbsp;sek. 2 stupn\u011b efektu Dobyvatel, ka\u017ed\u00fd stupe\u0148 p\u0159id\u00e1v\u00e1 1.8\u20134 bod\u016f <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'><font color='#48C4B7'>adaptivn\u00ed s\u00edly</font></lol-uikit-tooltipped-keyword>. Efekt se m\u016f\u017ee nas\u010d\u00edtat a\u017e 12x. \u0160ampion\u016fm bojuj\u00edc\u00edm na d\u00e1lku p\u0159id\u00e1vaj\u00ed z\u00e1kladn\u00ed \u00fatoky jen 1 stupe\u0148.<br><br>Po dosa\u017een\u00ed maxim\u00e1ln\u00edho po\u010dtu stup\u0148\u016f se vyl\u00e9\u010d\u00ed\u0161 ve v\u00fd\u0161i 8&nbsp;% po\u0161kozen\u00ed zp\u016fsoben\u00e9ho \u0161ampion\u016fm (5&nbsp;% v p\u0159\u00edpad\u011b \u0161ampion\u016f bojuj\u00edc\u00edch na d\u00e1lku)."
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 9101,
                    "key": "AbsorbLife",
                    "icon": "perk-images/Styles/Precision/AbsorbLife/AbsorbLife.png",
                    "name": "\u010cerp\u00e1n\u00ed \u017eivota",
                    "shortDesc": "Zabit\u00edm c\u00edle si vyl\u00e9\u010d\u00ed\u0161 \u010d\u00e1st zdrav\u00ed.",
                    "longDesc": "Zabit\u00edm c\u00edle si vyl\u00e9\u010d\u00ed\u0161 @HealAmount@ zdrav\u00ed."
                },
                {
                    "id": 9111,
                    "key": "Triumph",
                    "icon": "perk-images/Styles/Precision/Triumph.png",
                    "name": "Triumf",
                    "shortDesc": "<lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Takedown'>Likvidace</lol-uikit-tooltipped-keyword> ti obnovuj\u00ed 5 % chyb\u011bj\u00edc\u00edho zdrav\u00ed a poskytnou ti nav\u00edc 20 zla\u0165\u00e1k\u016f. ",
                    "longDesc": "Likvidace ti obnovuj\u00ed 5&nbsp;% chyb\u011bj\u00edc\u00edho zdrav\u00ed, 2.5&nbsp;% maxim\u00e1ln\u00edho zdrav\u00ed a poskytuj\u00ed ti nav\u00edc 20 zla\u0165\u00e1k\u016f. <br><br><hr><br><i>\u201eNejnebezpe\u010dn\u011bj\u0161\u00ed lovn\u00e1 zv\u011b\u0159 p\u0159in\u00e1\u0161\u00ed tu nejv\u011bt\u0161\u00ed sl\u00e1vu.\u201c <br>\u2013 Noxijsk\u00fd pr\u016fzkumn\u00edk</i>"
                },
                {
                    "id": 8009,
                    "key": "PresenceOfMind",
                    "icon": "perk-images/Styles/Precision/PresenceOfMind/PresenceOfMind.png",
                    "name": "Duchap\u0159\u00edtomnost",
                    "shortDesc": "P\u0159i po\u0161kozen\u00ed nep\u0159\u00e1telsk\u00e9ho \u0161ampiona se ti zv\u00fd\u0161\u00ed regenerace many nebo energie. Likvidace ti obnovuj\u00ed manu nebo energii.",
                    "longDesc": "Kdy\u017e zp\u016fsob\u00ed\u0161 po\u0161kozen\u00ed nep\u0159\u00e1telsk\u00e9mu \u0161ampionovi, zv\u00fd\u0161\u00ed se ti na 4&nbsp;sek. regenerace many o @RegenAmount@ za sekundu (80% \u00fa\u010dinnost u \u0161ampion\u016f bojuj\u00edc\u00edch na d\u00e1lku). V\u0161ichni u\u017eivatel\u00e9 energie m\u00edsto toho z\u00edsk\u00e1vaj\u00ed 1,5 bodu energie za sekundu.<br><br>Likvidace ti obnovuj\u00ed 15&nbsp;% maxim\u00e1ln\u00ed many nebo energie. "
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 9104,
                    "key": "LegendAlacrity",
                    "icon": "perk-images/Styles/Precision/LegendAlacrity/LegendAlacrity.png",
                    "name": "Legenda: Horlivost",
                    "shortDesc": "<lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Takedown'>Likvidov\u00e1n\u00edm</lol-uikit-tooltipped-keyword> nep\u0159\u00e1tel z\u00edsk\u00e1v\u00e1\u0161 trval\u00fd bonus k <b>rychlosti \u00fatok\u016f</b>. ",
                    "longDesc": "Z\u00edsk\u00e1\u0161 3&nbsp;% k rychlosti \u00fatok\u016f plus dodate\u010dn\u00fdch 1.5&nbsp;% za ka\u017ed\u00fd stupe\u0148 <i>Legendy</i> (<statGood>max. 10 stup\u0148\u016f</statGood>).<br><br>Stupn\u011b <i>Legendy</i> postupn\u011b z\u00edsk\u00e1v\u00e1\u0161 likvidac\u00ed \u0161ampion\u016f, likvidac\u00ed epick\u00fdch p\u0159\u00ed\u0161er, zab\u00edjen\u00edm velk\u00fdch p\u0159\u00ed\u0161er a zab\u00edjen\u00edm poskok\u016f."
                },
                {
                    "id": 9105,
                    "key": "LegendHaste",
                    "icon": "perk-images/Styles/Precision/LegendHaste/LegendHaste.png",
                    "name": "Legenda: Zrychlen\u00ed",
                    "shortDesc": "<lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Takedown'>Likvidov\u00e1n\u00edm</lol-uikit-tooltipped-keyword> nep\u0159\u00e1tel z\u00edsk\u00e1v\u00e1\u0161 trval\u00fd bonus ke <b>zrychlen\u00ed</b> z\u00e1kladn\u00edch schopnost\u00ed. ",
                    "longDesc": "Z\u00edsk\u00e1\u0161 1,5 ke zrychlen\u00ed z\u00e1kladn\u00edch schopnost\u00ed za ka\u017ed\u00fd stupe\u0148 <i>Legendy</i> (<statGood>max. 10 stup\u0148\u016f</statGood>).<br><br>Stupn\u011b <i>Legendy</i> postupn\u011b z\u00edsk\u00e1v\u00e1\u0161 likvidac\u00ed \u0161ampion\u016f, likvidac\u00ed epick\u00fdch p\u0159\u00ed\u0161er, zab\u00edjen\u00edm velk\u00fdch p\u0159\u00ed\u0161er a zab\u00edjen\u00edm poskok\u016f."
                },
                {
                    "id": 9103,
                    "key": "LegendBloodline",
                    "icon": "perk-images/Styles/Precision/LegendBloodline/LegendBloodline.png",
                    "name": "Legenda: Pokrevn\u00ed linie",
                    "shortDesc": "<lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Takedown'>Likvidov\u00e1n\u00edm</lol-uikit-tooltipped-keyword> nep\u0159\u00e1tel z\u00edsk\u00e1v\u00e1\u0161 trval\u00fd bonus k <b>l\u00e9\u010den\u00ed \u00fatoky</b>, a to a\u017e do ur\u010dit\u00e9 hodnoty. Jakmile j\u00ed dos\u00e1hne\u0161, zv\u00fd\u0161\u00ed se ti maxim\u00e1ln\u00ed zdrav\u00ed. Zpo\u010d\u00e1tku je tato runa slab\u0161\u00ed ne\u017e ostatn\u00ed Legendy, v z\u00e1v\u011bru utk\u00e1n\u00ed je v\u0161ak \u00fa\u010dinn\u011bj\u0161\u00ed.",
                    "longDesc": "Za ka\u017ed\u00fd stupe\u0148 <i>Legendy</i> z\u00edsk\u00e1\u0161 <scaleAD>0.35&nbsp;% k l\u00e9\u010den\u00ed \u00fatoky</scaleAD> (<statGood>max. 15 stup\u0148\u016f</statGood>). Kdy\u017e stupn\u011b <i>Legendy</i> dos\u00e1hnou maxima, z\u00edsk\u00e1\u0161 <scaleHealth>85 k maxim\u00e1ln\u00edmu zdrav\u00ed</scaleHealth>.<br><br>Stupn\u011b <i>Legendy</i> postupn\u011b z\u00edsk\u00e1v\u00e1\u0161 likvidac\u00ed \u0161ampion\u016f, likvidac\u00ed epick\u00fdch p\u0159\u00ed\u0161er, zab\u00edjen\u00edm velk\u00fdch p\u0159\u00ed\u0161er a zab\u00edjen\u00edm poskok\u016f."
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8014,
                    "key": "CoupDeGrace",
                    "icon": "perk-images/Styles/Precision/CoupDeGrace/CoupDeGrace.png",
                    "name": "R\u00e1na z milosti",
                    "shortDesc": "\u0160ampion\u016fm s n\u00edzkou hodnotou zdrav\u00ed zp\u016fsobuje\u0161 zv\u00fd\u0161en\u00e9 po\u0161kozen\u00ed.",
                    "longDesc": "\u0160ampion\u016fm s m\u00e9n\u011b ne\u017e 40&nbsp;% zdrav\u00ed zp\u016fsobuje\u0161 o 8&nbsp;% vy\u0161\u0161\u00ed po\u0161kozen\u00ed."
                },
                {
                    "id": 8017,
                    "key": "CutDown",
                    "icon": "perk-images/Styles/Precision/CutDown/CutDown.png",
                    "name": "Podseknut\u00ed",
                    "shortDesc": "\u0160ampion\u016fm s vysokou hodnotou zdrav\u00ed zp\u016fsobuje\u0161 zv\u00fd\u0161en\u00e9 po\u0161kozen\u00ed.",
                    "longDesc": "\u0160ampion\u016fm s v\u00edce ne\u017e 60&nbsp;% zdrav\u00ed zp\u016fsobuje\u0161 o 8&nbsp;% vy\u0161\u0161\u00ed po\u0161kozen\u00ed."
                },
                {
                    "id": 8299,
                    "key": "LastStand",
                    "icon": "perk-images/Styles/Sorcery/LastStand/LastStand.png",
                    "name": "Posledn\u00ed vzdor",
                    "shortDesc": "Pokud m\u00e1\u0161 m\u00e1lo zdrav\u00ed, zp\u016fsobuje\u0161 \u0161ampion\u016fm zv\u00fd\u0161en\u00e9 po\u0161kozen\u00ed.",
                    "longDesc": "Pokud m\u00e1\u0161 m\u00e9n\u011b ne\u017e 60 % zdrav\u00ed, zp\u016fsobuje\u0161 \u0161ampion\u016fm o 5-11 % vy\u0161\u0161\u00ed po\u0161kozen\u00ed. Maxim\u00e1ln\u00edho po\u0161kozen\u00ed dos\u00e1hne\u0161 na 30 % zdrav\u00ed."
                }
            ]
        }
    ]
}