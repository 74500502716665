import React from 'react';

const Terms: React.FC = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-gray-900 text-gray-200">
      <h1 className="text-4xl font-bold mb-6">Terms and Conditions</h1>

      <p className="mb-6">
        <span className="font-semibold">Terms of Service</span><br />
        <span>DATE OF LAST REVISION: 14/9/2024</span>
      </p>

      <p className="mb-6">
        <span className="font-bold text-xl">IMPORTANT, PLEASE READ THESE ONLINE TERMS OF USE CAREFULLY.</span>
      </p>

      <p className="mb-6">
        Welcome to EZ.GG. (hereafter referred to as “EZ.GG”, “we”, “us”, or “our”) provides a platform for online courses (collectively, the “Services”), which Services are accessible at EZ.GG and any other websites through which EZ.GG makes the Services available (collectively, the “Site”).
      </p>

      <p className="mb-6">
        The Site and Services are offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the “Terms”). Your use of the Site and Services constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference. We reserve the right to update or modify these Terms at any time without prior notice to you, and your continued use of the Site following EZ.GG’s posting of any changes will constitute your acceptance of such changes or modifications. We encourage you to review these Terms whenever you use the Site.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Privacy</h2>
      <p className="mb-6">
        Your use of the Site and Services are subject to EZ.GG’s Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices. EZ.GG does not knowingly collect, either online or offline, personal information from persons under the age of 13.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Your Account</h2>
      <p className="mb-6">
        If you use the Site or Services, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that EZ.GG is not responsible for third-party access to your account that results from theft or misappropriation of your account. EZ.GG and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Links to Third Party Sites/Third Party Services</h2>
      <p className="mb-6">
        The Site and Services contain links to other websites (“Linked Sites”). The Linked Sites are not under the control of EZ.GG, and EZ.GG assumes no responsibility for the content, privacy policies, or practices of any third-party websites, and you access and use these websites solely at your own risk. EZ.GG is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by EZ.GG of the site or any association with its operators.
      </p>

      <h2 className="text-2xl font-semibold mb-4">No Unlawful or Prohibited Use/Intellectual Property</h2>
      <p className="mb-6">
        You are granted a non-exclusive, non-transferable, revocable license to access and use the Site and Services strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to EZ.GG that you will not use the Site for any purpose that is unlawful or prohibited by these Terms.
      </p>

      <p className="mb-6">
        All content included as part of the Site and Services, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site or in the Application, is the property of EZ.GG, its suppliers, or third-parties and protected by trademark, copyright, and other laws that protect intellectual property and proprietary rights.
      </p>

      {/* Additional sections styled similarly */}

      <h2 className="text-2xl font-semibold mb-4">Changes to Terms</h2>
      <p className="mb-6">
        EZ.GG reserves the right, in its sole discretion, to change the Terms under which the Site and Services are offered, and such modification(s) will be effective immediately upon being posted on our Site (EZ.GG). The most current version of the Terms will supersede all previous versions. EZ.GG encourages you to periodically review the Terms to stay informed of our updates.
      </p>

      <p className="mb-6">
        IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MUST NOT USE THE SERVICE. BY USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD THE TERMS AND CONDITIONS OF THIS AGREEMENT AND YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.
      </p>
    </div>
  );
};

export default Terms;
