import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import colors from "tailwindcss/colors";

type QueueId = "420" | "440" | "450" | "400" | "720" | "700";

interface MatchHistoryDropdownProps {
  setSelectedQueueId: (queueId: QueueId | null) => void;
  setSearchValue: (value: string) => void;
  participants: string[];
  champions: string[]; // New prop for champion names
}

const MatchHistoryDropdown: React.FC<MatchHistoryDropdownProps> = ({
  setSelectedQueueId,
  setSearchValue,
  participants,
  champions, // New prop for champion names
}) => {
  const [selectedValue, setSelectedValue] = useState<string>("All");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (queueId: string | null, displayValue: string) => {
    setSelectedQueueId(queueId as QueueId | null);
    setSelectedValue(displayValue);
    setSearchValue("");
    setIsOpen(false);
  };

  const toggleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const participantOptions = participants.map((participant) => ({
    value: participant,
    label: participant,
  }));

  const championOptions = champions.map((champion) => ({
    value: champion,
    label: champion,
  }));

  const combinedOptions = [
    { label: "Participants", options: participantOptions },
    { label: "Champions", options: championOptions },
  ];

  const handleParticipantChange = (selectedOption: any) => {
    setSearchValue(selectedOption ? selectedOption.value : "");
  };

  const queueOptions = [
    { value: null, label: "All" },
    { value: "420", label: "Ranked Solo" },
    { value: "440", label: "Ranked Flex" },
    { value: "450", label: "ARAM" },
    { value: "400", label: "Normal" },
    { value: "720", label: "Arena" },
    { value: "700", label: "Clash" },
  ];

  const handleQueueChange = (selectedOption: any) => {
    handleSelect(selectedOption.value, selectedOption.label);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#0f172a", // Custom background color
      borderColor: "transparent",
      minHeight: "2rem",
      fontSize: "0.75rem", // text-xxs
      boxShadow: state.isFocused ? "0 0 0 1px #3b82f6" : "none", // Focused box shadow
      "&:hover": {
        backgroundColor: "#020617", // Prevent hover background color change
      },
      "@media (min-width: 768px)": {
        minHeight: "2.5rem",
        fontSize: "1rem", // md:text-base
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#020617", // Custom background color
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? colors.gray[900] : "transparent",
      color: "white",
      "&:hover": {
        backgroundColor: colors.gray[900], // Hover color
        color: "white",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "white",
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: colors.gray[400], // Placeholder color
    }),
    input: (provided: any) => ({
      ...provided,
      color: "white",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: colors.gray[400], // Dropdown indicator color
    }),
  };

  const customStylesSmall = {
    ...customStyles,
    control: (provided: any, state: any) => ({
      ...provided,
      ...customStyles.control(provided, state),
      width: "6rem", // Width for second select
      "@media (min-width: 768px)": {
        width: "12rem", // Width for second select on medium screens
      },
    }),
  };

  const customStylesLarge = {
    ...customStyles,
    control: (provided: any, state: any) => ({
      ...provided,
      ...customStyles.control(provided, state),
      width: "10rem", // Width for first select on mobile
      "@media (min-width: 768px)": {
        width: "18rem", // Width for first select on medium screens
      },
    }),
  };

  return (
    <div className="navbar relative h-10 w-full justify-between rounded bg-secondary shadow xl:mx-auto xl:max-w-[75%]">
      <div className="flex flex-1 items-center p-2">
        <div className="mr-1 h-4 w-0.5 bg-green md:mr-2 md:h-6"></div>
        <span className="text-sm font-bold md:text-xl">Match History</span>
      </div>
      <div className="relative flex items-center space-x-2">
        {" "}
        {/* Added space-x-2 to control spacing */}
        <Select
          classNamePrefix="react-select"
          options={combinedOptions}
          onChange={handleParticipantChange}
          placeholder="Search Played with or Champion"
          isClearable
          styles={customStylesLarge} // Use large styles for first select
        />
        <Select
          classNamePrefix="react-select"
          options={queueOptions}
          onChange={handleQueueChange}
          placeholder={selectedValue}
          styles={customStylesSmall} // Use small styles for second select
          isClearable={false}
          isSearchable={false} // Disable search
        />
      </div>
    </div>
  );
};

export default MatchHistoryDropdown;
