import React from 'react';

const AboutUs: React.FC = () => {
  return (
    <div className="max-w-3xl mx-auto p-6">
    <h1 className="text-3xl font-bold mb-4">About Us</h1>
    <p className="mb-6 text-gray-200">
      Welcome to our League of Legends third-party app! Our goal is to provide you with the most comprehensive and up-to-date
      information on champions, builds and live data. Whether you're looking up your favorite champions or checking live game data, 
      we've got you covered.
    </p>
    <h2 className="text-2xl font-semibold mb-3">Our Mission</h2>
    <p className="mb-6 text-gray-200">
      Our mission is to enhance your League of Legends experience by offering a reliable platform where you can easily find 
      detailed information on champions, player stats, and live game updates. We aim to be the go-to resource for all League 
      enthusiasts.
    </p>
    <h2 className="text-2xl font-semibold mb-3">Features</h2>
    <ul className="list-disc list-inside mb-6 text-gray-200">
      <li className="mb-2">Detailed Champion Information: Get stats, abilities, and more.</li>
      <li className="mb-2">Live Game Data: Track live matches, see player builds, and analyze strategies.</li>
      <li className="mb-2">Frequent Updates: Our app is regularly updated with the latest stats.</li>
    </ul>
    <h2 className="text-2xl font-semibold mb-3">Contact Us</h2>
    <p className="text-gray-200">
      If you have any questions, feedback, or suggestions, feel free to reach out to us at{' '}
      <a href="mailto:contact@ez.gg" className="text-blue-600 hover:text-blue-800">contact@ez.gg</a>.
    </p>
  </div>
  );
}

export default AboutUs;