import React, { useState, useEffect } from "react";
import { FeaturedGame } from "../LandingPageLiveData";
import { champions } from "../../staticData/champion_mapping"; // Import your champion mapping

interface FeaturedGamesItemsProps {
  game: FeaturedGame;
}

const FeaturedGamesItems: React.FC<FeaturedGamesItemsProps> = ({ game }) => {
  const [counter, setCounter] = useState(0); // Start at 0

  // Timer count-up logic
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1); // Increment counter
    }, 1000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div className="relative flex flex-col items-center rounded-md bg-secondary p-1">
      {/* Summoner Name, League Name, and Tier Image */}
      <div className="mb-2 flex items-center border-b border-gray-300 border-grey">
        <h2 className="mr-2 max-w-xs truncate font-oswald text-base">
          {game.participants[0].riotId}
        </h2>{" "}
        {/* Summoner Name */}
        <span className="text-md mr-2 font-oswald font-medium">
          Platinum IV
        </span>{" "}
        {/* League */}
        <div className="h-8 w-8">
          <img src="/tiers/platinum.png" alt="Tier" className="rounded-full" />{" "}
          {/* Tier image */}
        </div>
      </div>

      {/* Team Layout */}
      <div className="flex justify-center space-x-2">
        {/* Team 1 */}
        <div>
          {game.participants.slice(0, 5).map((participant, index) => {
            const championName = champions[participant.championId];
            const championImagePath = championName
              ? `/champimages/${championName}.png`
              : "/default-champ.png";
            return (
              <div className="avatar" key={index}>
                <div
                  className={`w-${8 + index * 2} rounded`} // Adjusting width size as per the original design
                >
                  <img
                    src={championImagePath}
                    alt={championName || "Unknown Champion"}
                  />
                </div>
              </div>
            );
          })}
        </div>

        {/* Team 2 */}
        <div>
          {game.participants.slice(5).map((participant, index) => {
            const championName = champions[participant.championId];
            const championImagePath = championName
              ? `/champimages/${championName}.png`
              : "/default-champ.png";
            const size = 16 - index * 2; // Decreasing width for Team 2
            return (
              <div className="avatar" key={index}>
                <div className={`w-${size} rounded`}>
                  <img
                    src={championImagePath}
                    alt={championName || "Unknown Champion"}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FeaturedGamesItems;
