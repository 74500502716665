import React from 'react';

type ErrorProps = {
  message: string;
  errorCode: string;
};

const Error: React.FC<ErrorProps> = ({ message, errorCode }) => {
  return (
    <section className="bg-primary gap-3 dark:bg-primary min-h-screen flex flex-col items-center md:justify-center justify-start mt-14">
      <h1 className='text-5xl'>{errorCode} Error</h1>
      <p className='text-2xl'>{message}</p>
        {/* Image */}
        <img
          className=" rounded-lg object-cover opacity-50 md:w-1/3 md:h-1/3"
          src={`/logo/error.png`}
          alt="Error illustration"
        />
    </section>
  );
};

export default Error;
