import React from "react";

interface AlertComponentProps {
  errorMessage: string;
}

const AlertComponent: React.FC<AlertComponentProps> = ({ errorMessage }) => {
  return (
    <div role="alert" className="alert shadow-lg w-60 md:w-80">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className="stroke-info h-6 w-6 shrink-0"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        ></path>
      </svg>
      <div>
        <h3 className="font-bold">No matches found!</h3>
        <div className="text-xs">
          <p>{errorMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default AlertComponent;
