import React from 'react';

interface RankInfo {
  leagueId: string;
  queueType: string;
  tier: string;
  rank: string;
  summonerId: string;
  leaguePoints: number;
  wins: number;
  losses: number;
  veteran: boolean;
  inactive: boolean;
  freshBlood: boolean;
  hotStreak: boolean;
}

interface RankDetailsProps {
  rankInfo?: RankInfo[];
}

const RankDetails: React.FC<RankDetailsProps> = ({ rankInfo = [] }) => {
  const queueTypes: { [key: string]: string } = {
    RANKED_SOLO_5x5: 'Ranked Solo/Duo',
    RANKED_FLEX_SR: 'Flex',
    ARENA: 'Arena', // Assuming "ARENA" is the identifier for the new mode
  };

  const tierImages: { [key: string]: string } = {
    CHALLENGER: '/tiers/CHALLENGER.png',
    GRANDMASTER: '/tiers/GRANDMASTER.png',
    MASTER: '/tiers/MASTER.png',
    DIAMOND: '/tiers/DIAMOND.png',
    EMERALD: '/tiers/EMERALD.png',
    PLATINUM: '/tiers/PLATINUM.png',
    GOLD: '/tiers/GOLD.png',
    SILVER: '/tiers/SILVER.png',
    BRONZE: '/tiers/BRONZE.png',
    IRON: '/tiers/IRON.png',
  };

  const defaultRankInfo = {
    rank: 'Unranked',
    lp: '-',
    wins: 0,
    losses: 0,
    img: '/tiers/PROFILE_UNRANKED.png',
  };

  // Creating the rank data for the expected modes
  const modes = ['RANKED_SOLO_5x5', 'ARENA', 'RANKED_FLEX_SR'];

  const ranks = modes.map((mode) => {
    const info = rankInfo.find((info) => info.queueType === mode);
    if (info) {
      return {
        mode: queueTypes[info.queueType] || 'Unknown',
        rank: `${info.tier} ${info.rank}`,
        lp: `${info.leaguePoints}LP`,
        wins: info.wins,
        losses: info.losses,
        img: tierImages[info.tier] || '/tiers/PROFILE_UNRANKED.png',
      };
    } else {
      return {
        mode: queueTypes[mode] || 'Unknown',
        ...defaultRankInfo,
      };
    }
  });

  return (
    <div className="flex w-full justify-start space-x-2">
      {ranks.map((rank) => (
        <div
          key={rank.mode}
          className="bg-secondary bg-opacity-80 p-2 rounded flex flex-col items-center shadow-lg shadow-slate-700 relative ring-1 ring-blue
          w-[180px] h-[140px] md:w-[140px] md:h-[140px]"
        >
          <img
            src={rank.img}
            alt={`${rank.rank} Badge`}
            className="w-12 h-12 mb-2"
          />
          <div className="text-xs font-bold text-center">{rank.mode}</div>
          <div className="text-xs text-center">{rank.rank}</div>
          <div className="text-xs text-center opacity-70">{rank.lp}</div>
          <div className="text-xs text-center opacity-40">
            {rank.wins === 0 && rank.losses === 0
              ? '-'
              : rank.wins && rank.losses
              ? `${rank.wins}W ${rank.losses}L (${Math.floor(
                  (rank.wins / (rank.wins + rank.losses)) * 100
                )}%)`
              : '0W 0L (0%)'}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RankDetails;
