import { useState } from 'react';
import { Participant } from './MatchHistory';
import MatchHistoryItemDropdownOverview from './MatchHistoryItemDropdownOverview';
import MatchHistoryItemDropdownPerformance from './MatchHistoryItemDropdownPerformance';
import MatchHistoryItemDropdownIndividualPerformance from './MatchHistoryItemDropdownIndividualPerformance';

interface MatchHistoryItemDropdownProps {
  participants: Participant[];
  itemData: Record<string, any>;
}

const MatchHistoryItemDropdown: React.FC<MatchHistoryItemDropdownProps> = ({
  participants,
  itemData,
}) => {
  const [activeTab, setActiveTab] = useState<
    'Overview' | 'Performance' | 'Individual'
  >('Overview');
  const activetabClass = "tab-active text-stone-50 font-bold [--tab-bg:#010b2b] [--tab-border-color:#57F287]"
  return (
    <div className="flex flex-col bg-secondary rounded-md p-2 z-0 md:p-4 lg:p-4 xl:max-w-[75%] xl:mx-auto">
      {/* DaisyUI Tabs */}
      <div role="tablist" className="tabs tabs-lifted mb-4">
        <a
          role="tab"
          className={`tab ${
            activeTab === 'Overview'
              ? activetabClass
              : ''
          }`}
          onClick={() => setActiveTab('Overview')}
        >
          Overview
        </a>
        <a
          role="tab"
          className={`tab ${
            activeTab === 'Performance'
              ? activetabClass
              : ''
          }`}
          onClick={() => setActiveTab('Performance')}
        >
          Team Performance
        </a>
        <a
          role="tab"
          className={`tab ${
            activeTab === 'Individual'
              ? activetabClass
              : ''
          }`}
          onClick={() => setActiveTab('Individual')}
        >
          Individual Performance
        </a>
      </div>

      {/* Tab Content */}
      {activeTab === 'Overview' && (
        <MatchHistoryItemDropdownOverview
          participants={participants}
          itemData={itemData}
        />
      )}

      {activeTab === 'Performance' && (
        <MatchHistoryItemDropdownPerformance participants={participants} />
      )}

      {activeTab === 'Individual' && (
        <MatchHistoryItemDropdownIndividualPerformance
          participants={participants}
        />
      )}
    </div>
  );
};

export default MatchHistoryItemDropdown;
