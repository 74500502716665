// Summoner.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MatchHistory from './components/matchhistory/MatchHistory';
import SummonerDetails from './components/summonerdetails/SummonerDetails';
import { SummonerDetailsSkeleton, MatchHistorySkeleton } from './Skeletons';
import Error from '../components/error';
import { httpsCallable } from 'firebase/functions'; // Import httpsCallable from Firebase SDK
import { functions } from '../firebase/championDAO';

const Summoner: React.FC = () => {
  const { region, summonername, tagline } = useParams<{
    
    region: string;
    summonername: string;
    tagline: string;
  }>();
  const [summonerData, setSummonerData] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const regionLowerCase = region?.toLowerCase()
    setSummonerData(null); // Reset state when parameters change
    setIsLoaded(false); // Reset loaded state when parameters change
    setIsError(false);

    const fetchSummonerData = async () => {
      try {
        // Use httpsCallable to call the onCall function
        const getMatchesByGameNameAndTagLine = httpsCallable(
          functions,
          'getMatchesByGameNameAndTagLine'
        );

        // Call the function with the correct parameters
        const response = await getMatchesByGameNameAndTagLine({
          regionalServer: regionLowerCase,
          gameName: summonername,
          tagLine: tagline,
          start: 0, // Example value, adjust as needed
          count: 5, // Example value, adjust as needed
        });

        setSummonerData(response.data); // Set the response data
        setIsLoaded(true); // Set the loaded state
      } catch (error) {
        setIsError(true);
        console.error('Error calling function:', error);
      }
    };

    fetchSummonerData();
  }, [region, summonername, tagline]);

  return (
    <>
      {isError ? (
        <Error message="Unexpected Error" errorCode="404" />
      ) : (
        <div className="container mx-auto min-h-[70rem]">
          <div
            className={`transition-opacity duration-1000 ${
              isLoaded ? 'opacity-0' : 'opacity-100'
            }`}
          >
            {!isLoaded && (
              <>
                <SummonerDetailsSkeleton />
                <MatchHistorySkeleton />
              </>
            )}
          </div>
          <div
            className={`transition-opacity duration-1000 ${
              isLoaded ? 'opacity-100' : 'opacity-0'
            }`}
          >
            {isLoaded && summonerData && (
              <>
                <SummonerDetails
                  region={region}
                  championMasteries={summonerData?.profile?.championMastery}
                  tagLine={summonerData.tagLine}
                  matches={summonerData?.matches}
                  gameName={summonerData?.gameName}
                  summonerName={summonerData?.gameName + summonerData?.tagLine}
                  profileIcon={summonerData?.profile?.profileIcon}
                  rank={summonerData?.profile?.rank?.[0]?.rank}
                  rankInfo={summonerData?.profile?.rank}
                  isSummonerLive={summonerData?.isSummonerLive}
                  puuid={summonerData?.puuid}
                  summonerLevel={summonerData?.profile?.summonerLevel}
                />
                <MatchHistory
                  tagLine={summonerData?.tagLine}
                  matches={summonerData?.matches}
                  summonerName={summonerData?.gameName + summonerData?.tagLine}
                  gameName={summonerData?.gameName}
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Summoner;

