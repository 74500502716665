export interface Tag {
    tag: string;
    color: string;
  }

  // When we get prod key we can fill it out.
  
  export function generateTags(totalGames: number, wins: number, losses: number, winRate: number, leaguePoints: number): Tag[] {
    const tags: Tag[] = [];

    if (winRate >= 50) {
      tags.push({ tag: 'High Win Rate', color: 'green' });
    } else {
      tags.push({ tag: 'Low Win Rate', color: 'red' });
    }
  
    if (leaguePoints > 50) {
      tags.push({ tag: 'High League Points', color: 'green' });
    } else if (leaguePoints <= 10) {
      tags.push({ tag: 'Close to Demotion', color: 'red' });
    } else {
      tags.push({ tag: 'Low League Points', color: 'red' });
    }
  
    if (totalGames > 20) {
      tags.push({ tag: 'Active Player', color: 'green' });
    } else {
      tags.push({ tag: 'Infrequent Player', color: 'red' });
    }
  
    return tags;
  }