import { Link, useLocation } from 'react-router-dom';
import ChampionSearchBar from '../lol-landing-page/components/ChampionSearchBar';
import {
  HamburgerMenuSvg,
  XSvg,
} from '../lol-champion-page/components/build/recommendedItems/SvgArrow';
import { useState, useRef, useEffect } from 'react';

const Header: React.FC = () => {
  return (
    <header className="bg-primary w-full pl-2 pr-2 md:pr-5 flex items-center gap-[5%] md:gap-[5%] rounded-sm shadow-2xl max-h-14 mb-3">
      <div className="hidden lg:flex lg:gap-5 lg:ml-5">
        <HeaderLinks setIsOpen={null} />
      </div>
      <div className="lg:hidden">
        <HamburgerMenu />
      </div>
      {useLocation().pathname !== '/' && (
        <div className="w-full lg:w-[40%] lg:mr-[15%] flex items-center z-50">
          <ChampionSearchBar padding="0" />
        </div>
      )}
    </header>
  );
};

const HeaderLinks: React.FC<any> = () => {
  return (
    <>
      <Link to="/" className="text-lg font-bold">
        <div className="text-3xl md:text-4xl font-proxima-nova font-extrabold mb-5 shadow-2xl font-proxima mt-5 flex items-center">
          <img src="/logo/ez_logo.png" alt="EZ Logo" className="h-12 md:h-12" />
          <span className="text-stone-50 pt-3 font-bold font-sans md:text-2xl">.GG</span>
        </div>
      </Link>
    </>
  );
};

const HamburgerMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClickOutsideMenu = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenu);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, []);

  return (
    <div className="lg:hidden" ref={menuRef}>
      <div
        className={`h-screen w-[50%] fixed inset-0 bg-secondary bg-opacity-[.95] z-50 transition-opacity duration-300 ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      >
        {isOpen && (
          <div className="">
            <div className="flex w-full justify-end mt-2">
              <div onClick={() => setIsOpen(!isOpen)}>
                <XSvg />
              </div>
            </div>
            <div className="flex flex-col text-lg font-bold gap-5 items-start justify-center ml-2">
              <HeaderLinks setIsOpen={() => setIsOpen(false)} />
            </div>
          </div>
        )}
      </div>
      <button
        className="w-full transition duration-300"
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isOpen && <HamburgerMenuSvg />}
      </button>
    </div>
  );
};

export default Header;
