import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
  connectFirestoreEmulator,
} from 'firebase/firestore';
// firebaseConfig.js

import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Your Firebase configuration for production
const firebaseConfig = {
  apiKey: 'AIzaSyBVyY0bfc40ahs_zAj1VVNmWc6NxGDHuFU',
  authDomain: 'league-bm.firebaseapp.com',
  projectId: 'league-bm',
  storageBucket: 'league-bm.appspot.com',
  messagingSenderId: '422108285165',
  appId: '1:422108285165:web:9bb7e680ed9b4aad78a4e4',
  measurementId: 'G-47NN3MBXC7',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Enable App Check debug mode
(<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN =
  '9A4D1A6E-2EC9-47C3-A0B9-F3492D645699';

// Initialize Firebase App Check with reCAPTCHA v3
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdE_kMqAAAAAJXtPOKO9MtKv-HC6Dbhs0mMkkTL'),
  isTokenAutoRefreshEnabled: true,
});

// Initialize Firebase Functions
const functions = getFunctions(app, 'europe-west1');

// Connect to Functions emulator in development
if (process.env.NODE_ENV === 'development') {
  // Connect the functions instance to the local emulator
  connectFunctionsEmulator(functions, 'localhost', 5001);
  console.log('Connected to Functions emulator at localhost:5001');

  connectFirestoreEmulator(db, 'localhost', 8080);
  console.log('Connected to Firestore emulator at localhost:8080');
} else {
  console.log('Connected to Firebase Functions in production');
}

export { db, functions }; // Export db and functions for use in your application

export interface StartingItems {
  pickRate: number;
  matchesPlayed: number;
  startingItems: number[];
  winRate: number;
}

export interface NormalItems {
  matchesPlayed: number;
  winRate: number;
  pickRate: number;
  item1: number;
  item2: number;
  item3: number;
}

export interface Runes {
  primaryStyle: number;
  primaryStylePerk1: number;
  primaryStylePerk2: number;
  primaryStylePerk3: number;
  primaryStylePerk4: number;
  subStyle: number;
  subStylePerk1: number;
  subStylePerk2: number;
  statMod1: number;
  statMod2: number;
  statMod3: number;
  matchesPlayed: number;
  styleWinRate: number;
  stylePickRate: number;
}

export interface Boots {
  winRate: number;
  matchesPlayed: number;
  boots: number;
  pickRate: number;
}

export interface Opponent {
  earlyGoldEarned: number;
  earlyMinionsKilled: number;
  earlySoloKillsAgainstLaneOpponent: number;
  kda: number;
  matchesPlayed: number;
  opponentChampionName: string;
  opponentEarlyGoldEarned: number;
  opponentEarlyMinionsKilled: number;
  opponentEarlySoloKillsAgainstLaneOpponent: number;
  opponentKda: number;
  winRate: number;
}

export interface SkillLevelUp {
  pickRate: number;
  winRate: number;
  skillLevelUpOrder: string;
  matchesPlayed: number;
}

export interface SummonerSpells {
  matchesPlayed: number;
  summonerSpell1: number;
  winRate: number;
  pickRate: number;
  summonerSpell2: number;
}

export interface ChampionStats {
  documentId: string;
  championName: string;
  teamPosition: string;
  teamPositionPickRate: number;
  teamPositionWinRate: number;
  recommendedSkillLevelUp: SkillLevelUp;
  recommendedStartingItems: StartingItems[];
  recommendedNormalItems: NormalItems[];
  recommendedRunes: Runes[];
  recommendedBoots: Boots[];
  goodAgainst: Opponent[];
  badAgainst: Opponent[];
  recommendedSummonerSpells: SummonerSpells[];
  allTeamPositionsPickRates: {
    [key: string]: number;
  };
  banRate: number;
  championSpecificLanePickRate: number;
}

const COLLECTION_ID = 'new_build_recommendations_test_8';

export async function getBuildDataByDocumentId(
  documentId: string
): Promise<ChampionStats | null> {
  const championsCollection = collection(db, COLLECTION_ID);

  try {
    const docRef = doc(championsCollection, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const championStats: ChampionStats = {
        ...(data as ChampionStats),
        documentId: docSnap.id,
      };
      return championStats;
    } else {
      console.log('No such champion!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching champion:', error);
    return null;
  }
}

export async function getChampion(
  championName: string
): Promise<ChampionStats | null> {
  const championsCollection = collection(db, COLLECTION_ID);

  const q = query(
    championsCollection,
    where('championName', '==', championName),
    orderBy('teamPositionPickRate', 'desc'),
    limit(1)
  );

  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const data = doc.data();
      const championStats: ChampionStats = {
        ...(data as ChampionStats),
        documentId: doc.id,
      };
      return championStats;
    } else {
      console.log('No such champion!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching champion:', error);
    return null;
  }
}

export interface SummonerProfile {
  lastUpdated: number;
  profileIcon: number;
  riotIdGameName: string;
  riotIdTagline: string;
  regionalServer: string;
}

export async function getSummonerProfiles(
  profileName: string
): Promise<SummonerProfile[] | null> {
  console.log('profileName:', profileName);
  if (profileName.length < 3 || profileName.length > 20) {
    return null;
  }

  const profilesRef = collection(db, 'lol_summoner_profile');

  try {
    const lowerCasePrefix = profileName.toLowerCase();
    const startAt = lowerCasePrefix;
    const endAt = lowerCasePrefix + '\uf8ff';

    const q = query(
      profilesRef,
      where('profileName', '>=', startAt),
      where('profileName', '<=', endAt),
      limit(4)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    }

    const profiles: SummonerProfile[] = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return data as SummonerProfile;
    });

    console.log('Fetched profiles:', profiles);

    return profiles;
  } catch (error) {
    console.error('Error fetching profiles:', error);
    return null;
  }
}

export default getChampion;
