import React from "react";
import { Participant } from "./MatchHistory";
import CustomTooltip from "../../../lol-champion-page/components/build/CustomToolTip";
import { getRightPerkClass } from "../../../Util";

interface MatchHistoryItemDropdownOverviewProps {
  participants: Participant[];
  itemData: Record<string, any>;
}

interface ParticipantRowProps {
  participant: Participant;
  itemData: Record<string, any>;
  index: number;
  backgroundColor: string;
}

const formatNumber = (num: number) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  return num.toString();
};

const ParticipantRow: React.FC<ParticipantRowProps> = ({ participant, itemData, index, backgroundColor }) => {
  return (
    
    <div key={index} className={`flex flex-col border-t border-primary ${backgroundColor}`}>
      <div className={`flex items-center border-r border-primary ${backgroundColor}`}>
        <span
          className="col-span-4 text-sm font-bold text-stone-50 md:text-xs lg:text-sm"
          style={{ fontFamily: "BeaufortForLoL, serif" }} // Apply the Beaufort font directly here
        >
          {participant.riotIdGameName + " #" + participant.riotIdTagline}
        </span>
      </div>
      <div className="flex items-center bg-opacity-[18%]">
        <div className="flex flex-col gap-1 self-start bg-secondary pr-1">
          <div className="flex flex-row">
            <img
              src={`/champimages/${participant.championName}.png`}
              alt={`Champion ${index + 1}`}
              className="h-10 w-10 md:h-8 md:w-8 lg:h-12 lg:w-12"
            />
            <div className="flex flex-col md:gap-1 self-center">
              <img
                src={`/${getRightPerkClass(participant.perks.styles[0].style)?.icon}`}
                alt={`Spell ${index + 1}`}
                className="h-4 w-5 md:h-2 md:w-2 lg:h-4 lg:w-6"
              />
              <img
                src={`/${getRightPerkClass(participant.perks.styles[1].style)?.icon}`}
                alt={`Spell ${index + 1}`}
                className="h-4 w-5 md:h-1 md:w-2 lg:h-4 lg:w-6"
              />
            </div>
          </div>
          <div className="flex flex-row gap-1">
            <img
              src={`/spell/${participant.summoner1Id}.png`}
              alt={`Spell ${index + 1}`}
              className="h-4 w-4 md:h-4 md:w-4 lg:h-5 lg:w-5"
            />
            <img
              src={`/spell/${participant.summoner2Id}.png`}
              alt={`Spell ${index + 1}`}
              className="h-4 w-4 md:h-5 md:w-4 lg:h-5 lg:w-5"
            />
            <div className="h-3 w-3 text-left text-xxs text-white md:h-4 md:w-4 md:text-xs lg:h-5 lg:w-5 lg:text-sm">
              {participant.champLevel}
            </div>
          </div>
        </div>

        <div className="grid w-full grid-cols-4 items-center justify-items-center rounded text-xxs text-white md:text-xs lg:text-sm">
          <span className="text-stone-50 text-sm font-bold">
            {`${participant.kills}/${participant.deaths}/${participant.assists}`}
          </span>
          <span className="text-stone-50 text-sm font-bold">
            {participant.totalMinionsKilled + participant.neutralMinionsKilled}
          </span>
          <span className="text-stone-50 text-sm font-bold">
            {formatNumber(participant.totalDamageDealtToChampions)}
          </span>
          <span className="grid grid-cols-3 gap-1 p-1 justify-self-center md:p-1 text-stone-50">
            {[
              participant.item0,
              participant.item1,
              participant.item2,
              participant.item3,
              participant.item4,
              participant.item5,
            ].map((item, itemIndex) => {
              const itemInfo = itemData[`item_${item}`] || {};
              if (!item || !itemInfo.name) return null;

              return (
                <CustomTooltip
                  key={index + itemIndex}
                  position="top"
                  header={itemInfo.name || ""}
                  cost={`${itemInfo.gold?.total || ""} (${itemInfo.gold?.base || ""})`}
                  content={itemInfo.description || ""}
                >
                  <img
                    src={`/item/${item}.png`}
                    alt="item"
                    className="h-7 w-7 rounded rounded-sm border border-[0.01rem] border-yellow-800 md:h-4 md:w-4 lg:h-7 lg:w-7"
                    
                  />
                </CustomTooltip>
              );
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

const MatchHistoryItemDropdownOverview: React.FC<MatchHistoryItemDropdownOverviewProps> = ({
  participants,
  itemData,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-col md:grid md:grid-cols-2 gap-1 md:gap-4 lg:gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex border-b border-primary">
            <span className="w-6 md:w-8 lg:w-10"></span>
            <span className="flex-1 border-r border-primary text-center ml-6 text-xs font-bold text-white md:text-xs lg:text-sm">
              KDA
            </span>
            <span className="flex-1 border-r border-primary text-center text-xs font-bold text-white md:text-xs lg:text-sm">
              CS
            </span>
            <span className="flex-1 border-r border-primary text-center text-xs font-bold text-white md:text-xs lg:text-sm">
              Damage
            </span>
            <span className="flex-1 border-r border-primary text-center text-xs font-bold text-white md:text-xs lg:text-sm">
              Items
            </span>
          </div>
          {participants.slice(0, 5).map((participant, index) => (
            <ParticipantRow
              key={index}
              participant={participant}
              itemData={itemData}
              index={index}
              backgroundColor="bg-red-400 bg-opacity-[18%]"
            />
          ))}
        </div>

        <div className="flex flex-col gap-2">
          <div className="hidden md:flex border-b border-primary">
            <span className="w-6 md:w-8 lg:w-10"></span>
            <span className="flex-1 border-r border-primary text-center ml-6 text-xxs font-bold text-white md:text-xs lg:text-sm">
              KDA
            </span>
            <span className="flex-1 border-r border-primary text-center text-xxs font-bold text-white md:text-xs lg:text-sm">
              CS
            </span>
            <span className="flex-1 border-r border-primary text-center text-xxs font-bold text-white md:text-xs lg:text-sm">
              Damage
            </span>
            <span className="flex-1 border-r border-primary text-center text-xxs font-bold text-white md:text-xs lg:text-sm">
              Items
            </span>
          </div>
          {participants.slice(5).map((participant, index) => (
            <ParticipantRow
              key={index + 5}
              participant={participant}
              itemData={itemData}
              index={index}
              backgroundColor="bg-blue-400 bg-opacity-[18%]"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MatchHistoryItemDropdownOverview;
