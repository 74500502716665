import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './lol-landing-page/LandingPage';
import ChampionPage from './lol-champion-page/ChampionPage';
import Summoner from './lol-summoner-page/SummonerPage';
import Header from './components/header';
import Footer from './components/footer';
import './styles/tailwind.css';
import LiveDataPage from './lol-live-data/LiveDataPage';
import AboutUs from './static-pages/about-us';
import ContactUs from './static-pages/contact-us';
import PrivacyPolicy from './static-pages/privacy';
import Advertisment from './static-pages/advertisment';
import Terms from './static-pages/terms';
import LandingPageLiveData from './lol-live-data/LandingPageLiveData';



function App() {
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.error = () => {};
    console.warn = () => {};
  }

  return (
    <div className="App bg-primary flex flex-col min-h-screen text-white overflow-x-hidden">
      <Router>
        <Header />
        <div className="flex-grow mb-8">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/champion/:id" element={<ChampionPage />} />
            <Route path="/summoner/:region/:summonername/:tagline" element={<Summoner />} />
            <Route path="/live-data/:region/:summonername/:tagline" element={<LiveDataPage/>}/>
            <Route path="/about-us" element={<AboutUs/>}/>
            <Route path="/contact-us" element={<ContactUs/>}/>
            <Route path="/terms-of-use" element={<Terms/>}/>
            <Route path="/privacy" element={<PrivacyPolicy/>}/>
            <Route path="/advertisement" element={<Advertisment/>}/>
            <Route path="/live" element={<LandingPageLiveData/>}/>
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
