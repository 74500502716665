interface RoleData {
  playRate: number;
}

interface ChampionRoles {
  TOP: RoleData;
  JUNGLE: RoleData;
  MIDDLE: RoleData;
  BOTTOM: RoleData;
  UTILITY: RoleData;
}

interface ChampionData {
  [id: string]: ChampionRoles;
}

const permute = (arr: string[]): string[][] => {
  const result: string[][] = [];

  const generate = (current: string[], remaining: string[]) => {
    if (remaining.length === 0) {
      result.push(current);
    } else {
      for (let i = 0; i < remaining.length; i++) {
        generate(
          current.concat(remaining[i]),
          remaining.slice(0, i).concat(remaining.slice(i + 1))
        );
      }
    }
  };

  generate([], arr);
  return result;
};

export const predictRoles = (champIds: string[]) => {
  const roles = ['TOP', 'JUNGLE', 'MIDDLE', 'BOTTOM', 'UTILITY'] as any;
  const rolePermutations = permute(roles);

  let bestTotalPlayRate = -Infinity;
  let bestAssignment: { [champId: string]: string } | null = null;

  for (const rolePermutation of rolePermutations) {
    let totalPlayRate = 0;
    const assignment: { [champId: string]: string } = {};

    for (let i = 0; i < champIds.length; i++) {
      const champId = champIds[i];
      const role = rolePermutation[i] as keyof ChampionRoles;
      const champRoles = championData[champId];

      if (!champRoles) {
        console.warn(`Champion data not found for champId: ${champId}`);
        totalPlayRate = -Infinity;
        break;
      }

      const playRate = champRoles[role].playRate;

      if (playRate === 0) {
        // Assign a large negative penalty
        totalPlayRate -= 1000;
      } else {
        totalPlayRate += playRate;
      }

      assignment[champId] = role;
    }

    if (totalPlayRate > bestTotalPlayRate) {
      bestTotalPlayRate = totalPlayRate;
      bestAssignment = assignment;
    }
  }

  if (bestAssignment) {
    return bestAssignment;
  } else {
    console.warn(`No valid assignment found.`);
    return {};
  }
};
  

const championData: ChampionData = {
  "1": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0.718,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "2": {
    TOP: {
      playRate: 0.647,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "3": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.54,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "4": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.245,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "5": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.527,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "6": {
    TOP: {
      playRate: 1.124,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "7": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 3.968,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "8": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.624,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "9": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.437,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "10": {
    TOP: {
      playRate: 1.258,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "11": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 2.424,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "12": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 2.278,
    },
  },
  "13": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0.742,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "14": {
    TOP: {
      playRate: 1.266,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "15": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 1.386,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "16": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 2.062,
    },
  },
  "17": {
    TOP: {
      playRate: 1.55,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "18": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0.729,
    },
    BOTTOM: {
      playRate: 0.857,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "19": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.757,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "20": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.616,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "21": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 6.462,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "22": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 5.201,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "23": {
    TOP: {
      playRate: 1.462,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "24": {
    TOP: {
      playRate: 2.08,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "25": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 2.265,
    },
  },
  "26": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 1.075,
    },
  },
  "27": {
    TOP: {
      playRate: 0.699,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "28": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.303,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "29": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 1.984,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "30": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.169,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "31": {
    TOP: {
      playRate: 1.293,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "32": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 3.397,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "33": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0.97,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "34": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0.941,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "35": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 2.798,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "36": {
    TOP: {
      playRate: 2.297,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "37": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 1.059,
    },
  },
  "38": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0.891,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "39": {
    TOP: {
      playRate: 1.739,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.592,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "40": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 1.735,
    },
  },
  "41": {
    TOP: {
      playRate: 1.472,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "42": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.555,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "43": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 3.594,
    },
  },
  "44": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0.658,
    },
  },
  "45": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.937,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "48": {
    TOP: {
      playRate: 0.809,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "50": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 1.08,
    },
  },
  "51": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 6.932,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "53": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 3.068,
    },
  },
  "54": {
    TOP: {
      playRate: 2.081,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "55": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 3.654,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "56": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 2.786,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "57": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0.849,
    },
  },
  "58": {
    TOP: {
      playRate: 2.939,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "59": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 2.312,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "60": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.039,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "61": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 2.119,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "62": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.067,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "63": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.962,
    },
    MIDDLE: {
      playRate: 1.021,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 1.545,
    },
  },
  "64": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 8.893,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "67": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 2.246,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "68": {
    TOP: {
      playRate: 1.097,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "69": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0.76,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "72": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0.636,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "74": {
    TOP: {
      playRate: 0.525,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "75": {
    TOP: {
      playRate: 3.257,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "76": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 2.645,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "77": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.922,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "78": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 1.367,
    },
  },
  "79": {
    TOP: {
      playRate: 1.297,
    },
    JUNGLE: {
      playRate: 1.377,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "80": {
    TOP: {
      playRate: 1.184,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 1.077,
    },
  },
  "81": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 10.965,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "82": {
    TOP: {
      playRate: 3.217,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "83": {
    TOP: {
      playRate: 1.75,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "84": {
    TOP: {
      playRate: 1.025,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 2.408,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "85": {
    TOP: {
      playRate: 1.512,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "86": {
    TOP: {
      playRate: 3.772,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "89": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 5.125,
    },
  },
  "90": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.969,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "91": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.348,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "92": {
    TOP: {
      playRate: 2.116,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "96": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 1.4,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "98": {
    TOP: {
      playRate: 1.194,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "99": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 2.553,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 4.27,
    },
  },
  "101": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.621,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 2.243,
    },
  },
  "102": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 3.406,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "103": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 3.384,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "104": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 2.576,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "105": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.669,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "106": {
    TOP: {
      playRate: 2.395,
    },
    JUNGLE: {
      playRate: 1.503,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "107": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.447,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "110": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 1.717,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "111": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 4.746,
    },
  },
  "112": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.44,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "113": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.84,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "114": {
    TOP: {
      playRate: 1.973,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "115": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0.8,
    },
    BOTTOM: {
      playRate: 0.787,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "117": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 3.969,
    },
  },
  "119": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 2.863,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "120": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 2.385,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "121": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 4.531,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "122": {
    TOP: {
      playRate: 3.749,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "126": {
    TOP: {
      playRate: 2.538,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "127": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.585,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "131": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 2.605,
    },
    MIDDLE: {
      playRate: 1.25,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "133": {
    TOP: {
      playRate: 0.846,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "134": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 2.572,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "136": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.441,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "141": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 3.617,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "142": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.11,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "143": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 1.722,
    },
  },
  "145": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 10.467,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "147": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 4.047,
    },
  },
  "150": {
    TOP: {
      playRate: 1.702,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "154": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.693,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "157": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 5.554,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "161": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 1.315,
    },
  },
  "163": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0.747,
    },
    MIDDLE: {
      playRate: 0.711,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "164": {
    TOP: {
      playRate: 2.532,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "166": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0.824,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "200": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.123,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "201": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 2.84,
    },
  },
  "202": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 12.446,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "203": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.044,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "221": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 3.393,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "222": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 5.12,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "223": {
    TOP: {
      playRate: 1.261,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "233": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 2.585,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "234": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 5.193,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "235": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 6.891,
    },
  },
  "236": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.258,
    },
    BOTTOM: {
      playRate: 4.401,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "238": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 4.604,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "240": {
    TOP: {
      playRate: 0.816,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "245": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.959,
    },
    MIDDLE: {
      playRate: 1.06,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "246": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.134,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "254": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 2.706,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "266": {
    TOP: {
      playRate: 6.44,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "267": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 4.222,
    },
  },
  "268": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.549,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "350": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 3.124,
    },
  },
  "360": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 2.604,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "412": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 6.436,
    },
  },
  "420": {
    TOP: {
      playRate: 2.049,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "421": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0.739,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "427": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0.722,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "429": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0.899,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "432": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 1.909,
    },
  },
  "497": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 2.101,
    },
  },
  "498": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 2.061,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "516": {
    TOP: {
      playRate: 1.903,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "517": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 1.045,
    },
    MIDDLE: {
      playRate: 3.209,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "518": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0.851,
    },
  },
  "523": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 2.088,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "526": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 2.073,
    },
  },
  "555": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 3.745,
    },
  },
  "711": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.862,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "777": {
    TOP: {
      playRate: 2.626,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 4.552,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "875": {
    TOP: {
      playRate: 3.52,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "876": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 4.71,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "887": {
    TOP: {
      playRate: 1.651,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "888": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0.663,
    },
  },
  "893": {
    TOP: {
      playRate: 1.886,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 3.166,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "895": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0.84,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "897": {
    TOP: {
      playRate: 1.636,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "901": {
    TOP: {
      playRate: 1.288,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.595,
    },
    BOTTOM: {
      playRate: 6.853,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "902": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 2.878,
    },
  },
  "910": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 3.01,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "950": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 1.327,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "3147": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "3151": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "3152": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "3153": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "3156": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "3157": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "3159": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "3678": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
  "3947": {
    TOP: {
      playRate: 0,
    },
    JUNGLE: {
      playRate: 0,
    },
    MIDDLE: {
      playRate: 0,
    },
    BOTTOM: {
      playRate: 0,
    },
    UTILITY: {
      playRate: 0,
    },
  },
};
