import React from 'react';

const Advertisment: React.FC = () => {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Contact Us for advertisment</h1>
      <p className="text-gray-200 mb-8 text-center">
        We’d love to hear from you! Fill out the form below or contact us directly at{' '}
        <a href="mailto:contact@ez.gg" className="text-blue-600 hover:text-blue-800">support@ez.com</a>.
      </p>

      <form className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-200">
            Name/Company
          </label>
          <input
            type="text"
            id="name"
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            placeholder="Your name"
            required
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-200">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-200"
            placeholder="you@example.com"
            required
          />
        </div>

        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-200">
            Message
          </label>
          <textarea
            id="message"
            rows={4}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            placeholder="Your message"
            required
          />
        </div>

        <div className="text-center">
          <button
            type="submit"
            className="inline-block px-6 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Send Message
          </button>
        </div>
      </form>

      <div className="mt-12 text-center text-gray-200">
        <p className="mb-1">You can also try reach us out by social media</p>
        <p>
          <a href="" className="text-blue-600 hover:text-blue-800"></a>
        </p>
      </div>
    </div>
  );
}

export default Advertisment;