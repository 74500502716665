import React, { useState, useRef, useEffect } from 'react';
import MatchHistoryItem from './MatchHistoryItem';
import MatchHistoryDropdown from './MatchHistoryDropdown';
import debounce from 'lodash/debounce'; // Correctly import debounce from lodash
import AlertComponent from './MatchNotFoundError';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase/championDAO';

export interface Matches {
  matches?: MatchData[];
  summonerName?: string;
  tagLine?: string;
  gameName: string;
}

export interface MatchData {
  matchId: string;
  gameDuration: number;
  gameEndTimestamp: number;
  gameMode: string;
  gameStartTimestamp: number;
  queueId: string;
  gameVersion: string;
  puuids: string[];
  participants: Participant[];
}

export interface Participant {
  assists: number;
  baronKills: number;
  doubleKills: number;
  tripleKills: number;
  quadraKills: number;
  pentaKills: number;
  dragonKills: number;
  neutralMinionsKilled: number;
  firstBloodKill: boolean;
  inhibitorKills: number;
  inhibitorTakedowns: number;
  longestTimeSpentLiving: number;
  firstTowerKill: boolean;
  largestMultiKill: number;
  damageDealtToBuildings: number;
  damageDealtToObjectives: number;
  damageDealtToTurrets: number;
  damagePerMinute: number;
  goldPerMinute: number;
  kda: number;
  soloKills: number;
  champExperience: number;
  champLevel: number;
  championId: number;
  championName: string;
  deaths: number;
  profileIcon: number;
  goldEarned: number;
  goldSpent: number;
  individualPosition: string;
  item0: number;
  item1: number;
  item2: number;
  item3: number;
  item4: number;
  item5: number;
  item6: number;
  itemsPurchased: number;
  kills: number;
  lane: string;
  participantId: number;
  perks: {
    statPerks: {
      defense: number;
      flex: number;
      offense: number;
    };
    styles: Style[];
  };
  puuid: string;
  summonerId: string;
  summonerName: string;
  riotIdGameName: string;
  riotIdTagline: string;
  teamId: number;
  teamPosition: string;
  summoner2Id: number;
  summoner1Id: number;
  role: string;
  totalTimeSpentDead: number;
  totalDamageTaken: number;
  totalMinionsKilled: number;
  turretKills: number;
  turretTakedowns: number;
  totalHealsOnTeammates: number;
  totalEnemyJungleMinionsKilled: number;
  totalDamageDealtToChampions: number;
  totalDamageShieldedOnTeammates: number;
  timeCCingOthers: number;
  wardsKilled: number;
  wardsPlaced: number;
  visionScore: number;
  win: boolean;
}

interface Style {
  selections: Selection[];
  style: number;
}

interface Selection {
  perk: number;
  var1: number;
  var2: number;
  var3: number;
}

type QueueId = '420' | '440' | '450' | '400' | '720' | '700';

const queueIdMap: { [key in QueueId]: string } = {
  '420': 'Ranked Solo 5v5',
  '440': 'Ranked Flex 5v5',
  '450': 'ARAM',
  '400': 'Normal 5v5 Draft',
  '720': 'ARAM',
  '700': 'Clash',
};

const MATCHES_TO_GET = 10;

const MatchHistory: React.FC<Matches> = ({
  matches: initialMatches,
  summonerName,
  tagLine,
  gameName,
}) => {
  const [visibleMatches, setVisibleMatches] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQueueId, setSelectedQueueId] = useState<QueueId | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [matches, setMatches] = useState<MatchData[]>(initialMatches || []);
  const [filteredMatches, setFilteredMatches] = useState<MatchData[]>([]);
  const loaderRef = useRef<HTMLDivElement>(null);
  const [hasServerError, setHasServerError] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          !isLoading &&
          !hasServerError &&
          filteredMatches.length !== 0 &&
          hasMoreData
        ) {
          setVisibleMatches((prev) => {
            const newVisibleMatches = prev + MATCHES_TO_GET;
            if (newVisibleMatches >= matches.length) {
              loadMoreMatchesDebounced(matches.length);
            }
            return newVisibleMatches;
          });
        }
      },
      {
        root: null,
        rootMargin: '20px',
        threshold: 0.5,
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [
    loaderRef,
    matches.length,
    isLoading,
    hasServerError,
    hasMoreData,
    filteredMatches.length,
  ]);

  const loadMoreMatchesDebounced = useRef(
    debounce(async (currentLength: number) => {
      await loadMoreMatches(currentLength);
    }, 300)
  ).current;

  // Replace this section in your current code
  const loadMoreMatches = async (currentLength: number) => {
    if (isLoading || hasServerError || !hasMoreData) return;
    setIsLoading(true);

    if (currentLength >= 100 || matches.length >= 100) {
      setHasMoreData(false);
      setIsLoading(false);
      return;
    }

    try {
      // Use httpsCallable to call the Firebase function
      const getMatchesByGameNameAndTagLine = httpsCallable(
        functions,
        'getMatchesByGameNameAndTagLine'
      );

      // Call the function with the correct parameters
      const response = await getMatchesByGameNameAndTagLine({
        regionalServer: 'euw1',
        gameName: gameName,
        tagLine: tagLine,
        start: currentLength,
        count: MATCHES_TO_GET,
      });

      // Properly type the data response
      const data = response.data as { matches: MatchData[] }; // Explicitly type response data

      if (
        !data ||
        !data.matches ||
        data.matches.length === 0 ||
        matches.length + data.matches.length >= 100
      ) {
        setHasMoreData(false);
      } else {
        setMatches((prevMatches) => [...prevMatches, ...data.matches]);
      }
    } catch (error) {
      console.error('Error fetching additional matches: ', error);
      setHasServerError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const allParticipants = Array.from(
    new Set(
      matches?.flatMap((match) =>
        match.participants.map((participant) => participant.riotIdGameName)
      ) || []
    )
  );
  const allChampions = Array.from(
    new Set(
      matches?.flatMap((match) =>
        match.participants.map((participant) => participant.championName)
      ) || []
    )
  );

  useEffect(() => {
    setFilteredMatches(
      matches?.filter((match) => {
        const validParticipants = match.participants.some(
          (participant) =>
            participant.riotIdTagline != null &&
            participant.riotIdGameName != null
        );

        const queueMatch = selectedQueueId
          ? match.queueId === queueIdMap[selectedQueueId]
          : true;

        const searchMatch = searchValue
          ? match.participants.some(
              (participant) =>
                participant.riotIdGameName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                participant.championName
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
            )
          : true;

        return validParticipants && queueMatch && searchMatch;
      })
    );
  }, [matches, selectedQueueId, searchValue]);

  return (
    <div className="flex flex-col items-center space-y-2">
      <div className="w-full md:w-full">
        <MatchHistoryDropdown
          setSelectedQueueId={setSelectedQueueId}
          setSearchValue={setSearchValue}
          participants={allParticipants}
          champions={allChampions}
        />
      </div>

      {filteredMatches.length === 0 ? (
        <AlertComponent errorMessage="There are no recent match records." />
      ) : (
        filteredMatches?.slice(0, visibleMatches).map((match, index) => (
          <div key={index} className="w-full md:w-full">
            <MatchHistoryItem
              matchIndex={index}
              matches={filteredMatches}
              summonerName={summonerName}
            />
          </div>
        ))
      )}

      {hasMoreData && (
        <div ref={loaderRef} className="flex justify-center items-center mt-4">
          {isLoading && (
            <span className="loading loading-bars loading-lg"></span>
          )}
        </div>
      )}
    </div>
  );
};

export default MatchHistory;
