import React, { useState, useEffect } from 'react';

interface ElapsedTimeCounterProps {
  gameStartTime: number; // timestamp in milliseconds
}

const ElapsedTimeCounter: React.FC<ElapsedTimeCounterProps> = ({ gameStartTime }) => {
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    const updateElapsedTime = () => {
      const now = Date.now();
      const elapsed = Math.floor((now - gameStartTime) / 1000);
      setSeconds(elapsed);
    };

    updateElapsedTime();

    const timer = setInterval(updateElapsedTime, 1000);

    return () => clearInterval(timer);
  }, [gameStartTime]);

  const minutes = Math.floor(seconds / 60);
  const displaySeconds = (seconds % 60).toString().padStart(2, "0");

  return (
    <div className="grid grid-flow-col gap-1 text-right">
      <div className="flex flex-col">
        <span className="countdown font-mono text-sm md:text-xl">
          <span
            style={{ "--value": minutes } as React.CSSProperties}
          ></span>
        </span>
        <span className="text-xs md:text-sm">min</span>
      </div>
      <div className="flex flex-col">
        <span className="countdown font-mono text-sm md:text-xl">
          <span
            style={
              {
                "--value": parseInt(displaySeconds),
              } as React.CSSProperties
            }
          ></span>
        </span>
        <span className="text-xs md:text-sm">sec</span>
      </div>
    </div>
  );
};

export default ElapsedTimeCounter;
