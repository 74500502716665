export default {
    "id": 8400,
    "icon": "perk-images/Styles/7204_Resolve.png",
    "name": "Odhodl\u00e1n\u00ed",
    "slots": [
        {
            "runes": [
                {
                    "id": 8437,
                    "key": "GraspOfTheUndying",
                    "icon": "perk-images/Styles/Resolve/GraspOfTheUndying/GraspOfTheUndying.png",
                    "name": "Sp\u00e1ry nehynouc\u00edch",
                    "shortDesc": "V\u017edy po uplynut\u00ed 4 sek. zp\u016fsob\u00ed tv\u016fj n\u00e1sleduj\u00edc\u00ed \u00fatok v\u016f\u010di \u0161ampionovi bonusov\u00e9 magick\u00e9 po\u0161kozen\u00ed, nav\u00edc t\u011b vyl\u00e9\u010d\u00ed a permanentn\u011b ti zv\u00fd\u0161\u00ed zdrav\u00ed.",
                    "longDesc": "Kdy\u017e bojuje\u0161, v\u017edy po uplynut\u00ed 4&nbsp;sek. m\u00e1 tv\u016fj p\u0159\u00ed\u0161t\u00ed z\u00e1kladn\u00ed \u00fatok veden\u00fd proti \u0161ampionovi tyto efekty:<li>Zp\u016fsob\u00ed bonusov\u00e9 magick\u00e9 po\u0161kozen\u00ed ve v\u00fd\u0161i 3.5&nbsp;% tv\u00e9ho maxim\u00e1ln\u00edho zdrav\u00ed</li><li>Vyl\u00e9\u010d\u00ed ti 3 + 1.2&nbsp;% tv\u00e9ho maxim\u00e1ln\u00edho zdrav\u00ed</li><li>Trvale ti zv\u00fd\u0161\u00ed zdrav\u00ed o 7</li><br><rules><i>St\u0159ele\u010dt\u00ed \u0161ampioni:</i> \u00da\u010dinky po\u0161kozen\u00ed, l\u00e9\u010den\u00ed a trval\u00e9ho zvy\u0161ov\u00e1n\u00ed zdrav\u00ed jsou o 40&nbsp;% slab\u0161\u00ed.</rules><br>"
                },
                {
                    "id": 8439,
                    "key": "Aftershock",
                    "icon": "perk-images/Styles/Resolve/VeteranAftershock/VeteranAftershock.png",
                    "name": "N\u00e1sledn\u00fd ot\u0159es",
                    "shortDesc": "Kdy\u017e <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Immobilize'>znehybn\u00ed\u0161</lol-uikit-tooltipped-keyword> nep\u0159\u00e1telsk\u00e9ho \u0161ampiona, z\u00edsk\u00e1\u0161 obranu a pozd\u011bji kolem sebe vyvol\u00e1\u0161 v\u00fdboj magick\u00e9ho po\u0161kozen\u00ed.",
                    "longDesc": "Pot\u00e9, co znehybn\u00ed\u0161 nep\u0159\u00e1telsk\u00e9ho \u0161ampiona, se ti na 2.5&nbsp;sek. zv\u00fd\u0161\u00ed hodnota brn\u011bn\u00ed a odolnosti v\u016f\u010di magii o 35 bod\u016f + 80&nbsp;% tv\u00fdch bonusov\u00fdch odolnost\u00ed. Pak dojde k explozi, kter\u00e1 zp\u016fsob\u00ed magick\u00e9 po\u0161kozen\u00ed okoln\u00edm nep\u0159\u00e1tel\u016fm.<br><br>Po\u0161kozen\u00ed: 25\u2013120 bod\u016f (+8&nbsp;% tv\u00e9ho bonusov\u00e9ho zdrav\u00ed)<br>P\u0159eb\u00edjec\u00ed doba: 20&nbsp;sek.<br><br>Maxim\u00e1ln\u00ed bonus N\u00e1sledn\u00e9ho ot\u0159esu k odolnostem: 80\u2013150 (v z\u00e1vislosti na \u00farovni)<br>"
                },
                {
                    "id": 8465,
                    "key": "Guardian",
                    "icon": "perk-images/Styles/Resolve/Guardian/Guardian.png",
                    "name": "Str\u00e1\u017ece",
                    "shortDesc": "St\u0159e\u017e spojence, na kter\u00e9 se\u0161le\u0161 n\u011bjak\u00e9 kouzlo nebo kte\u0159\u00ed se nach\u00e1zej\u00ed v tvoj\u00ed t\u011bsn\u00e9 bl\u00edzkosti. Pokud byste ty nebo st\u0159e\u017een\u00fd spojenec m\u011bli v z\u00e1vislosti na \u00farovni utr\u017eit po\u0161kozen\u00ed, oba z\u00edsk\u00e1te \u0161t\u00edt.",
                    "longDesc": "<i>St\u0159e\u017e\u00ed\u0161</i> spojence v okruhu 350 jednotek kolem sebe a tak\u00e9 po dobu 2.5&nbsp;sek. spojence, kter\u00e9 zam\u011b\u0159\u00ed\u0161 sv\u00fdmi kouzly. Kdy\u017e <i>st\u0159e\u017e\u00ed\u0161</i> n\u011bjak\u00e9ho spojence a jeden z v\u00e1s utr\u017e\u00ed po dobu <i>st\u0159e\u017een\u00ed</i> v\u00edce ne\u017e jen mal\u00e9 mno\u017estv\u00ed po\u0161kozen\u00ed, z\u00edsk\u00e1te oba dva na 1.5&nbsp;sek. \u0161t\u00edt.<br><br>P\u0159eb\u00edjec\u00ed doba: <scaleLevel>90\u201340</scaleLevel>&nbsp;sek.<br>\u0160t\u00edt: <scaleLevel>45\u2013120</scaleLevel> bod\u016f + <scaleAP>12.5&nbsp;%</scaleAP> tvoj\u00ed s\u00edly schopnost\u00ed + <scalehealth>8&nbsp;%</scalehealth> tv\u00e9ho bonusov\u00e9ho zdrav\u00ed<br>Hrani\u010dn\u00ed hodnota pro aktivaci: <scaleLevel>90\u2013250</scaleLevel> bod\u016f po\u0161kozen\u00ed po zapo\u010d\u00edt\u00e1n\u00ed odolnost\u00ed"
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8446,
                    "key": "Demolish",
                    "icon": "perk-images/Styles/Resolve/Demolish/Demolish.png",
                    "name": "Zni\u010dit",
                    "shortDesc": "Kdy\u017e se nach\u00e1z\u00ed\u0161 pobl\u00ed\u017e v\u011b\u017ee, nabije\u0161 si proti n\u00ed mocn\u00fd \u00fatok.",
                    "longDesc": "Kdy\u017e se nach\u00e1z\u00ed\u0161 v okruhu 600 jednotek od v\u011b\u017ee, nabije\u0161 si b\u011bhem 3 sek. mocn\u00fd \u00fatok, kter\u00fd proti n\u00ed bude\u0161 moct pou\u017e\u00edt. Nabit\u00fd \u00fatok zp\u016fsobuje bonusov\u00e9 fyzick\u00e9 po\u0161kozen\u00ed ve v\u00fd\u0161i 100 bod\u016f (+35 % tv\u00e9ho maxim\u00e1ln\u00edho zdrav\u00ed). <br><br>P\u0159eb\u00edjec\u00ed doba: 45 sek."
                },
                {
                    "id": 8463,
                    "key": "FontOfLife",
                    "icon": "perk-images/Styles/Resolve/FontOfLife/FontOfLife.png",
                    "name": "Pramen \u017eivota",
                    "shortDesc": "Kdy\u017e n\u011bjak\u00e9mu nep\u0159\u00e1telsk\u00e9mu \u0161ampionovi <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_ImpairMov'>omez\u00ed\u0161</lol-uikit-tooltipped-keyword> pohyblivost, vyl\u00e9\u010d\u00ed\u0161 okoln\u00ed spojeneck\u00e9 \u0161ampiony. ",
                    "longDesc": "Omezen\u00edm pohybu nep\u0159\u00e1telsk\u00e9ho \u0161ampiona obnov\u00ed\u0161 <healing>@BaseHeal@ zdrav\u00ed</healing> sob\u011b a nedalek\u00e9mu spojeneck\u00e9mu \u0161ampionovi s nejni\u017e\u0161\u00edm zdrav\u00edm.<br><br>U u\u017eivatel\u016f bojuj\u00edc\u00edch na d\u00e1lku m\u00e1 tento efekt 70% \u00fa\u010dinnost.<br><br>P\u0159eb\u00edjec\u00ed doba: 20&nbsp;sek."
                },
                {
                    "id": 8401,
                    "key": "ShieldBash",
                    "icon": "perk-images/Styles/Resolve/MirrorShell/MirrorShell.png",
                    "name": "\u00dader \u0161t\u00edtem",
                    "shortDesc": "Kdykoliv z\u00edsk\u00e1\u0161 \u0161t\u00edt, tv\u016fj p\u0159\u00ed\u0161t\u00ed z\u00e1kladn\u00ed \u00fatok proti \u0161ampionovi zp\u016fsob\u00ed bonusov\u00e9 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'><font color='#48C4B7'>adaptivn\u00ed po\u0161kozen\u00ed</font></lol-uikit-tooltipped-keyword>.",
                    "longDesc": "Kdy\u017e na sob\u011b m\u00e1\u0161 \u0161t\u00edt, z\u00edsk\u00e1\u0161 v z\u00e1vislosti na \u00farovni <scaleLevel>1\u201310</scaleLevel> bod\u016f k brn\u011bn\u00ed a odolnosti v\u016f\u010di magii.<br><br>Kdykoliv z\u00edsk\u00e1\u0161 nov\u00fd \u0161t\u00edt, tv\u016fj dal\u0161\u00ed z\u00e1kladn\u00ed \u00fatok proti \u0161ampionovi zp\u016fsob\u00ed bonusov\u00e9 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'><font color='#48C4B7'>adaptivn\u00ed</font></lol-uikit-tooltipped-keyword> po\u0161kozen\u00ed ve v\u00fd\u0161i <scaleLevel>5\u201330 bod\u016f </scaleLevel><scaleHealth>(+1.5&nbsp;% bonusov\u00e9ho zdrav\u00ed)</scaleHealth> <scaleMana>(+8.5&nbsp;% s\u00edly nov\u00e9ho \u0161t\u00edtu)</scaleMana>.<br><br>Tento efekt trv\u00e1 2&nbsp;sek. pot\u00e9, co \u0161t\u00edt zmiz\u00ed."
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8429,
                    "key": "Conditioning",
                    "icon": "perk-images/Styles/Resolve/Conditioning/Conditioning.png",
                    "name": "Udr\u017eov\u00e1n\u00ed kondice",
                    "shortDesc": "Po 12 min. z\u00edsk\u00e1\u0161 8 brn\u011bn\u00ed a 8 k odolnosti v\u016f\u010di magii a hodnoty bonusov\u00e9ho brn\u011bn\u00ed a odolnosti v\u016f\u010di magii se ti zv\u00fd\u0161\u00ed o 3&nbsp;%.",
                    "longDesc": "Po 12 min. z\u00edsk\u00e1\u0161 8 brn\u011bn\u00ed a 8 k odolnosti v\u016f\u010di magii a hodnoty bonusov\u00e9ho brn\u011bn\u00ed a odolnosti v\u016f\u010di magii se ti zv\u00fd\u0161\u00ed o 3&nbsp;%."
                },
                {
                    "id": 8444,
                    "key": "SecondWind",
                    "icon": "perk-images/Styles/Resolve/SecondWind/SecondWind.png",
                    "name": "Druh\u00fd dech",
                    "shortDesc": "Pot\u00e9, co utrp\u00ed\u0161 po\u0161kozen\u00ed od nep\u0159\u00e1telsk\u00e9ho \u0161ampiona, si b\u011bhem ur\u010dit\u00e9 doby obnov\u00ed\u0161 \u010d\u00e1st chyb\u011bj\u00edc\u00edho zdrav\u00ed. ",
                    "longDesc": "Pot\u00e9, co utrp\u00ed\u0161 po\u0161kozen\u00ed od nep\u0159\u00e1telsk\u00e9ho \u0161ampiona, si b\u011bhem 10 sek. vyl\u00e9\u010d\u00ed\u0161 4 % chyb\u011bj\u00edc\u00edho zdrav\u00ed +3 zdrav\u00ed."
                },
                {
                    "id": 8473,
                    "key": "BonePlating",
                    "icon": "perk-images/Styles/Resolve/BonePlating/BonePlating.png",
                    "name": "Kost\u011bn\u00e1 zbroj",
                    "shortDesc": "Pot\u00e9, co utrp\u00ed\u0161 po\u0161kozen\u00ed od n\u011bjak\u00e9ho nep\u0159\u00e1telsk\u00e9ho \u0161ampiona, ti jeho n\u00e1sleduj\u00edc\u00ed 3 kouzla nebo \u00fatoky zp\u016fsob\u00ed po\u0161kozen\u00ed sn\u00ed\u017een\u00e9 o 30\u201360 bod\u016f.<br><br><br>Trv\u00e1n\u00ed: 1.5&nbsp;sek.<br>P\u0159eb\u00edjec\u00ed doba: 55&nbsp;sek.",
                    "longDesc": "Pot\u00e9, co utrp\u00ed\u0161 po\u0161kozen\u00ed od n\u011bjak\u00e9ho nep\u0159\u00e1telsk\u00e9ho \u0161ampiona, ti jeho n\u00e1sleduj\u00edc\u00ed 3 kouzla nebo \u00fatoky zp\u016fsob\u00ed po\u0161kozen\u00ed sn\u00ed\u017een\u00e9 o 30\u201360 bod\u016f.<br><br><br>Trv\u00e1n\u00ed: 1.5&nbsp;sek.<br>P\u0159eb\u00edjec\u00ed doba: 55&nbsp;sek."
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 8451,
                    "key": "Overgrowth",
                    "icon": "perk-images/Styles/Resolve/Overgrowth/Overgrowth.png",
                    "name": "P\u0159ehnan\u00fd r\u016fst",
                    "shortDesc": "Kdykoliv ve tv\u00e9 bl\u00edzkosti zem\u0159e n\u011bjak\u00fd nep\u0159\u00e1telsk\u00fd poskok nebo p\u0159\u00ed\u0161era, tv\u00e9 maxim\u00e1ln\u00ed zdrav\u00ed se trvale zv\u00fd\u0161\u00ed.",
                    "longDesc": "Z p\u0159\u00ed\u0161er \u010di nep\u0159\u00e1telsk\u00fdch poskok\u016f, kte\u0159\u00ed zem\u0159ou ve tv\u00e9 bl\u00edzkosti, od\u010derp\u00e1v\u00e1\u0161 \u017eivotn\u00ed esenci \u2013 za ka\u017ed\u00fdch 8 jednotek se ti maxim\u00e1ln\u00ed zdrav\u00ed trvale zv\u00fd\u0161\u00ed o 3 bod\u016f.<br><br>Po absorbov\u00e1n\u00ed esence z 120 p\u0159\u00ed\u0161er nebo nep\u0159\u00e1telsk\u00fdch poskok\u016f se ti maxim\u00e1ln\u00ed zdrav\u00ed zv\u00fd\u0161\u00ed o dal\u0161\u00edch 3.5 %."
                },
                {
                    "id": 8453,
                    "key": "Revitalize",
                    "icon": "perk-images/Styles/Resolve/Revitalize/Revitalize.png",
                    "name": "O\u017eivit",
                    "shortDesc": "Z\u00edsk\u00e1\u0161 5&nbsp;% k s\u00edle l\u00e9\u010den\u00ed a \u0161t\u00edt\u016f.<br><br>U c\u00edl\u016f s m\u00e9n\u011b ne\u017e 40&nbsp;% zdrav\u00ed je \u00fa\u010dinnost tebou ses\u00edlan\u00fdch nebo p\u0159ij\u00edman\u00fdch l\u00e9\u010den\u00ed a \u0161t\u00edt\u016f o 10&nbsp;% vy\u0161\u0161\u00ed.",
                    "longDesc": "Z\u00edsk\u00e1\u0161 5&nbsp;% k s\u00edle l\u00e9\u010den\u00ed a \u0161t\u00edt\u016f.<br><br>U c\u00edl\u016f s m\u00e9n\u011b ne\u017e 40&nbsp;% zdrav\u00ed je \u00fa\u010dinnost tebou ses\u00edlan\u00fdch nebo p\u0159ij\u00edman\u00fdch l\u00e9\u010den\u00ed a \u0161t\u00edt\u016f o 10&nbsp;% vy\u0161\u0161\u00ed."
                },
                {
                    "id": 8242,
                    "key": "Unflinching",
                    "icon": "perk-images/Styles/Sorcery/Unflinching/Unflinching.png",
                    "name": "Neochv\u011bjnost",
                    "shortDesc": "Kdy\u017e na tebe p\u016fsob\u00ed n\u011bjak\u00fd omezuj\u00edc\u00ed efekt, z\u00edsk\u00e1\u0161 bonus k brn\u011bn\u00ed a odolnosti v\u016f\u010di magii.",
                    "longDesc": "Kdy\u017e na tebe p\u016fsob\u00ed n\u011bjak\u00fd omezuj\u00edc\u00ed efekt, z\u00edsk\u00e1\u0161 2\u201310 (v z\u00e1vislosti na \u00farovni) k hodnot\u00e1m brn\u011bn\u00ed a odolnosti v\u016f\u010di magii, tento bonus p\u016fsob\u00ed je\u0161t\u011b 2&nbsp;sek. po odezn\u011bn\u00ed omezen\u00ed."
                }
            ]
        }
    ]
}