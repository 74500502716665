import React from "react";
import { champions } from "../../../../../src/staticData/champion_mapping";
import { Link } from "react-router-dom";

interface ChampionMastery {
  championId: string;
  championLevel: number;
  championPoints: number;
}

interface ChampionMasteriesProps {
  championMasteries: ChampionMastery[];
}

const ChampionMasteries: React.FC<ChampionMasteriesProps> = ({
  championMasteries,
}) => {
  return (
    <div className="w-full rounded-md bg-primaryShade shadow-strong-inner shadow-primary md:min-h-[192px]">
      {/* Header */}
      <div className="flex items-center justify-start p-2">
        <div className="mr-1 h-4 w-0.5 bg-green md:mr-2 md:h-6"></div>
        <span className="text-sm font-bold md:text-lg">
          Top Champions Played
        </span>
      </div>

      {/* Champion Masteries */}
      <div className="flex flex-wrap justify-center gap-10 pb-2 ">
        {championMasteries && championMasteries.length > 0 ? (
          championMasteries.map((championMastery: ChampionMastery) => (
            <div
              key={championMastery.championId}
              className="flex flex-col items-center"
            >
              <div className="font-bold text-stone-50 mb-1">
                {champions[championMastery.championId]}
              </div>
              <div className="relative">
                {/* Top Image */}
                <Link to={`/champion/${champions[championMastery.championId]}`}>
                  <img
                    src={`/champimages/${
                      champions[championMastery.championId]
                    }.png`}
                    alt={`${champions[championMastery.championId]} Champion`}
                    className="relative z-20 h-12 w-12 rounded-full border-2 border-gray-300" // z-20 with relative
                  />
                </Link>

                {/* Underlying Badge Image */}
                <img
                  src={`/masteries/Mastery_${championMastery.championLevel >= 10 ? "10+" : championMastery.championLevel}_Banner.webp`}
                  alt="Badge"
                  className="absolute left-0 top-9 z-10 h-14 w-14" // ensure z-10 places it under
                />

                {/* Mastery Level */}
                <div className={`absolute top-9 ${championMastery.championLevel >= 10 ? 'right-3' : 'right-4'} z-30 rounded-full border bg-primary px-1 text-xs text-white`}>
                  {championMastery.championLevel}
                </div>
              </div>

              {/* Champion Name and Points */}
              <div className="mt-5 text-center text-sm">
                <div className="mt-5 text-gray-600">
                  {championMastery.championPoints.toLocaleString()}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">
            No champion masteries available
          </div>
        )}
      </div>
    </div>
  );
};

export default ChampionMasteries;
