import React from "react";
import { LiveDataProps, Perk } from "../live-data-static/Types";
import { round } from "lodash";
import { champions } from "../../staticData/champion_mapping";
import { generateTags } from "../../staticData/summoner_tags_calculation";
import r_8000_primary from "../../staticData/cs_CZ/runes/r_8000_primary";
import r_8100_primary from "../../staticData/cs_CZ/runes/r_8100_primary";
import r_8200_primary from "../../staticData/cs_CZ/runes/r_8200_primary";
import r_8300_primary from "../../staticData/cs_CZ/runes/r_8300_primary";
import r_8400_primary from "../../staticData/cs_CZ/runes/r_8400_primary";

interface SummonerCardMobileProps extends LiveDataProps {
  index: number; // Add index prop to the interface
  role: string;
}

const getRightPerkClass = (perkClass: number) => {
  if (perkClass >= 8000 && perkClass < 8100) return r_8000_primary;
  if (perkClass >= 8100 && perkClass < 8200) return r_8100_primary;
  if (perkClass >= 8200 && perkClass < 8300) return r_8200_primary;
  if (perkClass >= 8300 && perkClass < 8400) return r_8300_primary;
  if (perkClass >= 8400 && perkClass < 8500) return r_8400_primary;
  return null;
};

const getRightPerk = (perkClass: Perk, perkId: number) => {
  for (let slot of perkClass.slots) {
    for (let rune of slot.runes) {
      if (rune.id === perkId) {
        return rune.icon;
      }
    }
  }
  return null;
};

const SummonerCardMobile: React.FC<SummonerCardMobileProps> = ({ participant, index, role }) => {
  let primaryPerk: string | null = "";
  let secondaryPerk: string | null = "";

  const tempPerkOne = getRightPerkClass(participant.perks.perkSubStyle);
  const tempPerkTwo = getRightPerkClass(participant.perks.perkStyle);

  if (tempPerkOne && tempPerkTwo) {
    primaryPerk = getRightPerk(tempPerkOne, participant.perks.perkIds[4]);
    secondaryPerk = getRightPerk(tempPerkTwo, participant.perks.perkIds[0]);
  }

  return (
    <div
      tabIndex={0}
      className={`collapse collapse-arrow bg-secondary w-full shadow-md ${
        index < 5 ? "shadow-blue-500" : "shadow-red-500"
      }`}
    >
      {/* Checkbox to toggle collapse */}
      <input type="checkbox" className="peer" />

      {/* Title of the collapse with all data */}
      {/* Title of the collapse with all data */}
      <div className="collapse-title text-xs font-medium flex flex-col space-y-1 peer-checked:bg-secondary peer-checked:text-base-content p-2">
        {/* Summoner Name at the top, spanning the full width */}
        <div className="w-full text-xs font-bold">
          {participant.riotId}
        </div>

        <div className="h-px bg-blue-400 mt-3 opacity-50 mr-auto w-[90%]"></div>{" "}




        {/* Container to hold the three sections side-by-side */}
        <div className="flex items-start space-x-4">
          {/* Champion image and spells/perks section */}
          <div className="flex items-center space-x-2">
            {/* Champion image on the left */}
            <img
              src={`/champimages/${champions[participant.championId]}.png`}
              alt="Champion"
              className="w-8 h-8 rounded-full"
            />

            {/* Spells and perks in one column under each other */}
            <div className="grid grid-cols-2 gap-1 mt-1">
              <img
                src={`/spell/${participant.spell1Id}.png`}
                alt="Spell1"
                className="w-4 h-4"
              />
              <img
                src={`/spell/${participant.spell2Id}.png`}
                alt="Spell2"
                className="w-4 h-4"
              />
              <img
                src={`/${primaryPerk}`}
                alt="Primary Perk"
                className="w-4 h-4"
              />
              <img
                src={`/${secondaryPerk}`}
                alt="Secondary Perk"
                className="w-4 h-4"
              />
            </div>
          </div>

          {/* Rank, LP, Winrate in one box */}
          <div className="flex flex-col items-start space-y-1">
            <div className="flex items-center">
              <img
                src={`/tiers/${participant?.rank.length === 0 ? "PROFILE_UNRANKED.png" : participant?.rank[0].tier}.png`}
                alt="Rank Tier"
                className="w-4 h-4 mr-1"
              />
              <span className="text-xxs font-bold">
                {participant?.rank.length === 0 ? "Unranked" : participant?.rank[0].tier}
              </span>
              <span className="text-xxs ml-1">
                {participant.rank.length === 0 ? "0" : participant.rank[0].leaguePoints} LP
              </span>
            </div>
            <div>
              <span className="text-xxs">
                Winrate:{" "}
                {participant.rank.length === 0? "?":round(
                  (participant.rank[0].wins /
                    (participant.rank[0].wins + participant.rank[0].losses)) *
                    100,
                  1
                )}
                %
              </span>
              <span className="text-xxs ml-1">
                ({participant.rank.length === 0? "?":participant.rank[0].wins + participant.rank[0].losses}{" "}
                matches)
              </span>
            </div>
          </div>

          {/* K/D/A and Matches in a box */}
          <div className="flex flex-col items-start">
            <div className="text-center">
              <p className="text-xxs text-gray-400">K/D/A</p>
              <div className="font-bold text-xxs flex">
                <span className="text-green-500">4.7</span>
                <span className="mx-1">/</span>
                <span className="text-red-500">6.2</span>
                <span className="mx-1">/</span>
                <span className="text-yellow-500">8.0</span>
              </div>
              {/* Matches directly under K/D/A with no space */}
              <p className="text-xxs text-gray-400 mt-0">Matches 120</p>
            </div>
          </div>
        </div>
      </div>

      {/* Collapse content with tags */}
      {/* Collapse content with tags */}
      <div className="collapse-content peer-checked:block">
        {/* Tags */}
        <div className="flex justify-between items-center mt-2">
          {/* Left Tags (Green - Success) */}
          <div className="flex flex-col space-y-1">
            {participant.rank.length === 0? "":generateTags(
              participant.rank[0].wins + participant.rank[0].losses,
              participant.rank[0].wins,
              participant.rank[0].losses,
              (participant.rank[0].wins /
                (participant.rank[0].wins + participant.rank[0].losses)) *
                100,
                participant.rank.length === 0 ? 0 : participant.rank[0].leaguePoints
            )
              .filter((tag) => tag.color === "green")
              .map((tag, index) => (
                <span key={index} className="badge badge-success text-xs gap-2">
                  {tag.tag}
                </span>
              ))}
          </div>

          {/* Right Tags (Red - Error) */}
          <div className="flex flex-col space-y-1">
            {participant.rank.length === 0? "":generateTags(
              participant.rank[0].wins + participant.rank[0].losses,
              participant.rank[0].wins,
              participant.rank[0].losses,
              (participant.rank[0].wins /
                (participant.rank[0].wins + participant.rank[0].losses)) *
                100,
                participant.rank.length === 0 ? 0 : participant.rank[0].leaguePoints
            )
              .filter((tag) => tag.color === "red")
              .map((tag, index) => (
                <span key={index} className="badge badge-error text-xs gap-2">
                  {tag.tag}
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummonerCardMobile;
